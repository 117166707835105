<template>
  <flex v-click-outside="() => (showOptions = false)" direction="column">
    <flex align="center" justify="space-between">
      <strong> CC </strong>
      <btn
        tooltip="Add CC"
        circular
        compact
        icon="fas fa-plus"
        @click.native="() => (showOptions = !showOptions)"
      />
    </flex>
    <flex justify="space-between" align="center">
      <loading v-if="loading" loading inline size="tiny" />
      <template v-else>
        <list class="carbon-copy-list" horizontal selection>
          <list-item
            v-for="carbonCopy in carbonCopies"
            :key="`carbon-copy-${carbonCopy.id}`"
          >
            <carbon-copy
              :carbon-copy="carbonCopy"
              :action="action"
              @get-carbon-copies="() => $emit('get-carbon-copies')"
            />
          </list-item>
        </list>
      </template>
    </flex>
    <flex v-if="showOptions" wrap="wrap" gap="0.25rem 0">
      <dropdown
        class="carbon-copy-dropdown"
        name="CC People"
        placeholder="+ Person"
        hide-caret
        :loading="creatingCarbonCopy === 'userId'"
        :disabled="!users.length"
        :options="users"
        label="fullName"
        reduced-field="id"
        searchable
        @update="(id) => createCarbonCopy({ userId: id })"
      />
      <dropdown
        class="carbon-copy-dropdown"
        name="CC Groups"
        placeholder="+ Group"
        hide-caret
        searchable
        :disabled="!groups.length"
        reduced-field="id"
        label="name"
        :options="groups"
        :loading="creatingCarbonCopy === 'groupId'"
        @update="(id) => createCarbonCopy({ groupId: id })"
      />
      <dropdown
        class="carbon-copy-dropdown"
        name="CC Relationships"
        placeholder="+ Relationship"
        hide-caret
        :options="relationships"
        :disabled="!relationships.length"
        :loading="creatingCarbonCopy === 'relationshipId'"
        reduced-field="id"
        label="name"
        searchable
        @update="(id) => createCarbonCopy({ relationshipId: id })"
      />
    </flex>
  </flex>
</template>

<script>
import vClickOutside from 'v-click-outside'

import { api } from '@/api'
import { toast } from '@/toasts'

import dropdown from '@/components/v3/dropdown.vue'
import list from '@/components/v2/list/list.vue'
import listItem from '@/components/v2/list/item.vue'
import flex from '@/components/v2/flex.vue'
import btn from '@/components/v2/btn.vue'
import loading from '@/components/v2/loading.vue'
import carbonCopy from '@/components/v2/action_card/carbon_copy.vue'

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    dropdown,
    list,
    listItem,
    flex,
    btn,
    loading,
    carbonCopy,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    groups: {
      type: Array,
      default: () => [],
    },
    relationships: {
      type: Array,
      default: () => [],
    },
    action: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    carbonCopies: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showOptions: false,
      creatingCarbonCopy: null,
    }
  },
  methods: {
    async createCarbonCopy(payload) {
      try {
        ;[this.creatingCarbonCopy] = Object.keys(payload)
        await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${this.action.id}/carbon-copies`,
          {
            userId: payload.userId || null,
            groupId: payload.groupId || null,
            relationshipId: payload.relationshipId || null,
          }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.creatingCarbonCopy = null
      }

      this.$emit('get-carbon-copies')
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';

.carbon-copy-list {
  margin: 0;
}

.carbon-copy-dropdown {
  margin: 0 0.25em 0 0;

  ::v-deep.vs__selected-options .vs__search:only-child {
    max-width: 8rem;
  }
}
</style>
