<template>
  <list-content>
    <flex justify="space-between">
      <flex align="center" gap="0.5rem" wrap="wrap">
        <g-image avatar :src="avatar" alt="avatar" />
        {{ name }}
      </flex>
      <btn
        dismiss
        tooltip="Remove"
        compact
        :loading="loading"
        @click.native.prevent="unenroll"
      />
    </flex>
  </list-content>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import listContent from '@/components/v2/list/content.vue'
import flex from '@/components/v2/flex.vue'
import btn from '@/components/v2/btn.vue'
import gImage from '@/components/v2/image.vue'

export default {
  components: {
    listContent,
    flex,
    btn,
    gImage,
  },
  props: {
    moment: {
      type: Object,
      required: true,
    },
    avatar: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: (value) => ['user', 'group'].includes(value),
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async unenroll() {
      if (this.type === 'user') {
        await this.unenrollUser(this.id)
      }
      if (this.type === 'group') {
        await this.unenrollGroup(this.id)
      }
      this.$emit('get-moment-status')
    },
    async unenrollGroup() {
      try {
        this.loading = true
        await api.delete(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${this.moment.id}/enrollees/groups/${this.id}`
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.loading = false
      }
      this.$emit('get-group-enrollees')
      this.$emit('get-enrollable-groups')
    },
    async unenrollUser() {
      try {
        this.loading = true
        await api.delete(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${this.moment.id}/enrollees/individuals/${this.id} `
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.loading = false
      }
      this.$emit('get-user-enrollees')
      this.$emit('get-enrollable-users')
    },
  },
}
</script>
