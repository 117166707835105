<template>
  <dropdown
    ref="dropdown"
    :class="classes"
    :button="!!icon"
    :circular="!!icon"
    :icon="icon"
    :fluid="!inline"
    :inline="inline"
    :loading="loading"
    hide-caret
    hide-selection
    :button-variant="buttonVariant"
    scrolling
    :compact="compact"
    :basic="basic"
    :size="size"
    :tooltip="tooltip"
    name="Personalization Tokens"
    @update="update"
  >
    <dropdown-search />
    <dropdown-header> Enrollee </dropdown-header>
    <dropdown-item
      v-for="option in enrolleeTokens"
      :key="`enrollee-tokens-${option.text}`"
      :value="{
        label: option.value,
        id: option.value,
      }"
    >
      {{ option.text }}
      <dropdown-description> {{ option.description }} </dropdown-description>
    </dropdown-item>
    <template v-if="!hideRecipientTokens">
      <dropdown-divider />
      <dropdown-header> Recipient </dropdown-header>
      <dropdown-item
        v-for="option in recipientTokens"
        :key="`recipient-tokens-${option.text}`"
        :value="{
          label: option.value,
          id: option.value,
        }"
      >
        {{ option.text }}
        <dropdown-description> {{ option.description }} </dropdown-description>
      </dropdown-item>
    </template>
  </dropdown>
</template>

<script>
import { format, formatDistanceToNowStrict } from 'date-fns'
import jquery from 'jquery'
import dropdown from '@/components/v2/dropdown/dropdown.vue'
import dropdownItem from '@/components/v2/dropdown/item.vue'
import dropdownHeader from '@/components/v2/dropdown/header.vue'
import dropdownDescription from '@/components/v2/dropdown/description.vue'
import dropdownDivider from '@/components/v2/dropdown/divider.vue'
import dropdownSearch from '@/components/v2/dropdown/search.vue'

export default {
  components: {
    dropdown,
    dropdownItem,
    dropdownHeader,
    dropdownDescription,
    dropdownDivider,
    dropdownSearch,
  },
  props: {
    floatRight: {
      type: Boolean,
      default: false,
    },
    aspects: {
      type: Array,
      required: true,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    hideRecipientTokens: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    buttonVariant: {
      type: String,
      default: null,
      validator: (value) =>
        [
          null,
          'primary',
          'secondary',
          'positive',
          'negative',
          'default',
        ].includes(value),
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) =>
        [
          'mini',
          'tiny',
          'small',
          'medium',
          'large',
          'big',
          'huge',
          'massive',
        ].includes(value),
    },
    compact: {
      type: Boolean,
      default: false,
    },
    basic: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: 'Add Personalization',
    },
  },
  computed: {
    enrolleeTokens() {
      const basicTokens = [
        {
          text: 'First Name',
          description: 'John',
          value: 'Enrollee First Name',
        },
        {
          text: 'Full Name',
          description: 'John Smith',
          value: 'Enrollee Full Name',
        },
        {
          text: 'Email',
          description: 'john.smith@work.com',
          value: 'Enrollee Email',
        },
        {
          text: 'Personal Email',
          description: 'js123@gmail.com',
          value: 'Enrollee Personal Email',
        },
        {
          text: 'Birthday',
          description: '10/10',
          value: 'Enrollee Birthday',
        },
        {
          text: `Birthday (Relative)`,
          description: '15 days',
          value: `Enrollee Birthday (Relative)`,
        },
        {
          text: 'Start Date',
          description: '7/1',
          value: 'Enrollee Start Date',
        },
        {
          text: `Start Date (Relative)`,
          description: '3 days',
          value: `Enrollee Start Date (Relative)`,
        },
        {
          text: 'Work Anniversary',
          description: '1/19',
          value: 'Enrollee Work Anniversary',
        },
        {
          text: `Work Anniversary (Relative)`,
          description: '2 years',
          value: `Enrollee Work Anniversary (Relative)`,
        },
        {
          text: 'End Date',
          description: '12/31',
          value: 'Enrollee End Date',
        },
        {
          text: `End Date (Relative)`,
          description: '14 days',
          value: `Enrollee End Date (Relative)`,
        },
      ]
      return basicTokens.concat(this.generateTokens('Enrollee'))
    },
    recipientTokens() {
      const basicTokens = [
        {
          text: 'First Name',
          description: 'John',
          value: 'Recipient First Name',
        },
        {
          text: 'Full Name',
          description: 'John Smith',
          value: 'Recipient Full Name',
        },
        {
          text: 'Email',
          description: 'john.smith@work.com',
          value: 'Recipient Email',
        },
        {
          text: 'Personal Email',
          description: 'js123@gmail.com',
          value: 'Recipient Personal Email',
        },
        {
          text: 'Birthday',
          description: '10/10',
          value: 'Recipient Birthday',
        },
        {
          text: `Birthday (Relative)`,
          description: '15 days',
          value: `Recipient Birthday (Relative)`,
        },
        {
          text: 'Start Date',
          description: '7/1',
          value: 'Recipient Start Date',
        },
        {
          text: `Start Date (Relative)`,
          description: '3 days',
          value: `Recipient Start Date (Relative)`,
        },
        {
          text: 'Work Anniversary',
          description: '1/19',
          value: 'Recipient Work Anniversary',
        },
        {
          text: `Work Anniversary (Relative)`,
          description: '2 years',
          value: `Recipient Work Anniversary (Relative)`,
        },
        {
          text: 'End Date',
          description: '12/31',
          value: 'Recipient End Date',
        },
        {
          text: `End Date (Relative)`,
          description: '14 days',
          value: `Recipient End Date (Relative)`,
        },
      ]
      return basicTokens.concat(this.generateTokens('Recipient'))
    },
    classes() {
      return {
        fluid: !this.inline,
      }
    },
  },
  beforeDestroy() {
    jquery(this.$el).removeData()
    jquery(this.$el).remove()
  },
  methods: {
    update(value) {
      const mentionHtml = `<span class="mention" data-mention="" data-id="${value.id}" data-label="${value.label}" contenteditable="false">@${value.label}</span>`
      // NOTE: html is for editable html fields, mention is specifically for the text editor
      this.$emit('update', { html: mentionHtml, mention: value })
    },
    generateTokens(type) {
      const date = new Date(new Date().getFullYear() - 1, 6, 1)
      return this.aspects.flatMap((aspect) => {
        if (aspect.type === 'date') {
          return [
            {
              text: aspect.name,
              description: format(date, 'M/d'),
              value: `${type} ${aspect.name}`,
            },
            {
              text: `${aspect.name} (Relative)`,
              description: formatDistanceToNowStrict(date),
              value: `${type} ${aspect.name} (Relative)`,
            },
          ]
        }

        return {
          text: aspect.name,
          description: '',
          value: `${type} ${aspect.name}`,
        }
      })
    },
    show() {
      this.$refs.dropdown.show()
    },
    hide() {
      this.$refs.dropdown.hide()
    },
  },
}
</script>

<style lang="less" scoped>
// a fomantic override to allow fluid to work for the dropdown
.fluid ::v-deep.menu {
  right: 0;
}
</style>
