var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-description',[_c('flex',{attrs:{"direction":"column"}},[_c('strong',[_vm._v("Meeting Title")]),_c('flex',{attrs:{"justify":"space-between"}},[_c('editable-html',{key:("meeting-title-" + _vm.refreshKey),staticClass:"message-content meeting-title",attrs:{"value":_vm.action.meetingTitle,"loading":_vm.patchingField === 'meetingTitle',"placeholder":"Add Meeting Title"},on:{"update":function (value) { return _vm.patchAction('meetingTitle', value); }}}),_c('personalization-token-dropdown',{ref:"personalization",attrs:{"icon":"far fa-address-card","inline":"","aspects":_vm.aspects},on:{"update":_vm.insertPersonalizationToken}})],1)],1),_c('flex',{attrs:{"direction":"column"}},[_c('strong',[_vm._v("Meeting Date & Time "),_c('i',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"fas fa-info-circle",attrs:{"content":"Date and time are relative to the trigger date"}})]),_c('flex',{attrs:{"align":"center","wrap":"wrap"}},[_c('delta-picker',{attrs:{"delta":_vm.action.relativeMeetingDate,"loading":_vm.patchingField === 'relativeMeetingDate',"start":_vm.action.relativeDate,"tooltip":"Meeting Date"},on:{"update":function (value) { return _vm.patchAction('relativeMeetingDate', value); }}}),_c('flex',{attrs:{"align":"center"}},[_c('time-picker',{attrs:{"time":_vm.action.relativeMeetingStartTime,"loading":_vm.patchingField === 'relativeMeetingStartTime',"start":_vm.isEqual(_vm.action.relativeDate, _vm.action.relativeMeetingDate)
              ? _vm.action.relativeTime
              : null,"placeholder":"Start Time"},on:{"update":function (value) { return _vm.patchAction('relativeMeetingStartTime', value); }}}),_vm._v(" —"),_c('time-picker',{attrs:{"time":_vm.action.relativeMeetingEndTime,"loading":_vm.patchingField === 'relativeMeetingEndTime',"start":_vm.action.relativeMeetingStartTime,"placeholder":"End Time"},on:{"update":function (value) { return _vm.patchAction('relativeMeetingEndTime', value); }}})],1)],1)],1),_c('recipient-group',{attrs:{"title":"Guests","placeholder":"⚠️ Click + to add people to this channel","recipients":_vm.recipients,"action":_vm.action,"users":_vm.users,"groups":_vm.groups,"relationships":_vm.relationships,"slack-conversations":[],"hide-channels":"","loading":_vm.loading},on:{"get-recipients":function () { return _vm.$emit('get-recipients'); },"patch-action":function (ref) {
                            var field = ref.field;
                            var value = ref.value;

                            return _vm.patchAction(field, value);
}}}),_c('flex',{attrs:{"direction":"row"}},[_c('flex',{staticClass:"half-width"},[_c('p',{staticClass:"label-text"},[_vm._v("Location")])]),_c('flex',[_c('p',{staticClass:"label-text"},[_vm._v("Meeting Owner")])])],1),_c('flex',{attrs:{"direction":"column","gap":"0.5rem"}},[_c('flex',{attrs:{"direction":"row","align":"center"}},[_c('flex',{staticClass:"half-width",attrs:{"align":"center"}},[_c('editable-html',{attrs:{"value":_vm.action.meetingLocation,"loading":_vm.patchingField === 'meetingLocation',"placeholder":"Add Location"},on:{"update":function (value) { return _vm.patchAction('meetingLocation', value); }}})],1),_c('flex',{staticClass:"half-width"},[_vm._v(" "+_vm._s(_vm.meetingOwner ? _vm.meetingOwner.fullName : "⚠️ Connect an admin's calendar on the Settings page")+" ")])],1),_c('checkbox',{attrs:{"initial-value":!!_vm.action.meetingHasDefaultGoogleMeet,"name":"Add Google Meet"},on:{"update":function (value) { return _vm.patchAction('meetingHasDefaultGoogleMeet', value); }}},[_vm._v(" Generate Google Meet Link ")]),_c('flex',[_c('p',{staticClass:"label-text"},[_vm._v("Description")])]),_c('text-editor',{attrs:{"value":_vm.action.body,"aspects":_vm.aspects,"loading":_vm.patchingField === 'body',"hide-recipient-tokens":""},on:{"input":function (value) { return _vm.patchAction('body', value); }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }