var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-description',[_c('flex',{attrs:{"direction":"column","gap":"0.5rem"}},[_c('recipient-group',{attrs:{"title":"Recipient (Assigner)","recipients":_vm.recipients,"users":_vm.users,"groups":_vm.groups,"relationships":_vm.relationships,"slack-conversations":_vm.slackConversations,"action":_vm.action,"hide-channels":"","hide-groups":"","allow-one":"","loading":_vm.loading},on:{"get-recipients":function () { return _vm.$emit('get-recipients'); },"get-moment-status":function () { return _vm.$emit('get-moment-status'); },"patch-action":function (ref) {
      var field = ref.field;
      var value = ref.value;

      return _vm.patchAction(field, value);
}}}),_c('flex',{attrs:{"justify":"space-between","wrap":"wrap","gap":"0.5rem"}},[_c('flex',{attrs:{"direction":"column","gap":"0.5rem"}},[_c('strong',[_vm._v("Relationship")]),_c('dropdown',{attrs:{"name":"Relationship","simple":"","placeholder":"Select Relationship","value":_vm.action.relationshipId,"reduced-field":"id","loading":_vm.patchingRelationshipForAction,"options":_vm.relationships,"label":"name","searchable":""},on:{"update":_vm.patchRelationshipForAction}})],1),_c('flex',{attrs:{"direction":"column","gap":"0.5rem"}},[_c('strong',[_vm._v(" Group To Select From "),_c('i',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"fas fa-info-circle",attrs:{"content":("The " + (_vm.recipients.length
                ? _vm.recipients[0].name
                : 'recipient of this step') + " will assign the\n      " + (_vm.getRelationshipSuperiorTitle(_vm.action.relationshipId)) + " for the\n      " + (_vm.getRelationshipSubordinateTitle(_vm.action.relationshipId)))}})]),_c('dropdown',{attrs:{"name":"Assignee","placeholder":"Select Group","searchable":"","simple":"","value":_vm.action.assignee && _vm.action.assignee.groupId,"loading":_vm.patchingField === 'assigneeGroupId',"options":_vm.groups,"label":"name","reduced-field":"id"},on:{"update":function (value) { return _vm.patchAction('assigneeGroupId', value); }}})],1)],1),_c('flex',{attrs:{"direction":"column"}},[_c('btn',{staticClass:"preview-button",attrs:{"size":"small","compact":"","basic":"","variant":"default"},nativeOn:{"click":function($event){return (function () { return (_vm.showPreview = !_vm.showPreview); }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.showPreview ? 'Edit' : 'Close Text Editor')+" ")]),_c('slack-message-preview',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPreview),expression:"showPreview"}],staticClass:"message-preview pointer",attrs:{"action":_vm.action,"organization":_vm.organization},nativeOn:{"click":function($event){return (function () { return (_vm.showPreview = false); }).apply(null, arguments)}}}),_c('text-editor',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showPreview),expression:"!showPreview"}],attrs:{"value":_vm.action.body,"aspects":_vm.aspects,"loading":_vm.patchingField === 'body'},on:{"input":function (value) { return _vm.patchAction('body', value); }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }