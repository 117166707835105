<template>
  <v-select
    :name="name"
    :class="classes"
    :label="label"
    :options="paginated"
    :value="value"
    :placeholder="placeholder"
    :disabled="disabled"
    :searchable="searchable"
    :selectable="computedSelectable"
    :append-to-body="appendToBody"
    :calculate-position="calculatePosition"
    :clearable="clearable"
    :filterable="filterable"
    :reduce="reduce"
    :loading="loading"
    :multiple="multiple"
    @input="(value) => $emit('update', value)"
    @open="onOpen"
    @close="onClose"
    @search="onSearch"
    @search:focus="onSearchFocus"
  >
    <template v-if="icon" #selected-option-container="{ option }">
      <div class="vs__selected">
        <i :class="[icon, 'selection-icon']" />
        {{ option[label] }}
      </div>
    </template>
    <template #open-indicator="{ attributes }">
      <span v-if="hideCaret" v-bind="attributes" />
      <span v-else v-bind="attributes">
        <i class="fas fa-caret-down" />
      </span>
    </template>
    <template v-if="secondaryLabel" #selected-option="option">
      <div>
        {{ option[label] }} ({{
          option[secondaryLabel] || secondaryLabelWarning
        }})
      </div>
    </template>
    <template #list-footer>
      <li v-show="hasNextPage" ref="load" class="loader">
        Loading more options...
      </li>
    </template>
    <template v-if="groupable" #option="option">
      <div>
        {{ option.group }}
      </div>
      {{ option[label] }}
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { createPopper } from '@popperjs/core'
import { some, find } from 'lodash'

const DROPDOWN_PAGE_SIZE = 10

export default {
  components: {
    vSelect,
  },
  props: {
    appendToBody: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    groupable: {
      type: Boolean,
      default: false,
    },
    hideCaret: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    placement: {
      type: String,
      default: null,
      validator: (value) =>
        [
          'auto',
          'auto-start',
          'auto-end',
          'top',
          'top-start',
          'top-end',
          'bottom',
          'bottom-start',
          'bottom-end',
          'right',
          'right-start',
          'right-end',
          'left',
          'left-start',
          'left-end',
          null,
        ].includes(value),
    },
    placeholder: {
      type: String,
      default: '',
    },
    reducedField: {
      type: String,
      default: null,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    secondaryLabel: {
      type: String,
      default: null,
    },
    secondaryLabelWarning: {
      type: String,
      default: 'N/A',
    },
    selectable: {
      type: Function,
      default: () => true,
    },
    simple: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, Object, Boolean, Array],
      default: null,
    },
  },
  data: () => ({
    observer: null,
    limit: DROPDOWN_PAGE_SIZE,
  }),
  computed: {
    paginated() {
      const paginatedOptions = this.options.slice(0, this.limit)
      if (this.value && this.reducedField) {
        const currentValue = find(this.options, {
          [this.reducedField]: this.value,
        })

        if (!some(paginatedOptions, currentValue)) {
          return [currentValue].concat(paginatedOptions)
        }
      }
      return paginatedOptions
    },
    hasNextPage() {
      return this.paginated.length < this.options.length
    },
    classes() {
      return {
        'g-dropdown': true,
        simple: this.simple,
        groupable: this.groupable,
        'hide-caret': this.hideCaret,
      }
    },
    computedSelectable() {
      if (this.groupable) {
        return (option) =>
          !Object.prototype.hasOwnProperty.call(option, 'group')
      }
      return this.selectable
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.onScroll)
  },
  methods: {
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async onScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        this.limit += DROPDOWN_PAGE_SIZE
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    // When appendToBody is true, this function is responsible for positioning
    // the drop down list.
    calculatePosition(dropdownList, component, { width }) {
      dropdownList.style.width = width

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement || 'auto',
      })

      // To prevent memory leaks Popper needs to be destroyed.
      return () => popper.destroy()
    },
    onSearch(search) {
      this.$emit('search', search)
    },
    onSearchFocus() {
      this.limit = this.options.length
    },
    reduce(option) {
      if (!option || !this.reducedField) {
        return option
      }

      return option[this.reducedField]
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/colors.less';
@import '~@/assets/less/borders.less';

.vs--disabled {
  opacity: 0.45;

  ::v-deep.vs__dropdown-toggle,
  ::v-deep.vs__search,
  ::v-deep.vs__selected {
    cursor: default;
  }
}

.g-dropdown {
  ::v-deep.vs__dropdown-option--highlight {
    color: @blue;
    background: @light-grey-hover;
  }

  &.vs--open {
    ::v-deep.vs__dropdown-toggle {
      border-bottom-color: rgba(60, 60, 60, 0.26); // copied from vue-select.css
      border-bottom-right-radius: @standard-border-radius;
      border-bottom-left-radius: @standard-border-radius;
    }
  }

  ::v-deep.vs__dropdown-toggle {
    padding: 6px 5.5px 10px 5.5px; // Specific padding to make library match Fomantic
    line-height: 1;
  }

  ::v-deep.vs__selected {
    color: @blue;
    line-height: 1;
  }

  ::v-deep.vs__selected-options {
    .vs__search {
      width: 0;
      max-width: 100%;
      padding: 0 7px;
      margin: 4px 0 0;
      font-size: 1em;
      line-height: 1;
      background: none;
      outline: none;
      box-shadow: none;
      appearance: none;
      color: @blue;

      &:focus {
        &::placeholder {
          color: @grey;
        }
      }

      &:only-child {
        width: auto;
      }
    }
  }

  ::v-deep.vs__dropdown-menu {
    top: 100%;
    width: auto;
    min-width: 100%;
  }

  ::v-deep.vs__dropdown-option {
    line-height: 1;
    padding: 11px 14px; // Specific padding to make library match Fomantic
    width: 100%;
  }

  &.simple {
    &.vs--disabled {
      ::v-deep.vs__search,
      ::v-deep.vs__selected {
        background-color: transparent;
      }
    }

    ::v-deep.vs__dropdown-toggle {
      border: none;

      .vs__search {
        border: none;
      }

      &:hover {
        background-color: @light-grey-hover;

        .vs__search {
          background-color: @light-grey-hover;
        }
      }
    }
  }

  .loader {
    color: #bbb;
    text-align: center;
  }

  .selection-icon {
    margin-right: 1rem;
    margin-left: 0.25rem;
    background-color: @white;
    box-shadow: none;
    opacity: 0.5;
  }

  &.groupable {
    ::v-deep.vs__dropdown-option.vs__dropdown-option--disabled {
      background-color: @light-grey;
    }
  }

  &.hide-caret {
    ::v-deep.vs__actions {
      display: none;
    }
  }
}
</style>

<style lang="less">
// Global styles used in conjunction with append-to-body prop
@import '~@/assets/less/colors.less';

.vs__dropdown-option.vs__dropdown-option--disabled {
  background-color: @light-grey;
}

.vs__dropdown-option--highlight {
  background: @light-grey-hover;
}

.vs__dropdown-option {
  color: @blue;
}
</style>
