<template>
  <card-description>
    <flex direction="column" gap="0.5rem">
      <recipient-group
        disabled
        :recipients="recipients"
        :action="action"
        :users="[]"
        :groups="[]"
        :relationships="[]"
        :slack-conversations="[]"
        :loading="loading"
        title="Task Owner"
        disabled-tooltip="You can edit the task owner from the checklist tab"
        @get-recipients="() => $emit('get-recipients')"
        @get-moment-status="() => $emit('get-moment-status')"
      />
      <flex direction="column">
        <btn
          size="small"
          compact
          basic
          class="preview-button"
          variant="default"
          @click.native="() => (showPreview = !showPreview)"
        >
          {{ showPreview ? 'Edit' : 'Close Text Editor' }}
        </btn>
        <slack-message-preview
          v-show="showPreview"
          class="message-preview pointer"
          :action="action"
          :organization="organization"
          :tasks="tasks"
          @click.native="() => (showPreview = false)"
        />
        <text-editor
          v-show="!showPreview"
          :value="action.body"
          :aspects="aspects"
          :loading="patchingField === 'body'"
          @input="(value) => patchAction('body', value)"
        />
      </flex>
    </flex>
  </card-description>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import cardDescription from '@/components/v2/card/description.vue'
import btn from '@/components/v2/btn.vue'
import slackMessagePreview from '@/components/v2/slack_message_preview.vue'
import textEditor from '@/components/v2/text_editor.vue'
import recipientGroup from '@/components/v2/action_card/recipient_group.vue'
import flex from '@/components/v2/flex.vue'

export default {
  components: {
    cardDescription,
    btn,
    slackMessagePreview,
    textEditor,
    recipientGroup,
    flex,
  },
  props: {
    action: {
      type: Object,
      required: true,
    },
    recipients: {
      type: Array,
      required: true,
    },
    tasks: {
      type: Array,
      required: true,
    },
    aspects: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    organization: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      token: {},
      patchingField: null,
      showPreview: true,
    }
  },
  methods: {
    async patchAction(field, value) {
      try {
        this.patchingField = field
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${this.action.id}`,
          { [field]: value }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingField = null
      }
      this.$emit('get-actions')
    },
  },
}
</script>

<style lang="less" scoped>
.message-preview {
  width: 100%;
}

.preview-button {
  display: block;
  margin: 0 0 0.5rem auto;
}
</style>
