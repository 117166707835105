<template>
  <card-description>
    <flex direction="column">
      <strong>Meeting Title</strong>
      <flex justify="space-between">
        <editable-html
          :key="`meeting-title-${refreshKey}`"
          :value="action.meetingTitle"
          :loading="patchingField === 'meetingTitle'"
          class="message-content meeting-title"
          placeholder="Add Meeting Title"
          @update="(value) => patchAction('meetingTitle', value)"
        />
        <personalization-token-dropdown
          ref="personalization"
          icon="far fa-address-card"
          inline
          :aspects="aspects"
          @update="insertPersonalizationToken"
        />
      </flex>
    </flex>
    <flex direction="column">
      <strong
        >Meeting Date & Time
        <i
          v-tippy
          class="fas fa-info-circle"
          content="Date and time are relative to the trigger date"
      /></strong>
      <flex align="center" wrap="wrap">
        <delta-picker
          :delta="action.relativeMeetingDate"
          :loading="patchingField === 'relativeMeetingDate'"
          :start="action.relativeDate"
          tooltip="Meeting Date"
          @update="(value) => patchAction('relativeMeetingDate', value)"
        />
        <flex align="center">
          <time-picker
            :time="action.relativeMeetingStartTime"
            :loading="patchingField === 'relativeMeetingStartTime'"
            :start="
              isEqual(action.relativeDate, action.relativeMeetingDate)
                ? action.relativeTime
                : null
            "
            placeholder="Start Time"
            @update="(value) => patchAction('relativeMeetingStartTime', value)"
          />
          —<time-picker
            :time="action.relativeMeetingEndTime"
            :loading="patchingField === 'relativeMeetingEndTime'"
            :start="action.relativeMeetingStartTime"
            placeholder="End Time"
            @update="(value) => patchAction('relativeMeetingEndTime', value)"
          />
        </flex>
      </flex>
    </flex>
    <recipient-group
      title="Guests"
      placeholder="⚠️ Click + to add people to this channel"
      :recipients="recipients"
      :action="action"
      :users="users"
      :groups="groups"
      :relationships="relationships"
      :slack-conversations="[]"
      hide-channels
      :loading="loading"
      @get-recipients="() => $emit('get-recipients')"
      @patch-action="({ field, value }) => patchAction(field, value)"
    />
    <flex direction="row">
      <flex class="half-width">
        <p class="label-text">Location</p>
      </flex>
      <flex>
        <p class="label-text">Meeting Owner</p>
      </flex>
    </flex>
    <flex direction="column" gap="0.5rem">
      <flex direction="row" align="center">
        <flex class="half-width" align="center">
          <editable-html
            :value="action.meetingLocation"
            :loading="patchingField === 'meetingLocation'"
            placeholder="Add Location"
            @update="(value) => patchAction('meetingLocation', value)"
          />
        </flex>
        <flex class="half-width">
          {{
            meetingOwner
              ? meetingOwner.fullName
              : "⚠️ Connect an admin's calendar on the Settings page"
          }}
        </flex>
      </flex>
      <checkbox
        :initial-value="!!action.meetingHasDefaultGoogleMeet"
        name="Add Google Meet"
        @update="(value) => patchAction('meetingHasDefaultGoogleMeet', value)"
      >
        Generate Google Meet Link
      </checkbox>
      <flex><p class="label-text">Description</p></flex>
      <text-editor
        :value="action.body"
        :aspects="aspects"
        :loading="patchingField === 'body'"
        hide-recipient-tokens
        @input="(value) => patchAction('body', value)"
      />
    </flex>
  </card-description>
</template>

<script>
import { isEqual } from 'lodash'
import { api } from '@/api'
import { toast } from '@/toasts'

import cardDescription from '@/components/v2/card/description.vue'
import flex from '@/components/v2/flex.vue'
import checkbox from '@/components/v2/checkbox.vue'
import editableHtml from '@/components/v2/editable_html.vue'
import deltaPicker from '@/components/v2/delta_picker.vue'
import timePicker from '@/components/v2/time_picker.vue'
import personalizationTokenDropdown from '@/components/v2/personalization_token_dropdown.vue'
import recipientGroup from '@/components/v2/action_card/recipient_group.vue'
import textEditor from '@/components/v2/text_editor.vue'

export default {
  components: {
    cardDescription,
    flex,
    checkbox,
    editableHtml,
    deltaPicker,
    timePicker,
    personalizationTokenDropdown,
    recipientGroup,
    textEditor,
  },

  props: {
    action: {
      type: Object,
      required: true,
    },
    aspects: {
      type: Array,
      required: true,
    },
    recipients: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
    relationships: {
      type: Array,
      required: true,
    },
    organization: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      patchingField: false,
      isEqual,
      refreshKey: 0,
    }
  },
  computed: {
    meetingOwner() {
      return this.users.find(
        (user) => user.id === this.organization.calendarOwnerId
      )
    },
  },
  methods: {
    async patchAction(field, value) {
      try {
        this.patchingField = field
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${this.action.id}`,
          { [field]: value }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingField = null
      }
      this.$emit('get-actions')
      if (field === 'meetingTitle') {
        this.refreshKey += 1
      }
    },
    async insertPersonalizationToken(value) {
      await this.patchAction(
        'meetingTitle',
        `${this.action.meetingTitle || ''}<span></span>${value.html}`
      )
      this.$refs.personalization.hide()
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/message-content.less';
.half-width {
  width: 50%;
}

.meeting-title {
  font-size: @title-font-size;
}
</style>
