// ========================================== // Author: @johnwetzel // Purpose:
The information that goes inside the hover state of the user action status //
==========================================

<template>
  <div v-if="status" class="action-details">
    <p :class="['blue action-details-header', { 'error-colors': isError }]">
      {{ hoverHeader }}
    </p>
    <div v-if="isError" class="error-colors">
      {{ errorMessage }}
    </div>
    <div v-else class="grey">
      <i class="grey calendar icon" />{{ formattedDate }}
      <br />
      <i class="grey clock icon" />{{ formattedTime }}
    </div>
  </div>
</template>

<script>
import dateMixin from '@/mixins/dateMixin'

export default {
  mixins: [dateMixin],
  props: {
    status: {
      type: String,
      required: true,
    },
    actionType: {
      type: String,
      required: true,
    },
    actionTime: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: 'Contact Gather Support for more info',
    },
    organization: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isError() {
      return this.status === 'error' || this.status === 'neverSent'
    },
    hoverHeader() {
      const headers = {
        neverSent: this.actionType === 'channel' ? 'Not Created' : 'Not Sent',
        scheduled: 'Scheduled',
        sent: this.actionType === 'channel' ? 'Created' : 'Sent',
        error: 'Issue',
        complete:
          this.actionType === 'relationship' ? 'Assigned' : 'Completed at',
        skipped: 'Skipped',
        overdue: 'Overdue on',
        blocked: 'Blocked by',
        partialComplete: 'Partially Completed',
      }
      return headers[this.status]
    },
    formattedDate() {
      return this.formatDate(new Date(this.actionTime), true)
    },
    formattedTime() {
      return this.formatTime(new Date(this.actionTime), {
        timeZone: this.organization ? this.organization.timezone : '',
      })
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/colors.less';

.action-details {
  min-width: 10em;
  padding: 0.25em;
  text-align: left;
  background-color: @white;
}

.action-details-header {
  margin-bottom: 0;
  font-weight: bold;
}

.error-colors {
  // HACK: Make error more subdued until it can become properly nuanced
  color: @blue;
}
</style>
