<template>
  <g-table selectable class="timeline-table">
    <table-head>
      <table-row ref="firstHeader">
        <table-header sticky class="empty-column" celled rowspan="3" />
        <table-header
          v-for="(header, index) in firstTableHeader"
          :key="'firstHeader' + index"
          celled
          left-aligned
          sticky
          :colspan="firstTableHeaderColspan(header)"
          :style="{ backgroundColor: firstTableHeaderColor(header) }"
        >
          <h3>{{ firstHeaderTitle(header) }}</h3>
        </table-header>
      </table-row>
      <table-row ref="secondHeader">
        <table-header
          v-for="(header, index) in secondTableHeader"
          :key="'secondHeader' + index"
          center-aligned
          sticky
          class="task-column"
          :style="{ top: secondHeaderPosition + 'px' }"
        >
          <div class="task-name-container">
            {{ secondHeaderTitle(header.name, header.action) }}
          </div>
        </table-header>
      </table-row>
      <table-row>
        <table-header
          v-for="(header, index) in thirdTableHeader"
          :key="'thirdHeader' + index"
          center-aligned
          sticky
          class="recipient-column"
          :style="{ top: thirdHeaderPosition + 'px' }"
        >
          <div>
            {{ thirdHeaderTitle(header) }}
          </div>
        </table-header>
      </table-row>
    </table-head>
    <table-body>
      <table-row
        v-for="(row, index) in tableData"
        :key="'enrollee' + index"
        class="user-row"
      >
        <table-cell
          class="user-cell"
          @click.native="
            $router.push({ name: 'profile', params: { id: row.user.id } })
          "
        >
          <h4 class="ui image header">
            <g-image
              :src="row.user.avatar || defaultProfileImage"
              alt="avatar"
              avatar
            />
            <div class="content">
              <span class="header">
                {{
                  shortenText(
                    formatFullName(row.user),
                    PERSON_NAME_CHARACTER_LIMIT
                  )
                }}
              </span>
              <div class="sub header">
                {{ formatTrigger(row.user) }}
              </div>
            </div>
          </h4>
        </table-cell>

        <table-cell
          v-for="(collection, innerIndex) in row.collection"
          :key="'userAction' + innerIndex"
          center-aligned
          class="status-indicator"
        >
          <user-action-status-indicator
            :loading="
              patchingUserActionUserTasks[0] === index &&
              patchingUserActionUserTasks[1] === innerIndex
            "
            :organization="organization"
            :action-type="collection.action.type || ''"
            :status="getStatus(collection, row.user)"
            :error-message="getError(collection, row.user)"
            :has-pop-up="showPopupForIndicator(collection, row.user)"
            :user-action-date="getUserActionDate(collection)"
            :is-reminder-setup-mode="showReminderSelection"
            :is-clickable="
              collection.action.type === 'task' &&
              !!collection.userActions.length
            "
            :percentage-complete="
              calculateCompletionPercentageForTasks(
                collection.userActions.flatMap(
                  (userAction) => userAction.userTasks
                )
              )
            "
            @indicator-clicked="toggleTask(collection, index, innerIndex)"
            @checked="(input) => selectTaskForReminder(collection, input)"
          />
        </table-cell>
      </table-row>
    </table-body>
  </g-table>
</template>

<script>
import { add, parseISO } from 'date-fns'
import { sortBy, filter, find, some, get } from 'lodash'

import { api } from '@/api'
import { toast } from '@/toasts'

import humanReadableStringMixin from '@/mixins/v2/human_readable_string_mixin'
import dateMixin from '@/mixins/v2/dateMixin'
import actionStatusMixin from '@/mixins/v2/action_status_mixin'
import userActionStatusIndicator from '@/components/UserActionStatusIndicator.vue'
import defaultProfileImage from '@/assets/img/profile_avatar_small.png'

import gTable from '@/components/v2/table/table.vue'
import tableHead from '@/components/v2/table/head.vue'
import tableCell from '@/components/v2/table/cell.vue'
import tableHeader from '@/components/v2/table/header.vue'
import tableBody from '@/components/v2/table/body.vue'
import tableRow from '@/components/v2/table/row.vue'
import gImage from '@/components/v2/image.vue'

const TASK_NAME_CHARACTER_LIMIT = 27
const PERSON_NAME_CHARACTER_LIMIT = 16

export default {
  components: {
    userActionStatusIndicator,
    gTable,
    tableHead,
    tableCell,
    tableHeader,
    tableBody,
    tableRow,
    gImage,
  },
  mixins: [dateMixin, humanReadableStringMixin, actionStatusMixin],
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return ['date', 'checklist'].includes(value)
      },
    },
    moment: {
      type: Object,
      required: true,
    },
    actions: {
      type: Array,
      required: true,
    },
    sections: {
      type: Array,
      required: true,
    },
    tasks: {
      type: Array,
      required: true,
    },
    userTasks: {
      type: Array,
      required: true,
    },
    userActions: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    enrollees: {
      type: Array,
      required: true,
    },
    aspects: {
      type: Array,
      required: true,
    },
    userAspects: {
      type: Array,
      required: true,
    },
    recipients: {
      type: Array,
      required: true,
    },
    relationships: {
      type: Array,
      required: true,
    },
    actionTasks: {
      type: Array,
      required: true,
    },
    showReminderSelection: {
      type: Boolean,
      default: false,
    },
    organization: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      PERSON_NAME_CHARACTER_LIMIT,
      defaultProfileImage,
      selectedReminders: [],
      secondHeaderPosition: 0,
      thirdHeaderPosition: 0,
      patchingUserActionUserTasks: [],
    }
  },
  computed: {
    userActionDictionary() {
      const dictionary = new Map()

      this.actions.forEach((action) => {
        if (action.type !== 'moment') {
          const userActions = filter(this.userActions, { actionId: action.id })
          const key = JSON.stringify(action.relativeDate)
          if (dictionary.has(key)) {
            const existing = dictionary.get(key)
            dictionary.set(key, {
              userActions: existing.userActions.concat(userActions),
              actions: existing.actions.concat(action),
            })
          } else {
            dictionary.set(key, {
              userActions,
              actions: [action],
            })
          }
        }
      })
      return dictionary
    },
    relativeDates() {
      return sortBy(Array.from(this.userActionDictionary.keys()), (key) =>
        add(new Date(), JSON.parse(key))
      )
    },
    sortedActions() {
      return this.relativeDates.flatMap((relativeDate) =>
        sortBy(
          this.userActionDictionary.get(relativeDate).actions,
          (action) => action.order
        )
      )
    },
    firstTableHeader() {
      if (this.type === 'date') {
        return this.relativeDates
      }
      if (this.type === 'checklist') {
        return this.sections
      }
      throw new Error("Can't build first header")
    },
    secondTableHeader() {
      if (this.type === 'date') {
        return this.sortedActions.map((action) => ({
          name: action.type,
          action,
        }))
      }
      if (this.type === 'checklist') {
        return this.tasks.map((task) => ({ name: task.name, action: null }))
      }

      throw new Error("Can't build second header")
    },
    thirdTableHeader() {
      if (this.type === 'date') {
        return this.sortedActions.map((action) =>
          this.convertRecipientListToReadableString(
            filter(this.recipients, { actionId: action.id })
          )
        )
      }
      if (this.type === 'checklist') {
        return this.tasks.map((task) => this.findOwner(task))
      }

      throw new Error("Can't build third header")
    },
    tableData() {
      let allRows = []

      // for date type, use all user actions
      if (this.type === 'date') {
        allRows = this.enrollees.map((user) => {
          const tableRow = {
            user,
            collection: [],
          }
          this.sortedActions.forEach((sortedAction) => {
            // filter because we want to show how many user actions are connected to each action + enrollee combo
            const userActions = this.userActionDictionary
              .get(JSON.stringify(sortedAction.relativeDate))
              .userActions.filter(
                (userAction) =>
                  userAction.enrolleeId === user.id &&
                  userAction.actionId === sortedAction.id
              )

            tableRow.collection.push({
              userActions,
              action: sortedAction,
            })
          })
          return tableRow
        })
      }

      // for checklist type, use only tasks
      if (this.type === 'checklist') {
        allRows = this.enrollees.map((user) => {
          const row = this.tasks.flatMap((task) => {
            const userTask = find(this.userTasks, {
              userId: user.id,
              taskId: task.id,
            })
            let userAction
            for (const [, value] of this.userActionDictionary.entries()) {
              userAction = find(value.userActions, {
                id: (userTask || {}).userActionId,
              })
              if (userAction) {
                break
              }
            }

            let action
            if (userAction) {
              action = find(this.actions, { id: userAction.actionId })
            } else {
              action = (
                find(this.actionTasks, (actionTask) =>
                  actionTask.tasks.some((t) => t.id === task.id)
                ) || {}
              ).action
            }

            if (action) {
              return {
                // drop association with other user task so that we can check each task off individually
                userActions: userAction
                  ? [{ ...userAction, userTasks: [userTask] }]
                  : [],
                action,
              }
            }
            return []
          })
          return {
            user,
            collection: row,
          }
        })
      }
      const rowsWithSomeData = allRows.filter((row) =>
        some(
          row.collection.map((collection) => collection.userActions),
          (arr) => arr.length > 0
        )
      )
      return rowsWithSomeData.length ? rowsWithSomeData : allRows
    },
  },

  watch: {
    showReminderSelection: {
      handler() {
        this.selectedReminders = []
      },
    },
    userActions: {
      handler() {
        this.patchingUserActionUserTasks = []
      },
    },
  },

  // updated and mounted to handle fixed header height on page-load and v-if event
  updated() {
    this.$nextTick(() => {
      this.setFixedHeaders()
    })
  },
  mounted() {
    this.$nextTick(() => {
      this.setFixedHeaders()
    })
  },
  methods: {
    /**
     * set fixed headers for table
     */
    setFixedHeaders() {
      this.secondHeaderPosition = get(
        this.$refs,
        'firstHeader.$el.clientHeight',
        0
      )
      this.thirdHeaderPosition =
        this.secondHeaderPosition +
        get(this.$refs, 'secondHeader.$el.clientHeight', 0)
    },
    firstHeaderTitle(header) {
      if (this.type === 'date') {
        return this.formatDelta(JSON.parse(header))
      }
      if (this.type === 'checklist') {
        return header.name
      }
      throw new Error(`Got unexpected type ${this.type} for firstHeaderTitle`)
    },
    firstTableHeaderColspan(header) {
      if (this.type === 'date') {
        return get(this.userActionDictionary.get(header), 'actions.length', 1)
      }
      if (this.type === 'checklist') {
        return (
          filter(this.tasks, {
            checklistSectionId: (find(this.sections, { id: header.id }) || {})
              .id,
          }).length || 1
        )
      }
      throw new Error(
        `Got unexpected type ${this.type} for firstTableHeaderColspan`
      )
    },
    firstTableHeaderColor(header) {
      if (this.type === 'date') {
        return ''
      }
      if (this.type === 'checklist') {
        return (find(this.sections, { id: header.id }) || {}).color
      }
      throw new Error(
        `Got unexpected type ${this.type} for firstTableHeaderColor`
      )
    },
    secondHeaderTitle(name, action) {
      if (this.type === 'date') {
        return this.getSpecificPhraseForAction(action)
      }
      if (this.type === 'checklist') {
        return this.shortenText(name, TASK_NAME_CHARACTER_LIMIT)
      }
      throw new Error(`Got unexpected type ${this.type} for secondHeaderTitle`)
    },
    thirdHeaderTitle(text) {
      return this.shortenText(text, PERSON_NAME_CHARACTER_LIMIT)
    },
    showPopupForIndicator(collection, user) {
      if (!this.moment.isActive) {
        return false
      }
      const status = this.getStatusForUserActions(
        collection.userActions,
        collection.action,
        user,
        this.findTrigger(user)
      )
      if (status === 'neverSent') {
        return false
      }
      if (this.type === 'checklist' && collection.action.type === 'task') {
        return false
      }

      return true
    },
    findOwner(task) {
      if (task.ownerType === 'user') {
        return (find(this.users, { id: task.ownerUserId }) || {}).fullName
      }
      if (task.ownerType === 'relationship') {
        return find(this.relationships, { id: task.ownerRelationshipId }).name
      }
      if (task.ownerType === 'enrollee') {
        return 'Enrollee'
      }

      throw new Error(`unknown task owner ${task.ownerType}`)
    },
    findTrigger(user) {
      if (this.moment.type === 'date') {
        return parseISO(this.moment.triggerDate)
      }

      if (this.moment.type === 'aspect') {
        const userAspect = find(this.userAspects, {
          aspectId: this.moment.aspectId,
          userId: user.id,
        })
        if (userAspect && userAspect.value) {
          // Have to cancel out timezone offset here because these are (time-agnostic) dates, but dateMixin.formatDate
          // treats them like date times w/ the time set to midnight.
          return add(new Date(userAspect.value), {
            minutes: new Date(userAspect.value).getTimezoneOffset(),
          })
        }
        return null
      }
      if (this.moment.type === 'birthday') {
        return user.birthday ? parseISO(user.birthday) : null
      }
      if (this.moment.type === 'end_date') {
        return user.endDate ? parseISO(user.endDate) : null
      }
      if (
        this.moment.type === 'start_date' ||
        this.moment.type === 'work_anniversary'
      ) {
        return user.startDate ? parseISO(user.startDate) : null
      }

      throw new Error(`this moment type ${this.moment.type} is unsupported`)
    },
    formatTrigger(user) {
      const date = this.findTrigger(user)
      if (this.moment.type === 'aspect' && this.moment.aspectId && !date) {
        return `${
          (find(this.aspects, { id: this.moment.aspectId }) || {}).name
        }: Not Added`
      }
      return ['start_date', 'end_date'].includes(this.moment.type)
        ? this.formatDate(date, true)
        : this.formatDate(date)
    },
    getStatus(collection, user) {
      if (!this.moment.isActive) {
        return 'scheduled'
      }

      return this.getStatusForUserActions(
        collection.userActions,
        collection.action,
        user,
        this.findTrigger(user)
      )
    },
    getError(collection, user) {
      if (this.getStatus(collection, user) === 'error') {
        return this.getErrorMessageForUserActions(
          collection,
          this.findTrigger(user)
        )
      }
      return ''
    },
    getUserActionDate(collection) {
      if (this.type === 'date') {
        if (
          collection.action.type === 'task' &&
          collection.userActions.length &&
          this.isAnyUserTaskDone(collection.userActions)
        ) {
          return get(collection.userActions[0], 'userTasks[0].updatedAt', '')
        }
        return get(collection.userActions[0], 'actionDate', '')
      }
      if (this.type === 'checklist') {
        return get(collection.userActions[0], 'userTasks[0].deadline', '')
      }
      throw new Error(`Got unexpected type ${this.type} for getUserActionDate`)
    },
    async toggleTask(collection, index, innerIndex) {
      if (this.patchingUserActionUserTasks.length) {
        return
      }
      const userTasks = collection.userActions.flatMap(
        (userAction) => userAction.userTasks
      )
      try {
        // HACK: instead of handling the loading state in the finally block, the UX is improved tremendously
        // by waiting until the watcher for user actions kicks off to remove the loading state
        this.patchingUserActionUserTasks = [index, innerIndex]
        await Promise.all(
          userTasks.map(async (userTask) => {
            await api.patch(
              `${process.env.VUE_APP_DB_ENDPOINT}/v2/user-tasks/${userTask.id}`,
              { isComplete: !userTask.isComplete }
            )
          })
        )
      } catch (error) {
        toast.error(error)
      }
      this.$emit('get-user-actions')
    },
    selectTaskForReminder(collection, input) {
      const userTasks = collection.userActions.flatMap(
        (userAction) => userAction.userTasks
      )
      userTasks.forEach((userTask) => {
        if (input) {
          this.selectedReminders = this.selectedReminders.concat(userTask)
        } else {
          this.selectedReminders = filter(
            this.selectedReminders,
            (reminder) => reminder.id !== userTask.id
          )
        }

        this.$emit('reminders-added', this.selectedReminders)
      })
    },
    shortenText(text, limit) {
      if (!text) {
        return text
      }
      if (text.length > limit) {
        return text.substr(0, limit).concat('...')
      }
      return text
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';
@import '~@/assets/less/borders.less';
@import '~@/assets/less/text.less';

.timeline-table {
  border: 0;
}

.empty-column {
  left: 0;
  z-index: 801; // override fomantic z index for sticky
  width: 14em;
}

.task-column {
  padding: 0.75em 0.25em 0.5em 0.25em;
}

.task-name-container {
  display: inline-block;
  width: 10em;
  white-space: break-spaces;
}

.recipient-column {
  padding: 0.5em;
}

.status-indicator {
  min-width: 10em;
}

.user-cell {
  position: sticky;
  left: 0;
  z-index: 1; // To overcome the checkbox floating over it
  padding: 1em 1em 1em 0.75em;
  overflow: hidden;
  background: @white;
  border-right: @light-border;

  .content {
    min-width: 15em;
    font-family: @primary-font;

    .header {
      font-size: @title-font-size;
    }

    .sub {
      font-size: @description-font-size;
    }
  }
}

.user-row:hover {
  .user-cell {
    background-color: @table-hover;
  }
}
</style>
