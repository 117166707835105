<template>
  <grid class="button-form">
    <grid-column v-if="action.buttonUrl" sixteen>
      <flex align="center" gap="1rem">
        <btn basic variant="primary" @click.native="editButtonUrl">
          {{ action.buttonName }}
        </btn>
        <btn
          dismiss
          compact
          tooltip="Remove Button"
          :loading="clearingUrl"
          @click.native="removeButtonUrl"
        />
      </flex>
    </grid-column>
    <grid-column v-if="!formType && !action.buttonUrl" sixteen>
      <dropdown
        name="Buttons"
        placeholder="Add Button"
        label="name"
        reduced-field="value"
        :options="buttonOptions"
        @update="(value) => (formType = value)"
      />
    </grid-column>
    <grid-column v-if="formType" sixteen>
      <g-form
        v-if="formType === 'link'"
        :id="`normal-link-${action.id}`"
        @submit="saveButtonUrl"
      >
        <template #default="{ valid }">
          <form-validation
            :valid="valid"
            @update="(value) => (isLinkFormValid = value)"
          />
          <form-field>
            <form-label> Button Name </form-label>
            <form-input
              name="Button Name"
              :rules="['required', 'max:255']"
              :initial-value="action.buttonName || '🔗 Check it out'"
              placeholder="Call to action"
            />
          </form-field>
          <form-field>
            <form-label> Link </form-label>
            <form-input
              name="Link"
              :rules="['url', 'required']"
              :initial-value="action.buttonUrl"
              placeholder="https://"
            />
          </form-field>
          <flex justify="space-between">
            <form-btn
              variant="default"
              type="reset"
              :form-id="`normal-link-${action.id}`"
              @click.native="resetButtonForm"
            >
              Cancel
            </form-btn>

            <form-btn
              variant="primary"
              :loading="savingUrl"
              :form-id="`normal-link-${action.id}`"
              :invalid="!isLinkFormValid"
            >
              Save Button
            </form-btn>
          </flex>
        </template>
      </g-form>
      <g-form
        v-if="formType === 'dm'"
        :id="`dm-${action.id}`"
        @submit="saveButtonUrl"
      >
        <template #default="{ valid }">
          <form-validation
            :valid="valid"
            @update="(value) => (isLinkDmFormValid = value)"
          />
          <form-field>
            <form-label> Button Name </form-label>
            <form-input
              name="Button Name"
              :rules="['required', 'max:255']"
              :initial-value="action.buttonName || '🧑 Send Message'"
              placeholder="Call to action"
            />
          </form-field>
          <form-field>
            <form-label> Person </form-label>
            <form-dropdown
              name="People"
              required
              searchable
              :options="users"
              label="fullName"
              reduced-field="slackUserId"
              :value="getSearchParam(action.buttonUrl, 'id')"
              placeholder="Select Person"
            />
          </form-field>
          <flex justify="space-between">
            <form-btn
              variant="default"
              type="reset"
              :form-id="`dm-${action.id}`"
              @click.native="resetButtonForm"
            >
              Cancel
            </form-btn>

            <form-btn
              variant="primary"
              :loading="savingUrl"
              :form-id="`dm-${action.id}`"
              :invalid="!isLinkDmFormValid"
            >
              Save Button
            </form-btn>
          </flex>
        </template>
      </g-form>
      <g-form
        v-if="formType === 'channel'"
        :id="`channel-${action.id}`"
        @submit="saveButtonUrl"
      >
        <template #default="{ valid }">
          <form-validation
            :valid="valid"
            @update="(value) => (isLinkChannelFormValid = value)"
          />
          <form-field>
            <form-label> Button Name </form-label>
            <form-input
              name="Button Name"
              :rules="['required', 'max:255']"
              :initial-value="action.buttonName || 'Post in Channel'"
              placeholder="Call to action"
            />
          </form-field>
          <form-field>
            <form-label> Slack Channel </form-label>
            <form-dropdown
              name="Channels"
              required
              searchable
              :value="getSearchParam(action.buttonUrl, 'id')"
              placeholder="Select Channel"
              :options="slackConversations"
              label="displayName"
              reduced-field="conversationId"
            >
            </form-dropdown>
          </form-field>
          <flex justify="space-between">
            <form-btn
              variant="default"
              type="reset"
              :form-id="`channel-${action.id}`"
              @click.native="resetButtonForm"
            >
              Cancel
            </form-btn>

            <form-btn
              variant="primary"
              :loading="savingUrl"
              :form-id="`channel-${action.id}`"
              :invalid="!isLinkChannelFormValid"
            >
              Save Button
            </form-btn>
          </flex>
        </template>
      </g-form>
      <g-form
        v-if="formType === 'calendar'"
        :id="`calendar-${action.id}`"
        @submit="saveButtonUrl"
      >
        <template #default="{ valid }">
          <form-validation
            :valid="valid"
            @update="(value) => (isLinkCalendarFormValid = value)"
          />
          <form-field>
            <form-label> Button Name </form-label>
            <form-input
              name="Button Name"
              :rules="['required', 'max:255']"
              :initial-value="action.buttonName || '📅 Create Calendar Event'"
              placeholder="Call to action"
            />
          </form-field>
          <form-field>
            <form-label> Event Name </form-label>
            <form-input
              name="Event Name"
              :rules="['required', 'max:255']"
              :initial-value="getSearchParam(action.buttonUrl, 'text')"
              placeholder="30 Day Check In"
            />
          </form-field>
          <form-field>
            <form-label> Event Description </form-label>
            <form-textarea
              name="Event Description"
              :rules="['max:1500']"
              :initial-value="getSearchParam(action.buttonUrl, 'details')"
              placeholder="Give context or other useful information"
            />
          </form-field>
          <flex justify="space-between">
            <form-btn
              :form-id="`calendar-${action.id}`"
              variant="default"
              type="reset"
              @click.native="resetButtonForm"
            >
              Cancel
            </form-btn>

            <form-btn
              :form-id="`calendar-${action.id}`"
              variant="primary"
              :loading="savingUrl"
              :invalid="!isLinkCalendarFormValid"
            >
              Save Button
            </form-btn>
          </flex>
        </template>
      </g-form>
      <g-form v-if="formType === 'email'" id="email" @submit="saveButtonUrl">
        <template #default="{ valid }">
          <form-validation
            :valid="valid"
            @update="(value) => (isLinkEmailFormValid = value)"
          />
          <form-field>
            <form-label> Button Name </form-label>
            <form-input
              name="Button Name"
              :rules="['required', 'max:255']"
              :initial-value="
                action.buttonName || '✉️ Create Email From Template'
              "
              placeholder="Call to action"
            />
          </form-field>
          <form-field>
            <form-label> To </form-label>
            <form-dropdown
              name="To"
              :value="getSearchParam(action.buttonUrl, 'to')"
              placeholder="Select Person"
              :options="users"
              reduced-field="email"
              searchable
              label="fullName"
            >
            </form-dropdown>
          </form-field>
          <form-field>
            <form-label> Subject </form-label>
            <form-input
              name="Subject"
              :rules="['max:255']"
              :initial-value="getSearchParam(action.buttonUrl, 'su')"
              placeholder="Give context or other useful information"
            />
          </form-field>
          <form-field>
            <form-label> Email Body </form-label>
            <form-textarea
              name="Email Body"
              :rules="['max:1500']"
              :initial-value="getSearchParam(action.buttonUrl, 'body')"
              placeholder="Give context or other useful information"
            />
          </form-field>
          <flex justify="space-between">
            <form-btn
              variant="default"
              type="reset"
              form-id="email"
              @click.native="resetButtonForm"
            >
              Cancel
            </form-btn>
            <form-btn
              variant="primary"
              :loading="savingUrl"
              form-id="email"
              :invalid="!isLinkEmailFormValid"
            >
              Save Button
            </form-btn>
          </flex>
        </template>
      </g-form>
    </grid-column>
  </grid>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import dropdown from '@/components/v3/dropdown.vue'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import gForm from '@/components/v2/form/form.vue'
import formField from '@/components/v2/form/field.vue'
import formInput from '@/components/v2/form/input.vue'
import formTextarea from '@/components/v2/form/textarea.vue'
import formBtn from '@/components/v2/form/btn.vue'
import formLabel from '@/components/v2/form/label.vue'
import formValidation from '@/components/v2/form/validation.vue'
import formDropdown from '@/components/v3/form_dropdown.vue'
import btn from '@/components/v2/btn.vue'
import flex from '@/components/v2/flex.vue'

import defaultUserAvatar from '@/assets/img/profile_avatar_small.png'

export default {
  components: {
    dropdown,
    grid,
    gridColumn,
    gForm,
    formField,
    formInput,
    formTextarea,
    formBtn,
    formLabel,
    formValidation,
    formDropdown,
    btn,
    flex,
  },
  props: {
    action: {
      type: Object,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    slackConversations: {
      type: Array,
      default: null,
    },
    slackIntegration: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      defaultUserAvatar,
      formType: null,
      form: null,
      isLinkFormValid: false,
      isLinkDmFormValid: false,
      isLinkChannelFormValid: false,
      isLinkEmailFormValid: false,
      isLinkCalendarFormValid: false,
      clearingUrl: false,
      savingUrl: false,
      buttonOptions: [
        {
          name: '🔗 Link',
          value: 'link',
        },
        {
          name: '🧑 Link To Direct Message',
          value: 'dm',
        },
        {
          name: '#️⃣ Link To Channel',
          value: 'channel',
        },
        {
          name: '📅‍ Create Calendar Event',
          value: 'calendar',
        },
        {
          name: '📨 Create Email ',
          value: 'email',
        },
      ],
    }
  },
  methods: {
    async saveButtonUrl(submission) {
      try {
        this.savingUrl = true
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${this.action.id}`,
          {
            buttonName: submission.get('Button Name'),
            buttonUrl: this.buildButtonUrl(submission),
          }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.savingUrl = false
      }
      this.$emit('get-actions')
      this.resetButtonForm()
    },
    editButtonUrl() {
      if (this.formType) {
        this.resetButtonForm()
        return
      }
      const url = new URL(this.action.buttonUrl)
      if (url.href.includes('mail.google.com')) {
        this.formType = 'email'
      } else if (url.href.includes('calendar.google.com')) {
        this.formType = 'calendar'
      } else if (url.href.includes('slack://channel')) {
        this.formType = 'channel'
      } else if (url.href.includes('slack://user')) {
        this.formType = 'dm'
      } else {
        this.formType = 'link'
      }
    },
    buildButtonUrl(submission) {
      if (this.formType === 'email') {
        const url = new URL('https://mail.google.com/mail/?view=cm&fs=1&tf=1')
        url.searchParams.set('to', submission.get('To') || '')
        url.searchParams.set('su', submission.get('Subject') || '')
        url.searchParams.set('body', submission.get('Email Body') || '')
        return url.href
      }
      if (this.formType === 'calendar') {
        const url = new URL('https://calendar.google.com/calendar/r/eventedit')
        url.searchParams.set('text', submission.get('Event Name') || '')
        url.searchParams.set(
          'details',
          submission.get('Event Description') || ''
        )
        return url.href
      }
      if (this.formType === 'channel') {
        return this.getChannelUrl(submission.get('Channels'))
      }
      if (this.formType === 'dm') {
        return this.getDirectMessageUrl(submission.get('People'))
      }
      if (this.formType === 'link') {
        return submission.get('Link')
      }

      throw new Error(`Unknown button form ${this.formType}`)
    },
    resetButtonForm() {
      this.formType = null
    },
    getDirectMessageUrl(slackUserId) {
      if (!slackUserId || !this.slackIntegration) {
        return ''
      }
      const url = new URL('slack://user')
      url.searchParams.set('team', this.slackIntegration.workspaceId)
      url.searchParams.set('id', slackUserId)
      return url.href
    },
    getChannelUrl(conversationId) {
      if (!conversationId) {
        return ''
      }
      const url = new URL('slack://channel')
      url.searchParams.set('id', conversationId)
      url.searchParams.set('team', this.slackIntegration.workspaceId)
      return url.href
    },
    getSearchParam(url, param) {
      if (url) {
        return new URL(url).searchParams.get(param)
      }
      return null
    },
    async removeButtonUrl() {
      try {
        this.clearingUrl = true
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${this.action.id}`,
          {
            buttonName: null,
            buttonUrl: null,
          }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.clearingUrl = false
      }
      this.$emit('get-actions')
      this.resetButtonForm()
    },
  },
}
</script>

<style lang="less" scoped>
.button-form {
  margin-top: 0.5rem;
}
</style>
