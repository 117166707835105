<template>
  <list-content>
    <flex align="center" justify="space-between" gap="1rem">
      <flex align="center" gap="0.25rem">
        {{ carbonCopy.name }}
        <btn
          dismiss
          tooltip="Remove"
          size="tiny"
          compact
          :loading="deletingCarbonCopy"
          @click.native.prevent="deleteCarbonCopy"
        />
      </flex>
    </flex>
  </list-content>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import listContent from '@/components/v2/list/content.vue'
import flex from '@/components/v2/flex.vue'
import btn from '@/components/v2/btn.vue'

export default {
  components: {
    listContent,
    flex,
    btn,
  },
  props: {
    action: {
      type: Object,
      required: true,
    },
    carbonCopy: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      patchingField: null,
      deletingCarbonCopy: false,
    }
  },
  methods: {
    async deleteCarbonCopy() {
      try {
        this.deletingCarbonCopy = true
        await api.delete(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${this.action.id}/carbon-copies/${this.carbonCopy.id}`
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.deletingCarbonCopy = false
      }

      this.$emit('get-carbon-copies')
    },
    async patchCarbonCopy(field, value) {
      try {
        this.patchingField = field
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${this.action.id}/carbon-copies/${this.carbonCopy.id}`,
          {
            [field]: value,
          }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingField = null
      }

      this.$emit('get-carbon-copies')
    },
  },
}
</script>
