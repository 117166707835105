<template>
  <flex v-click-outside="() => (showOptions = false)" direction="column">
    <flex align="center" justify="space-between">
      <strong> Send Replies To </strong>
      <btn
        v-if="!hasReplyTo"
        tooltip="Add Reply To"
        circular
        compact
        icon="fas fa-plus"
        @click.native="() => (showOptions = !showOptions)"
      />
    </flex>
    <flex justify="space-between" align="center">
      <loading v-if="loading" loading inline size="tiny" />
      <template v-else>
        <placeholder v-if="!hasReplyTo" warning>
          ⚠️ Click + to add a reply-to option to this step
        </placeholder>
        <list v-else class="reply-to-list" horizontal selection>
          <list-item>
            <list-content>
              <flex align="center" gap="0.25rem">
                {{ action.replyToName }}
                <btn
                  dismiss
                  tooltip="Remove"
                  compact
                  :loading="removingReplyTo"
                  @click.native.prevent="removeReplyTo"
                /> </flex
            ></list-content>
          </list-item>
        </list>
      </template>
    </flex>
    <flex v-if="showOptions" wrap="wrap" gap="0.25rem 0">
      <dropdown
        class="reply-to-dropdown"
        name="People"
        placeholder="+ Person"
        hide-caret
        :loading="patchingField === 'user'"
        :disabled="!users.length"
        :options="users"
        label="fullName"
        reduced-field="id"
        searchable
        @update="(id) => patchAction('replyToUserId', id)"
      />
      <dropdown
        name="Groups"
        class="reply-to-dropdown"
        placeholder="+ Group"
        hide-caret
        searchable
        :disabled="!groups.length"
        reduced-field="id"
        label="name"
        :options="groups"
        :loading="patchingField === 'replyToGroupId'"
        @update="(id) => patchAction('replyToGroupId', id)"
      />
      <dropdown
        name="Relationships"
        class="reply-to-dropdown relationship"
        placeholder="+ Relationship"
        hide-caret
        :options="relationships"
        :disabled="!relationships.length"
        :loading="patchingField === 'replyToRelationshipId'"
        reduced-field="id"
        label="name"
        searchable
        @update="(id) => patchAction('replyToRelationshipId', id)"
      />
    </flex>
  </flex>
</template>

<script>
import vClickOutside from 'v-click-outside'

import { api } from '@/api'
import { toast } from '@/toasts'

import dropdown from '@/components/v3/dropdown.vue'
import list from '@/components/v2/list/list.vue'
import listItem from '@/components/v2/list/item.vue'
import listContent from '@/components/v2/list/content.vue'
import flex from '@/components/v2/flex.vue'
import btn from '@/components/v2/btn.vue'
import placeholder from '@/components/v2/placeholder.vue'
import loading from '@/components/v2/loading.vue'

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    dropdown,
    list,
    listItem,
    listContent,
    flex,
    btn,
    placeholder,
    loading,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
    relationships: {
      type: Array,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showOptions: false,
      patchingField: null,
      removingReplyTo: false,
    }
  },
  computed: {
    hasReplyTo() {
      return !!(
        this.action.replyToUserId ||
        this.action.replyToRelationshipId ||
        this.action.replyToGroupId
      )
    },
  },
  watch: {
    hasReplyTo: {
      handler(newValue) {
        if (newValue) {
          this.showOptions = false
        }
      },
    },
  },
  methods: {
    async patchAction(field, value) {
      try {
        this.patchingField = field
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${this.action.id}`,
          {
            ...{
              replyToRelationshipId: null,
              replyToGroupId: null,
              replyToUserId: null,
            },
            ...{ [field]: value },
          }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingField = null
      }
      this.$emit('get-actions')
    },
    async removeReplyTo() {
      try {
        this.removingReplyTo = true
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${this.action.id}`,
          {
            replyToRelationshipId: null,
            replyToGroupId: null,
            replyToUserId: null,
          }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.removingReplyTo = false
      }
      this.$emit('get-actions')
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';

.reply-to-list {
  margin: 0;
}

.reply-to-dropdown {
  margin: 0 0.25em 0 0;

  ::v-deep.vs__selected-options .vs__search:only-child {
    max-width: 8rem;
  }
}
</style>
