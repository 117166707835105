<template>
  <div>
    <input
      :value="JSON.stringify(computedDelta && computedDelta.value)"
      :name="name"
      type="hidden"
    />
    <dropdown
      button
      :name="name"
      icon="fas fa-calendar"
      hide-caret
      placeholder="Select Date"
      :value="computedDelta"
      tooltip="Select Date"
      :loading="loading"
      :disabled="disabled"
      :options="options"
      label="name"
      simple
      :append-to-body="appendToBody"
      :placement="placement"
      class="delta-picker"
      @update="onChange"
    >
    </dropdown>
  </div>
</template>

<script>
import { add } from 'date-fns'
import dateMixin from '@/mixins/v2/dateMixin'
import dropdown from '@/components/v3/dropdown.vue'

export default {
  components: {
    dropdown,
  },
  mixins: [dateMixin],
  props: {
    appendToBody: {
      type: Boolean,
      default: false,
    },
    delta: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    start: {
      type: Object,
      default: null,
    },
    end: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: 'Delta',
    },
    nullable: {
      type: Boolean,
      default: false,
    },
    nullLabel: {
      type: String,
      default: 'None',
    },
    placement: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      internalSelection: null,
    }
  },
  computed: {
    options() {
      const now = new Date()
      let newOptions = this.deltaOptions
      if (this.start) {
        newOptions = newOptions.filter(
          ({ value }) =>
            add(now, value).getTime() >= add(now, this.start).getTime()
        )
      }

      if (this.end) {
        newOptions = newOptions.filter(
          ({ value }) =>
            add(now, value).getTime() < add(now, this.end).getTime()
        )
      }

      if (this.nullable) {
        newOptions = [{ value: null, name: this.nullLabel }].concat(newOptions)
      }

      return newOptions
    },
    computedDelta() {
      if (this.form && this.internalSelection) {
        return this.internalSelection
      }

      return (
        this.options.find(
          (option) =>
            JSON.stringify(option.value) === JSON.stringify(this.delta)
        ) || null
      )
    },
  },
  methods: {
    onChange(option) {
      this.internalSelection = option
      this.$emit('update', (option || {}).value || null)
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/colors.less';

.delta-picker {
  font-weight: 400;
  color: @blue;
  background-color: @white;

  ::v-deep&.vs--single.vs--open {
    .vs__selected {
      position: relative;
    }
  }
}
</style>
