// ========================================== // Author: @johnwetzel // Purpose:
An indicator of the status of a specific userAction //
==========================================

<template>
  <loading v-if="loading" :loading="loading" inline />
  <span v-else class="user-action-indicator" @click="indicatorClicked">
    <!-- Hover State with More Info -->
    <tippy v-if="hasPopUp" :to="uniqueIdentifier" arrow="true" interactive>
      <user-action-status-hover
        :key="hoverKey"
        :status="status"
        :organization="organization"
        :action-type="actionType"
        :action-time="userActionDate"
        :error-message="errorMessage"
      />
    </tippy>

    <!-- Reminder Select Mode -->
    <child-checkbox
      v-if="showReminderCheckbox"
      class="reminder-checkbox"
      @input="(input) => $emit('checked', input)"
    />

    <pre
      v-else-if="statusText"
      :name="uniqueIdentifier"
      class="grey status-text"
    >
        {{ statusText }}
    </pre>

    <!-- Status Indicator Circle -->
    <i
      v-else
      :class="[
        statusIcon,
        color,
        {
          'reminder-mode-fade': isReminderSetupMode,
          'clickable-indicator': isClickable,
        },
      ]"
      :style="[
        { backgroundImage: status === 'partialComplete' ? linearGradient : '' },
      ]"
      :name="uniqueIdentifier"
    />
  </span>
</template>

<script>
import childCheckbox from '@/components/jquery/ChildCheckbox.vue'
import userActionStatusHover from '@/components/UserActionStatusHover.vue'
import loading from '@/components/v2/loading.vue'

export default {
  components: { childCheckbox, userActionStatusHover, loading },
  props: {
    status: {
      type: String,
      required: true,
    },
    actionType: {
      type: String,
      required: true,
    },
    isReminderSetupMode: {
      type: Boolean,
      default: false,
    },
    hasPopUp: {
      type: Boolean,
      default: false,
    },
    isClickable: {
      type: Boolean,
      default: false,
    },
    userActionDate: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    percentageComplete: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    organization: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      hoverKey: 0,
      uniqueIdentifier: Math.random(), // A uniqueId to associate the tool tip with the correct indicator
    }
  },
  computed: {
    // HACK: can't use less here for color vars
    linearGradient() {
      const degree = 90 + 360 * this.percentageComplete
      if (this.percentageComplete < 0.5) {
        return (
          `linear-gradient(${degree}deg, transparent 50%, #FFF 50%), ` +
          'linear-gradient(90deg, #FFF 50%, transparent 50%)'
        )
      }
      if (this.percentageComplete > 0.5) {
        const degree = 360 * this.percentageComplete - 90
        return (
          `linear-gradient(${degree}deg, transparent 50%, #DBF4C7 50%), ` +
          'linear-gradient(90deg, #FFF 50%, transparent 50%)'
        )
      }
      if (this.percentageComplete === 0.5) {
        return 'linear-gradient(90deg, #FFF 50%, transparent 50%)'
      }

      throw new Error('Linear gradient could not be calculated')
    },
    canActionBeCompletedByUser() {
      return this.actionType === 'task' || this.actionType === 'relationship'
    },
    showReminderCheckbox() {
      if (this.isReminderSetupMode && this.canActionBeCompletedByUser) {
        return this.status === 'sent' || this.status === 'overdue'
      }
      return false
    },
    color() {
      const colors = {
        neverSent: '', // no icon so no color
        scheduled: 'status-light-grey-no-fill',
        sent: this.canActionBeCompletedByUser
          ? 'status-grey-no-fill'
          : 'status-green',
        error: 'status-red',
        complete: 'status-green',
        skipped: 'status-grey',
        overdue: 'status-yellow',
        blocked: 'status-purple',
        partialComplete: 'status-partial-green',
      }
      return colors[this.status]
    },
    actionTypeIcon() {
      const icons = {
        message: 'comment alternate',
        channel: 'hashtag',
        relationship: 'people arrows',
        task: 'checkmark',
        email: 'envelope',
        meeting: 'calendar',
      }
      return icons[this.actionType]
    },
    statusIcon() {
      const icons = {
        neverSent: '',
        scheduled: this.actionTypeIcon,
        sent: this.canActionBeCompletedByUser
          ? this.actionTypeIcon
          : `inverted ${this.actionTypeIcon}`,
        error: 'inverted exclamation',
        complete:
          this.actionTypeIcon === 'tasks'
            ? 'inverted checkmark'
            : `inverted ${this.actionTypeIcon}`,
        skipped: 'inverted share',
        overdue: 'inverted bell',
        blocked: 'inverted minus',
        partialComplete:
          this.actionTypeIcon === 'tasks'
            ? 'inverted checkmark'
            : `inverted ${this.actionTypeIcon}`,
      }

      return `circular ${icons[this.status]} icon`
    },
    statusText() {
      const statusMessages = {
        neverSent: 'Enrolled After\nSend Time',
      }
      return statusMessages[this.status]
    },
  },
  watch: {
    errorMessage: {
      handler() {
        this.hoverKey += 1
      },
    },
  },
  methods: {
    /**
     * Controls behavior if the component is clicked
     */
    indicatorClicked() {
      if (this.isClickable && !this.isReminderSetupMode) {
        this.$emit('indicator-clicked')
      }
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/colors.less';
@import '~@/assets/less/text.less';

.user-action-indicator {
  .icon {
    &.status-light-grey-no-fill {
      color: @light-grey;
      box-shadow: 0 0 0 0.1em @light-grey inset;
    }

    &.status-green {
      color: @green;
      background-color: @light-green;
    }

    &.status-partial-green {
      color: @green;
      background-color: @light-green;
      box-shadow: 0 0 0 0.1em @light-green inset;
    }

    &.status-red {
      // HACK: Make error more subdued until it can become properly nuanced
      color: @grey;
      background-color: @light-grey;
    }

    &.status-grey-no-fill {
      color: @grey;
      box-shadow: 0 0 0 0.1em @grey inset;
    }
    // For showing that that it can be marked complete on the dashboard

    &.clickable-indicator {
      &.status-grey-no-fill:hover,
      &.status-light-grey-no-fill:hover {
        color: @green;
        background-color: @light-green;
        box-shadow: none;
      }
    }

    &.status-grey {
      color: @grey;
      background-color: @light-grey;
    }

    &.status-yellow {
      color: @blue;
      background-color: @yellow;
    }

    &.status-purple {
      color: @purple;
      background-color: @light-purple;
    }

    &.reminder-mode-fade {
      color: rgba(0, 0, 0, 0.1);
    }
  }

  .reminder-checkbox {
    padding: 0.2em 0;
  }

  .status-text {
    margin: 0;
    font-family: @primary-font;
    font-size: @description-font-size;
  }
}

:focus {
  outline: none;
}
</style>
