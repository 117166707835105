export default {
  methods: {
    /**
     * convert multiple recipients to string
     *
     * @param {Array} recipients
     * @returns {string} - recipient names
     */
    convertRecipientListToReadableString(recipients) {
      let header = 'Slack Message'
      const names = recipients.map((recipient) =>
        this.getRecipientName(recipient)
      )
      if (names.length) {
        if (names.length === 1) {
          header = names.join('')
        } else if (names.length === 2) {
          header = names.join(' and ')
        } else {
          header = `${names.slice(0, 2).join(' and ')} and others`
        }
      }
      return header
    },
    /**
     * builds name of recipient based on type
     *
     * @param {object} recipient
     * @returns {string} - recipient name
     */
    getRecipientName(recipient) {
      if (!recipient) {
        return ''
      }

      if (recipient.model === 'enrollee') {
        return 'Enrollee'
      }

      return recipient.name
    },
    /**
     * @param {object} action - the action used
     * @returns {string} - a noun for the action type ex. Message
     */
    getGenericNounForAction(action) {
      const genericActionNames = {
        message: 'Message',
        channel: 'Channel',
        'archive-channel': 'Channel',
        relationship: 'Relationship',
        task: 'Tasks',
        email: 'Email',
        meeting: 'Meeting Invite',
        moment: '',
      }

      if (!Object.keys(genericActionNames).includes(action.type)) {
        throw new Error(`Unknown action type: ${action.type}`)
      }

      return genericActionNames[action.type]
    },
    /**
     * @param {object} action - the action used
     * @returns {string} - a noun for the action type ex. Message
     */
    getSpecificNounForAction(action) {
      // Note: Channel Names not included until readable, compiled version of action.channelName is available

      if (action.type === 'relationship' && action.relationship) {
        return action.relationship.name
      }

      if (action.type === 'task') {
        return 'Tasks'
      }

      return this.getGenericNounForAction(action)
    },
    /**
     * @param {object} action - the action used
     * @returns {string} - a verb for the action type ex. Send
     */
    getVerbForAction(action) {
      const genericActionVerbs = {
        message: 'Send',
        channel: 'Create',
        'archive-channel': 'Archive',
        relationship: 'Assign',
        task: 'Send',
        email: 'Send',
        meeting: 'Send',
        moment: '',
      }

      if (!Object.keys(genericActionVerbs).includes(action.type)) {
        throw new Error(`Unknown action type: ${action.type}`)
      }

      return genericActionVerbs[action.type]
    },
    /**
     * @param {string} action - the action used
     * @returns {string} - a phrase starting with a verb for the action ex. Send Message
     */
    getGenericPhraseForAction(action) {
      return `${this.getVerbForAction(action)} ${this.getGenericNounForAction(
        action
      )}`
    },
    /**
     * @param {string} action - the action used
     * @returns {string} - a phrase starting with a verb for the action ex. Send Message
     */
    getSpecificPhraseForAction(action) {
      return `${this.getVerbForAction(action)} ${this.getSpecificNounForAction(
        action
      )}`
    },
    /**
     * builds full name of a user from first and last
     *
     * @param {object|undefined} user
     * @returns {string} - full name name
     */
    formatFullName(user) {
      return user
        ? `${user.preferredFirstName || user.legalFirstName} ${
            user.lastName || ''
          }`.trim()
        : ''
    },
  },
}
