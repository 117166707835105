<template>
  <div class="text-editor message-content">
    <!-- this input is used for form submission -->
    <input type="hidden" :value="computedValue" :name="name" />
    <bubble-menu
      v-if="editor"
      class="bubble-menu"
      :tippy-options="{ duration: 100 }"
      :editor="editor"
    >
      <btn
        basic
        icon="fas fa-link"
        compact
        tooltip="Link"
        variant="secondary"
        size="mini"
        :class="{ active: editor.isActive('link') }"
        @click.native.prevent="setLink"
      />
      <btn
        v-if="editor.isActive('link')"
        basic
        icon="fas fa-unlink"
        compact
        tooltip="Unlink"
        variant="secondary"
        size="mini"
        @click.native.prevent="editor.chain().focus().unsetLink().run()"
      />
    </bubble-menu>
    <editor-content :editor="editor" />
    <flex v-if="editor" class="editor-buttons" justify="space-between">
      <flex align="center" wrap="wrap">
        <btn
          basic
          circular
          icon="fas fa-bold"
          compact
          tooltip="Bold"
          variant="default"
          size="mini"
          :class="{ active: editor.isActive('bold') }"
          @click.native.prevent="editor.chain().focus().toggleBold().run()"
        />
        <btn
          basic
          circular
          icon="fas fa-italic"
          compact
          tooltip="Italic"
          variant="default"
          size="mini"
          :class="{ active: editor.isActive('italic') }"
          @click.native.prevent="editor.chain().focus().toggleItalic().run()"
        />
        <btn
          basic
          circular
          icon="fas fa-strikethrough"
          compact
          tooltip="Strikethrough"
          variant="default"
          size="mini"
          :class="{ active: editor.isActive('strike') }"
          @click.native.prevent="editor.chain().focus().toggleStrike().run()"
        />
        <btn
          basic
          circular
          icon="fas fa-code"
          compact
          tooltip="Code"
          variant="default"
          size="mini"
          :class="{ active: editor.isActive('code') }"
          @click.native.prevent="editor.chain().focus().toggleCode().run()"
        />
        <btn
          basic
          circular
          icon="fas fa-list-ul"
          compact
          tooltip="Bulleted list"
          variant="default"
          size="mini"
          :class="{ active: editor.isActive('bulletList') }"
          @click.native.prevent="
            editor.chain().focus().toggleBulletList().run()
          "
        />
        <btn
          basic
          circular
          icon="fas fa-list-ol"
          compact
          tooltip="Ordered list"
          variant="default"
          size="mini"
          :class="{ active: editor.isActive('orderedList') }"
          @click.native.prevent="
            editor.chain().focus().toggleOrderedList().run()
          "
        />
        <btn
          basic
          circular
          icon="fas fa-quote-right"
          compact
          tooltip="Blockquote"
          variant="default"
          size="mini"
          :class="{ active: editor.isActive('blockquote') }"
          @click.native.prevent="
            editor.chain().focus().toggleBlockquote().run()
          "
        />
        <btn
          basic
          circular
          icon="fas fa-image"
          compact
          tooltip="Add image from URL"
          variant="default"
          size="mini"
          @click.native.prevent="addImage"
        />
        <btn
          basic
          circular
          icon="fas fa-link"
          compact
          tooltip="Link"
          variant="default"
          size="mini"
          :class="{ active: editor.isActive('link') }"
          @click.native.prevent="setLink"
        />
        <btn
          v-if="editor.isActive('link')"
          basic
          circular
          icon="fas fa-unlink"
          compact
          tooltip="Unlink"
          variant="default"
          size="mini"
          @click.native.prevent="editor.chain().focus().unsetLink().run()"
        />
        <btn
          basic
          circular
          icon="far fa-smile"
          compact
          tooltip="Emoji"
          variant="default"
          size="mini"
          @click.native.prevent="() => (showEmojis = !showEmojis)"
        />
        <personalization-token-dropdown
          ref="personalization"
          icon="far fa-address-card"
          inline
          :aspects="aspects"
          basic
          compact
          :hide-recipient-tokens="hideRecipientTokens"
          tooltip="Add Personalization"
          button-variant="default"
          size="mini"
          @update="insertToken"
        />
      </flex>
      <flex v-if="loading" align="center" justify="end">
        <loading loading size="mini" inline />
      </flex>
    </flex>
    <flex
      v-if="editor && percentage > 75"
      align="center"
      justify="end"
      class="character-count"
    >
      <svg
        height="20"
        width="20"
        viewBox="0 0 20 20"
        class="character-count graph"
      >
        <circle r="10" cx="10" cy="10" fill="#e9ecef" />
        <circle
          r="5"
          cx="10"
          cy="10"
          fill="transparent"
          stroke="currentColor"
          stroke-width="10"
          :stroke-dasharray="`calc(${percentage} * 31.4 / 100) 31.4`"
          transform="rotate(-90) translate(-20)"
        />
        <circle r="6" cx="10" cy="10" fill="white" />
      </svg>

      <div class="character-count text">
        {{ editor.getCharacterCount() }}/{{ characterLimit }} characters
      </div>
    </flex>
    <picker
      v-if="showEmojis"
      v-click-outside="() => (showEmojis = false)"
      class="emoji-picker"
      :data="emojiIndex"
      native
      title="Pick your emoji…"
      emoji="point_up"
      @select="selectEmoji"
    />
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
import { debounce } from 'lodash'
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Typography from '@tiptap/extension-typography'
import CharacterCount from '@tiptap/extension-character-count'
import Mention from '@tiptap/extension-mention'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'
import 'emoji-mart-vue-fast/css/emoji-mart.css'
import data from 'emoji-mart-vue-fast/data/all.json'

import { toast } from '../../toasts'

import btn from '@/components/v2/btn.vue'
import flex from '@/components/v2/flex.vue'
import personalizationTokenDropdown from '@/components/v2/personalization_token_dropdown.vue'
import loading from '@/components/v2/loading.vue'

export default {
  components: {
    EditorContent,
    BubbleMenu,
    btn,
    flex,
    Picker,
    personalizationTokenDropdown,
    loading,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'Text Editor',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    aspects: {
      type: Array,
      default: () => [],
    },
    characterLimit: {
      type: Number,
      default: 2000,
    },
    hideRecipientTokens: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editor: null,
      showEmojis: false,
      emojiIndex: new EmojiIndex(data),
      internalValue: null,
    }
  },
  computed: {
    percentage() {
      return Math.round(
        (100 / this.characterLimit) * this.editor.getCharacterCount()
      )
    },
    computedValue() {
      return this.internalValue || this.value
    },
  },
  watch: {
    value: {
      handler(newValue) {
        if (this.editor.getHTML() === newValue) {
          return
        }

        this.editor.commands.setContent(newValue, false)
      },
    },
  },
  mounted() {
    const CustomLink = Link.extend({
      addKeyboardShortcuts: () => ({ 'Mod-k': this.setLink }),
    })
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Typography,
        Image,
        CharacterCount.configure({
          limit: this.characterLimit,
        }),
        Mention.configure({
          HTMLAttributes: {
            class: 'mention',
          },
        }),
        CustomLink.configure({
          openOnClick: false,
        }),
      ],
      content: this.value,
      onUpdate: () => {
        if (this.editor.getHTML() !== this.internalValue) {
          this.internalValue = this.editor.getHTML()
        }
      },
      onBlur: debounce(() => {
        if (this.editor.getHTML() === this.value) {
          return
        }

        this.$emit('input', this.editor.getHTML())
      }, 250),
    })
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {
    async getImageFileSize(url) {
      return (await (await fetch(url)).blob()).size
    },
    async addImage() {
      const url = window.prompt('URL')
      if (url) {
        try {
          const fileSize = await this.getImageFileSize(url)
          // 2MB as bytes
          if (fileSize > 2000000) {
            toast.info(
              'This image might not expand automatically in Slack, you may want to test this message'
            )
          }
        } catch (error) {
          toast.info(
            "We weren't able to check the size of this image, you may want to test this message"
          )
        }
        this.editor.chain().focus().setImage({ src: url }).run()
      }
    },
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      let url = window.prompt('URL', previousUrl)

      url = this.fixUrl(url)

      if (url === null) {
        return
      }

      if (url === '') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run()

        return
      }

      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    },
    selectEmoji(emoji) {
      this.editor.chain().focus().insertContent(emoji.native).run()
      this.showEmojis = false
    },
    insertToken(value) {
      this.editor
        .chain()
        .focus()
        .insertContent([
          {
            type: 'mention',
            attrs: value.mention,
          },
          {
            type: 'text',
            text: ' ',
          },
        ])
        .run()
      this.editor.chain().blur()
    },
    fixUrl(url_) {
      if (!url_) {
        return url_
      }

      let url = url_
      // add protocol if not present
      if (!/^(https?):\/\//.test(url)) {
        url = `https://${url}`
      }

      try {
        // we allow this new constructor to test if the url is valid, we don't want to store the result
        new URL(url) // eslint-disable-line no-new
      } catch (e) {
        alert('Please enter a valid URL (ex. https://www.teamgather.co)')
        return null
      }
      return url
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';
@import '~@/assets/less/message-content.less';
@import '~@/assets/less/borders.less';

.bubble-menu {
  display: flex;
  padding: 0.2rem;
  background-color: @white;
  border-radius: @standard-border-radius;
}

.character-count {
  margin-top: 1rem;
  color: @error-red;

  &.graph {
    margin-right: 0.5rem;
  }

  &.text {
    color: @blue;
  }
}

.text-editor {
  padding: 0.5rem;
  border: @light-border;
  border-radius: @standard-border-radius;
}

.editor-buttons {
  margin-top: 1rem;
}

.emoji-picker {
  position: absolute;
  z-index: 1; // to give a floating appearance
}
</style>
