import { formatISO, parse, isValid, add } from 'date-fns'
import { format } from 'date-fns-tz'
import { isDate, trimEnd, capitalize } from 'lodash'
import deltaOptions from './deltaOptions'

function parseDuration(text) {
  if (!text) {
    return text
  }

  if (text.split(' ').length !== 2) {
    throw new Error(`Got poorly formatted duration: "${text}"`)
  }

  const magnitude = parseFloat(text.split(' ')[0])
  if (Number.isNaN(magnitude)) {
    throw new Error(`Could not parse magnitude from duration: "${text}"`)
  }

  let unit = text.split(' ')[1].toLowerCase()
  // allowing durations more specific than a day creates inconsistencies
  // w/ timezone logic. times should be handled explicitly as combinations
  // of times and timezones.
  if (
    ['hour', 'minute', 'second', 'millisecond'].includes(trimEnd(unit, 's'))
  ) {
    throw new Error(
      `Got duration more granular than a day. Bad unit was ${unit}`
    )
  }

  if (['day', 'week', 'month', 'year'].includes(unit)) {
    unit = `${unit}s`
  }

  if (!['days', 'weeks', 'months', 'years'].includes(unit)) {
    throw new Error(`Could not parse unit from duration: "${text}"`)
  }

  return { [unit]: magnitude }
}

function _getClientTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

function formatDelta(delta) {
  if (!delta) {
    return delta
  }

  const magnitude = parseInt(delta.split(' ')[0], 10)

  let unit = capitalize(delta.split(' ')[1])
  if (Math.abs(magnitude) === 1) {
    unit = trimEnd(unit, 's')
  }

  if (magnitude === 0) {
    return 'Same Day'
  }

  return magnitude < 0
    ? `${Math.abs(magnitude)} ${unit} Before`
    : `${magnitude} ${unit} After`
}

function formatTime(date, { timeZone } = {}) {
  if (date === null || date === undefined) {
    return date
  }

  if (!isDate(date)) {
    throw new TypeError(`Got "${date}" for date, expected a Date object`)
  }

  return date.toLocaleTimeString(
    [],
    {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: timeZone || _getClientTimezone(),
      timeZoneName: 'short',
    },
    'en-US'
  )
}

function formatDate(date, showYear = false) {
  if (date === null || date === undefined) {
    return date
  }

  if (!isDate(date)) {
    throw new TypeError(`Got "${date}" for date, expected a Date object`)
  }

  return showYear ? format(date, 'MMMM d, yyyy') : format(date, 'MMMM d')
}

function formatDynamicDate(delta, time, timezone, absoluteDate = null) {
  if (absoluteDate !== null) {
    if (!isDate(absoluteDate)) {
      throw new TypeError(
        `Got "${absoluteDate}" for absoluteDate, expected a Date object or null`
      )
    }

    if (!time) {
      return formatDate(add(absoluteDate, parseDuration(delta)))
    }

    absoluteDate.setHours(parseInt(time.split(':')[0], 10))
    absoluteDate.setMinutes(parseInt(time.split(':')[1], 10))

    return `${formatDate(add(absoluteDate, parseDuration(delta)))} at ${format(
      absoluteDate,
      'h:mm a zzz',
      { timeZone: timezone }
    )}`
  }

  // we need a specific date to determine hours and the abbreviated timezone, the current date acts as a natural default
  const baseDate = new Date()

  if (!time) {
    return formatDelta(delta)
  }

  baseDate.setHours(parseInt(time.split(':')[0], 10))
  baseDate.setMinutes(parseInt(time.split(':')[1], 10))

  return `${formatDelta(delta)} at ${format(baseDate, 'h:mm a zzz', {
    timeZone: timezone,
  })}`
}

function formatDatetime(datetime) {
  if (datetime === null || datetime === undefined) {
    return datetime
  }

  if (!isDate(datetime)) {
    throw new TypeError(
      `Got "${datetime}" for datetime, expected a Date object`
    )
  }

  return format(datetime, 'MMMM do, yyyy h:mm a zzz', {
    timezone: _getClientTimezone(),
  })
}

function formatAspectDate(dateString) {
  if (!dateString) {
    return dateString
  }

  let date = parse(dateString, 'yyyy-M-d', new Date())
  if (!isValid(date)) {
    date = parse(dateString, 'M/d/yyyy', new Date())
  }

  if (!isValid(date)) {
    // return the 'Invalid Date' string
    return date
  }

  if (date.getFullYear() < 1900) {
    date.setYear(new Date().getFullYear())
  }

  return format(date, 'M/d/yyyy')
}

function serializeDate(date) {
  return formatISO(date, { representation: 'date' })
}

function serializeTime(date) {
  return format(date, 'H:mm') // capital H ensures this is a 24 hour time!
}

export default {
  computed: {
    deltaOptions() {
      return deltaOptions.map((delta) => ({
        name: formatDelta(delta),
        value: delta,
      }))
    },
  },
  methods: {
    formatTime,
    formatDate,
    formatDelta,
    formatDynamicDate,
    formatDatetime,
    formatAspectDate,
    parseDuration,
    serializeDate,
    serializeTime,
  },
}
