<template>
  <loading v-if="loading" :loading="loading" />
  <grid v-else container>
    <grid-column sixteen right-aligned>
      <btn :loading="creatingChecklistSection" @click.native="createSection">
        + Add Section
      </btn>
    </grid-column>
    <grid-column sixteen>
      <checklist-section
        v-for="checklistSection in checklistSections"
        :key="checklistSection.id"
        :checklist-section="checklistSection"
        :moment="moment"
        :organization="organization"
        :users="users"
        :relationships="relationships"
        :aspects="aspects"
        @get-checklist-sections="getChecklistSections"
        @get-actions="() => $emit('get-actions')"
      />
    </grid-column>
  </grid>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import loading from '@/components/v2/loading.vue'
import btn from '@/components/v2/btn.vue'

import checklistSection from '@/views/v2/moment/checklist_section.vue'
import { momentColors } from '@/assets/js/colors'

export default {
  components: {
    grid,
    gridColumn,
    loading,
    checklistSection,
    btn,
  },
  props: {
    moment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      checklistSectionsLoading: false,
      creatingChecklistSection: false,
      organizationLoading: false,
      checklistSections: [],
      organization: null,
      users: [],
      relationships: [],
      aspects: [],
    }
  },
  computed: {
    loading() {
      return this.checklistSectionsLoading && this.organizationLoading
    },
  },
  mounted() {
    this.getUsers()
    this.getRelationships()
    this.getAspects()
    this.getPage()
  },
  methods: {
    async getPage() {
      await this.getOrganization()
      await this.getChecklistSections()
    },
    async getUsers() {
      let res = { cursor: { nextPage: 1 } }
      while (res.cursor && res.cursor.nextPage) {
        try {
          // eslint-disable-next-line no-await-in-loop
          res = await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/users`, {
            page: res.cursor.nextPage,
            status: ['active', 'onboarding'],
            sort: 'preferredFirstName,lastName,id',
          })
          this.users = this.users.concat(res.data)
        } catch (error) {
          toast.error(error)
          break
        }
      }
    },
    async getRelationships() {
      let res = { cursor: { nextPage: 1 } }
      while (res.cursor && res.cursor.nextPage) {
        try {
          // eslint-disable-next-line no-await-in-loop
          res = await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/relationships`,
            { page: res.cursor.nextPage, sort: 'name,id' }
          )
          this.relationships = this.relationships.concat(res.data)
        } catch (error) {
          toast.error(error)
          break
        }
      }
    },
    async getAspects() {
      let res = { cursor: { nextPage: 1 } }
      while (res.cursor && res.cursor.nextPage) {
        try {
          // eslint-disable-next-line no-await-in-loop
          res = await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/aspects`, {
            page: res.cursor.nextPage,
            isEditable: 1,
          })
          this.aspects = this.aspects.concat(res.data)
        } catch (error) {
          toast.error(error)
          break
        }
      }
    },
    async getChecklistSections() {
      try {
        this.checklistSectionsLoading = true
        this.checklistSections = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${this.moment.id}/checklist-sections`,
            {
              sort: 'id-desc',
            }
          )
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.checklistSectionsLoading = false
      }
    },
    async getOrganization() {
      try {
        this.organizationLoading = true
        this.organization = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/organizations/${this.$store.state.organizationId}`
          )
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.organizationLoading = false
      }
    },
    async createSection() {
      try {
        this.creatingChecklistSection = true
        await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${this.moment.id}/checklist-sections`,
          {
            name: 'New Section',
            color: momentColors[0],
          }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.creatingChecklistSection = false
      }

      await this.getChecklistSections()
    },
  },
}
</script>
