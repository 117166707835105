<template>
  <loading v-if="pageLoading || patchingTheme" :loading="true" />
  <container v-else>
    <page-header>
      <grid>
        <grid-column v-if="moment" eleven>
          <flex direction="column" gap="0.5rem">
            <flex gap="0.5rem" align="center">
              <moment-icon
                v-tippy
                content="Edit Icon"
                :icon="moment.icon"
                :hex-color="moment.color"
                class="pointer"
                size="big"
                @click.native="$refs.themeModal.show()"
              />
              <h1 class="moment-header">
                <editable-text
                  :value="moment.name"
                  :loading="patchingMomentField === 'name'"
                  placeholder="Add Name"
                  @update="(value) => patchMoment('name', value)"
                />
              </h1>
              <g-label v-if="moment && moment.isActive" green>Active</g-label>
              <g-label v-if="moment && !moment.isActive">Draft</g-label>
            </flex>
            <editable-text
              :value="moment.description"
              :loading="patchingMomentField === 'description'"
              placeholder="Add Description"
              @update="(value) => patchMoment('description', value)"
            />
          </flex>
        </grid-column>
        <grid-column five right-aligned>
          <div class="header-actions">
            <activation
              class="activation"
              :moment-status="momentStatus || {}"
              :moment="moment || {}"
              :active="moment && moment.isActive"
              :loading="patchingMomentField === 'isActive'"
              :scheduling="scheduling"
              :scheduled="scheduled"
              tooltip="Activate Moment"
              :disabled="isMomentDisabled"
              @deactivate="deactivateMoment"
              @activate="activateMoment"
            />
            <btn
              icon="fas fa-copy"
              class="btn"
              variant="secondary"
              tooltip="Copy Moment"
              :loading="duplicatingMoment"
              @click.native="duplicateMoment"
            />
            <btn
              icon="fas fa-trash"
              class="btn"
              variant="secondary"
              tooltip="Delete Moment"
              :loading="archivingMoment"
              @click.native="$refs.confirm.show()"
            />
          </div>
        </grid-column>
      </grid>
    </page-header>
    <tab-list pointing class="tabs">
      <tab fragment="workflow" :active="$route.hash === '#workflow'">
        Workflow
      </tab>
      <tab fragment="checklist" :active="$route.hash === '#checklist'">
        Checklist
      </tab>
      <tab fragment="timeline" :active="$route.hash === '#timeline'">
        Timeline
      </tab>
    </tab-list>
    <workflow
      v-if="moment"
      v-show="$route.hash === '#workflow'"
      :actions="actions"
      :moment="moment"
      @get-actions="getActions"
      @get-moment="getMoment"
      @get-moment-status="getMomentStatus"
    />
    <checklist
      v-if="moment"
      v-show="$route.hash === '#checklist'"
      :moment="moment"
      :organization="organization"
      @get-actions="getActions"
      @get-moment="getMoment"
      @get-moment-status="getMomentStatus"
    />
    <timeline
      v-if="moment"
      v-show="$route.hash === '#timeline'"
      :moment="moment"
      :actions="actions"
      @get-actions="getActions"
      @get-moment="getMoment"
      @get-moment-status="getMomentStatus"
    />

    <confirm-modal
      ref="confirm"
      negative
      approve-text="Delete Moment"
      deny-text="Cancel"
      @approve="archiveMoment"
    >
      Are you sure you want to delete the moment {{ moment && moment.name }}?
    </confirm-modal>
    <modal ref="themeModal" size="tiny" @deny="() => (theme = {})">
      <modal-header> Edit Icon </modal-header>
      <modal-content>
        <g-form id="edit-moment-form" @submit="patchIconTheme">
          <template #default="{ valid }">
            <form-validation
              :valid="valid"
              @update="(value) => (isFormValid = value)"
            />
            <form-field-group equal-width>
              <form-field>
                <form-label>Color</form-label>
                <form-dropdown
                  name="Color"
                  required
                  :initial-value="moment && moment.color"
                  default-text="Select Color"
                  @update="(value) => (theme.color = value)"
                >
                  <dropdown-item
                    v-for="color in momentColors"
                    :key="color"
                    :value="color"
                  >
                    <i
                      class="fas fa-square form-dropdown-icon"
                      :style="{ color: color }"
                    />
                  </dropdown-item>
                </form-dropdown>
              </form-field>
              <form-field>
                <form-label>Icon</form-label>
                <form-dropdown
                  name="Icon"
                  required
                  :initial-value="moment && moment.icon"
                  default-text="Select Icon"
                  @update="(value) => (theme.icon = value)"
                >
                  <dropdown-item
                    v-for="icon in momentIcons"
                    :key="icon"
                    :value="icon"
                  >
                    <i :class="[icon, 'form-dropdown-icon']" />
                  </dropdown-item>
                </form-dropdown>
              </form-field>
            </form-field-group>
          </template>
        </g-form>
      </modal-content>
      <modal-actions space-between>
        <form-btn variant="default" type="reset" form-id="edit-moment-form">
          Cancel
        </form-btn>

        <form-btn
          variant="primary"
          type="submit"
          :invalid="!isFormValid"
          form-id="edit-moment-form"
        >
          Save
        </form-btn>
      </modal-actions>
    </modal>
  </container>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import loading from '@/components/v2/loading.vue'
import container from '@/components/v2/container.vue'
import pageHeader from '@/components/v2/page_header.vue'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import btn from '@/components/v2/btn.vue'
import editableText from '@/components/v2/editable_text.vue'
import gLabel from '@/components/v2/label.vue'
import confirmModal from '@/components/v2/confirm_modal.vue'
import modal from '@/components/v2/modal/modal.vue'
import modalHeader from '@/components/v2/modal/header.vue'
import modalActions from '@/components/v2/modal/actions.vue'
import modalContent from '@/components/v2/modal/content.vue'
import gForm from '@/components/v2/form/form.vue'
import formField from '@/components/v2/form/field.vue'
import formFieldGroup from '@/components/v2/form/field_group.vue'
import formBtn from '@/components/v2/form/btn.vue'
import formLabel from '@/components/v2/form/label.vue'
import formValidation from '@/components/v2/form/validation.vue'
import formDropdown from '@/components/v2/form/dropdown.vue'
import dropdownItem from '@/components/v2/dropdown/item.vue'
import tabList from '@/components/v2/tabs/tab_list.vue'
import tab from '@/components/v2/tabs/tab.vue'
import momentIcon from '@/components/v2/moment_icon.vue'
import flex from '@/components/v2/flex.vue'

import workflow from '@/views/v2/moment/workflow.vue'
import checklist from '@/views/v2/moment/checklist.vue'
import timeline from '@/views/v2/moment/timeline.vue'

import { momentColors } from '@/assets/js/colors'
import { momentIcons } from '@/assets/js/icons'

import activation from './activation.vue'

export default {
  components: {
    gLabel,
    loading,
    container,
    pageHeader,
    grid,
    gridColumn,
    btn,
    activation,
    editableText,
    confirmModal,
    modal,
    modalActions,
    modalContent,
    modalHeader,
    gForm,
    formField,
    formFieldGroup,
    formBtn,
    formValidation,
    formLabel,
    formDropdown,
    dropdownItem,
    tabList,
    tab,
    momentIcon,
    flex,
    workflow,
    checklist,
    timeline,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      momentColors,
      momentIcons,
      organization: null,
      moment: null,
      pageLoading: false,
      patchingMomentField: null,
      patchingTheme: false,
      isFormValid: false,
      duplicatingMoment: false,
      archivingMoment: false,
      scheduled: false,
      scheduling: false,
      theme: {},
      actions: [],
      momentStatus: null,
    }
  },
  computed: {
    momentDisabledWarning() {
      if (this.moment && this.moment.type === 'aspect') {
        if (!this.moment.aspectId) {
          return "This moment can't be activated until a custom field is chosen"
        }
      }

      if (this.moment && this.moment.type === 'date') {
        if (!this.moment.triggerDate) {
          return "This moment can't be activated until a date is chosen"
        }
      }

      if (this.momentStatus && this.momentStatus.error) {
        return this.momentStatus.error.message
      }

      return null
    },
    isMomentDisabled() {
      return !!(
        this.momentDisabledWarning ||
        !(this.momentStatus && this.momentStatus.ok)
      )
    },
  },
  created() {
    if (!this.$route.hash) {
      this.$router.replace({ hash: '#workflow' })
    }
  },
  mounted() {
    this.getPage()
  },
  methods: {
    async getPage() {
      try {
        this.pageLoading = true
        this.organization = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/organizations/${this.$store.state.organizationId}`
          )
        ).data
        await this.getMoment()
        await this.getActions()
      } catch (error) {
        toast.error(error)
      } finally {
        this.pageLoading = false
      }
    },
    async getMoment() {
      try {
        this.moment = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${this.id}`
          )
        ).data
      } catch (error) {
        toast.error(error)
      }
      await this.getMomentStatus()
    },
    async getMomentStatus() {
      try {
        this.momentStatus = (
          await api.post(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/rpcs/moment/status`,
            {
              momentId: parseInt(this.id, 10),
            }
          )
        ).data
      } catch (error) {
        toast.error(error)
      }
    },
    async getActions(options = {}) {
      try {
        this.actions = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/actions`, {
            momentId: this.moment.id,
            sort: 'order,id',
          })
        ).data
      } catch (error) {
        toast.error(error)
      }
      this.scrollToAction(options.goToAction)
    },
    scrollToAction(actionId) {
      if (actionId) {
        this.$nextTick(() => {
          const actionElement = document.getElementById(`action-${actionId}`)
          if (actionElement) {
            window.scrollTo({
              top:
                actionElement.getBoundingClientRect().top +
                window.pageYOffset -
                130, // magic number that offsets the action header consistently
              behavior: 'smooth',
            })
          }
        })
      }
    },
    async patchMoment(field, value) {
      try {
        this.patchingMomentField = field
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${this.id}`,
          { [field]: value }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingMomentField = null
      }
      await this.getMoment()
    },
    async deactivateMoment() {
      try {
        this.patchingMomentField = 'isActive'
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${this.id}`,
          { isActive: false }
        )
        this.scheduled = false
        toast.info('Workflow stopped')
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingMomentField = null
        await this.getMoment()
      }
    },
    async activateMoment() {
      let activationFailed = false
      try {
        this.patchingMomentField = 'isActive'
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${this.id}`,
          { isActive: true }
        )
      } catch (error) {
        toast.error(error)
        activationFailed = true
      } finally {
        this.patchingMomentField = null
      }

      if (!activationFailed) {
        await Promise.all([this.getMoment(), this.scheduleActions()])
      } else {
        await this.getMoment()
      }
    },
    async scheduleActions() {
      try {
        this.scheduling = true
        await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/rpcs/scheduler/schedule`,
          { momentId: this.moment.id }
        )
        this.scheduled = true
      } catch (error) {
        toast.error(error)
      } finally {
        this.scheduling = false
      }
    },
    async patchIconTheme(submission) {
      try {
        this.patchingTheme = true
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${this.id}`,
          {
            color: submission.get('Color'),
            icon: submission.get('Icon'),
          }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingTheme = false
      }
      await this.getMoment()
    },
    async duplicateMoment() {
      this.momentStatus = null
      try {
        this.duplicatingMoment = true
        const response = await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments`,
          {
            momentId: parseInt(this.id, 10),
          }
        )
        this.$router.push({
          name: 'moment',
          params: { id: response.data.id },
          hash: '#workflow',
        })
      } catch (error) {
        toast.error(error)
      } finally {
        this.duplicatingMoment = false
      }
      await this.getPage()
    },
    async archiveMoment() {
      try {
        this.archivingMoment = true
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${this.id}`,
          { isArchived: true }
        )
        this.$router.push({ name: 'moments' })
      } catch (error) {
        toast.error(error)
      } finally {
        this.archivingMoment = false
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/borders.less';
@import '~@/assets/less/text.less';
@import '~@/assets/less/colors.less';

.moment-header {
  display: inline-flex;
  margin: 0;
}

.form-dropdown-icon {
  font-size: @large-icon-font-size;
  line-height: 1;
}

.activation {
  vertical-align: middle;
}

.header-actions {
  margin-top: 1em;
}

.tabs {
  margin-bottom: 1em !important;
}

.moment-disabled-warning {
  margin-right: 0.5rem;
  vertical-align: middle;
}
</style>
