<template>
  <card :class="['action-card', { 'action-loader': deletingAction }]">
    <loading v-if="deletingAction" loading size="huge" />
    <card-body v-else>
      <action-header
        :action="action"
        :relationships="relationships"
        :triggers="triggers"
        :moment="moment"
        :users="users"
        :organization="organization"
        @get-actions="(value) => $emit('get-actions', value)"
        @delete-action="showDeleteModal"
        @get-moment-status="() => $emit('get-moment-status')"
      />
      <message
        v-if="action.type === 'message'"
        :loading="dataLoading"
        :organization="organization"
        :action="action"
        :recipients="recipients"
        :users="users"
        :groups="groups"
        :relationships="relationships"
        :slack-conversations="slackConversations"
        :slack-integration="slackIntegration"
        :aspects="aspects"
        @get-actions="() => $emit('get-actions')"
        @get-recipients="getRecipients"
        @get-moment-status="() => $emit('get-moment-status')"
      />
      <create-channel
        v-if="action.type === 'channel'"
        :loading="dataLoading"
        icon="fas fa-hashtag"
        :organization="organization"
        :action="action"
        :recipients="recipients"
        :users="users"
        :groups="groups"
        :relationships="relationships"
        :slack-conversations="slackConversations"
        :slack-integration="slackIntegration"
        :aspects="aspects"
        @get-actions="() => $emit('get-actions')"
        @get-recipients="getRecipients"
        @get-moment-status="() => $emit('get-moment-status')"
      />
      <assign-relationship
        v-if="action.type === 'relationship'"
        :loading="dataLoading"
        :organization="organization"
        :action="action"
        :recipients="recipients"
        :relationships="relationships"
        :users="users"
        :groups="groups"
        :slack-conversations="slackConversations"
        :aspects="aspects"
        @get-actions="() => $emit('get-actions')"
        @get-recipients="getRecipients"
        @get-moment-status="() => $emit('get-moment-status')"
      />
      <tasks
        v-if="action.type === 'task'"
        :loading="dataLoading"
        :organization="organization"
        :action="action"
        :recipients="recipients"
        :tasks="actionTasks"
        :aspects="aspects"
        @get-actions="() => $emit('get-actions')"
        @get-moment-status="() => $emit('get-moment-status')"
      />
      <email
        v-if="action.type === 'email'"
        :loading="dataLoading"
        :action="action"
        :recipients="recipients"
        :users="users"
        :groups="groups"
        :relationships="relationships"
        :aspects="aspects"
        :carbon-copies="carbonCopies"
        @get-recipients="getRecipients"
        @get-carbon-copies="getCarbonCopies"
        @get-actions="() => $emit('get-actions')"
        @get-moment-status="() => $emit('get-moment-status')"
      />
      <meeting
        v-if="action.type === 'meeting'"
        :loading="dataLoading"
        :action="action"
        :aspects="aspects"
        :recipients="recipients"
        :users="users"
        :groups="groups"
        :relationships="relationships"
        :organization="organization"
        @get-actions="() => $emit('get-actions')"
        @get-recipients="getRecipients"
      />
    </card-body>
    <confirm-modal
      ref="delete"
      negative
      approve-text="Delete Step"
      deny-text="Cancel"
      @approve="deleteAction"
    >
      Are you sure you want to delete this step?
    </confirm-modal>
  </card>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import card from '@/components/v2/card/card.vue'
import cardBody from '@/components/v2/card/body.vue'
import message from '@/components/v2/action_card/message.vue'
import actionHeader from '@/components/v2/action_card/header.vue'
import createChannel from '@/components/v2/action_card/create_channel.vue'
import assignRelationship from '@/components/v2/action_card/assign_relationship.vue'
import tasks from '@/components/v2/action_card/tasks.vue'
import email from '@/components/v2/action_card/email.vue'
import meeting from '@/components/v2/action_card/meeting.vue'
import confirmModal from '@/components/v2/confirm_modal.vue'
import loading from '@/components/v2/loading.vue'

export default {
  components: {
    card,
    cardBody,
    message,
    actionHeader,
    createChannel,
    assignRelationship,
    tasks,
    email,
    meeting,
    confirmModal,
    loading,
  },
  props: {
    moment: {
      type: Object,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
    slackIntegration: {
      type: Object,
      default: null,
    },
    relationships: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
    slackConversations: {
      type: Array,
      required: true,
    },
    triggers: {
      type: Array,
      required: true,
    },
    aspects: {
      type: Array,
      required: true,
    },
    organization: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      recipients: [],
      carbonCopies: [],
      actionTasks: [],
      dataLoading: false,
      deletingAction: false,
      deleteActionId: null,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        this.dataLoading = true
        await this.getRecipients()
        if (this.action.type === 'task') {
          await this.getTasksForAction()
        }
        if (this.action.type === 'email') {
          await this.getCarbonCopies()
        }
      } catch (error) {
        toast.error(error)
      } finally {
        this.dataLoading = false
      }
    },
    async getRecipients() {
      try {
        this.recipients = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${this.action.id}/recipients`,
            {
              userStatus: ['active', 'onboarding'],
              order: 'createdAt-desc',
            }
          )
        ).data
      } catch (error) {
        toast.error(error)
      }
    },
    async getCarbonCopies() {
      try {
        this.carbonCopies = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${this.action.id}/carbon-copies`,
            {
              userStatus: ['active', 'onboarding'],
              order: 'createdAt',
            }
          )
        ).data
      } catch (error) {
        toast.error(error)
      }
      this.$emit('get-moment-status')
    },
    async getTasksForAction() {
      try {
        this.actionTasks = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/tasks`, {
            actionId: this.action.id,
          })
        ).data
      } catch (error) {
        toast.error(error)
      }
    },
    async deleteAction() {
      try {
        this.deletingAction = true
        await api.delete(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${this.deleteActionId}`
        )
      } catch (error) {
        toast.error(error)
        this.deletingAction = false
      } finally {
        this.deleteActionId = null
      }
      this.$emit('get-actions')
    },
    showDeleteModal(id) {
      this.deleteActionId = id
      this.$refs.delete.show()
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/text.less';
@import '~@/assets/less/borders.less';

.action-card {
  width: 75%;
  min-width: 20vw;
}

.action-loader {
  min-height: 20vh;
}
</style>
