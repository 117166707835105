export default [
  '-4 weeks',
  '-3 weeks',
  '-2 weeks',
  '-13 days',
  '-12 days',
  '-11 days',
  '-10 days',
  '-9 days',
  '-8 days',
  '-7 days',
  '-6 days',
  '-5 days',
  '-4 days',
  '-3 days',
  '-2 days',
  '-1 days',
  '0 days',
  '1 days',
  '2 days',
  '3 days',
  '4 days',
  '5 days',
  '6 days',
  '7 days',
  '8 days',
  '9 days',
  '10 days',
  '11 days',
  '12 days',
  '13 days',
  '2 weeks',
  '3 weeks',
  '4 weeks',
  '30 days',
  '60 days',
  '90 days',
]
