<template>
  <list-content>
    <flex align="center" gap="0.25rem">
      {{ recipient.name }}
      <btn
        v-if="!disabled"
        dismiss
        tooltip="Remove"
        size="tiny"
        compact
        :loading="deletingRecipient"
        @click.native.prevent="deleteRecipient"
      />
    </flex>
  </list-content>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import listContent from '@/components/v2/list/content.vue'
import flex from '@/components/v2/flex.vue'
import btn from '@/components/v2/btn.vue'

export default {
  components: {
    listContent,
    flex,
    btn,
  },
  props: {
    action: {
      type: Object,
      required: true,
    },
    recipient: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deletingRecipient: false,
    }
  },
  methods: {
    async deleteRecipient() {
      try {
        this.deletingRecipient = true
        await api.delete(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${this.action.id}/recipients/${this.recipient.id}`
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.deletingRecipient = false
      }

      this.$emit('get-recipients')
      this.$emit('get-moment-status')
    },
  },
}
</script>
