<template>
  <card-description>
    <flex direction="column" gap="0.5rem">
      <recipient-group
        title="Recipient (Assigner)"
        :recipients="recipients"
        :users="users"
        :groups="groups"
        :relationships="relationships"
        :slack-conversations="slackConversations"
        :action="action"
        hide-channels
        hide-groups
        allow-one
        :loading="loading"
        @get-recipients="() => $emit('get-recipients')"
        @get-moment-status="() => $emit('get-moment-status')"
        @patch-action="({ field, value }) => patchAction(field, value)"
      />
      <flex justify="space-between" wrap="wrap" gap="0.5rem">
        <flex direction="column" gap="0.5rem">
          <strong>Relationship</strong>
          <dropdown
            name="Relationship"
            simple
            placeholder="Select Relationship"
            :value="action.relationshipId"
            reduced-field="id"
            :loading="patchingRelationshipForAction"
            :options="relationships"
            label="name"
            searchable
            @update="patchRelationshipForAction"
          />
        </flex>
        <flex direction="column" gap="0.5rem">
          <strong>
            Group To Select From
            <i
              v-tippy
              class="fas fa-info-circle"
              :content="`The ${
                recipients.length
                  ? recipients[0].name
                  : 'recipient of this step'
              } will assign the
        ${getRelationshipSuperiorTitle(action.relationshipId)} for the
        ${getRelationshipSubordinateTitle(action.relationshipId)}`"
            />
          </strong>
          <dropdown
            name="Assignee"
            placeholder="Select Group"
            searchable
            simple
            :value="action.assignee && action.assignee.groupId"
            :loading="patchingField === 'assigneeGroupId'"
            :options="groups"
            label="name"
            reduced-field="id"
            @update="(value) => patchAction('assigneeGroupId', value)"
          />
        </flex>
      </flex>
      <flex direction="column">
        <btn
          size="small"
          compact
          basic
          class="preview-button"
          variant="default"
          @click.native="() => (showPreview = !showPreview)"
        >
          {{ showPreview ? 'Edit' : 'Close Text Editor' }}
        </btn>
        <slack-message-preview
          v-show="showPreview"
          class="message-preview pointer"
          :action="action"
          :organization="organization"
          @click.native="() => (showPreview = false)"
        />
        <text-editor
          v-show="!showPreview"
          :value="action.body"
          :aspects="aspects"
          :loading="patchingField === 'body'"
          @input="(value) => patchAction('body', value)"
        />
      </flex>
    </flex>
  </card-description>
</template>

<script>
import { find } from 'lodash'
import { api } from '@/api'
import { toast } from '@/toasts'

import cardDescription from '@/components/v2/card/description.vue'
import dropdown from '@/components/v3/dropdown.vue'
import btn from '@/components/v2/btn.vue'
import recipientGroup from '@/components/v2/action_card/recipient_group.vue'
import slackMessagePreview from '@/components/v2/slack_message_preview.vue'
import flex from '@/components/v2/flex.vue'
import textEditor from '@/components/v2/text_editor.vue'

export default {
  components: {
    cardDescription,
    dropdown,
    recipientGroup,
    textEditor,
    btn,
    slackMessagePreview,
    flex,
  },
  props: {
    action: {
      type: Object,
      required: true,
    },
    recipients: {
      type: Array,
      required: true,
    },
    relationships: {
      type: Array,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    slackConversations: {
      type: Array,
      required: true,
    },
    aspects: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    organization: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      token: {},
      creatingRecipient: false,
      patchingField: null,
      showPreview: true,
      patchingRelationshipForAction: false,
    }
  },
  methods: {
    getRelationshipSuperiorTitle(id) {
      const relationship = find(this.relationships, { id }) || {}
      return relationship.superiorTitle || relationship.name || 'Relationship'
    },
    getRelationshipSubordinateTitle(id) {
      return (
        (find(this.relationships, { id }) || {}).subordinateTitle || 'Enrollee'
      )
    },
    async patchRelationshipForAction(relationshipId) {
      try {
        this.patchingRelationshipForAction = true
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${this.action.id}`,
          {
            buttonName: `👫 Assign ${this.getRelationshipSuperiorTitle(
              relationshipId
            )}`,
            relationshipId,
          }
        )
        this.$emit('get-moment-status')
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingRelationshipForAction = false
      }

      this.$emit('get-actions')
    },
    async patchAction(field, value) {
      try {
        this.patchingField = field
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${this.action.id}`,
          { [field]: value }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingField = null
      }
      this.$emit('get-actions')
      if (field === 'assigneeGroupId') {
        this.$emit('get-moment-status')
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/message-content.less';

.message-preview {
  width: 100%;
}

.preview-button {
  display: block;
  margin: 0 0 0.5rem auto;
}
</style>
