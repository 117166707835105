<template>
  <table-row>
    <table-cell>{{ task.name }}</table-cell>
    <table-cell :class="{ 'warning-bg': taskOwnerError }">
      <g-image
        avatar
        :src="taskOwnerImage"
        alt="Task owner's profile picture"
      />
      {{ taskOwnerLabel }}
    </table-cell>
    <table-cell>
      {{
        formatDynamicDate(
          task.sendDate,
          task.sendTime,
          organization.timezone,
          null
        ) || 'Not added to workflow'
      }}
    </table-cell>
    <table-cell>
      {{ formatDeadline(task.deadline) || 'Optional' }}
    </table-cell>
    <table-cell class="no-left-padding">
      <div class="task-controls">
        <btn
          icon="fas fa-edit"
          tooltip="Edit Task"
          :loading="patchingTask"
          variant="secondary"
          compact
          @click.native="$refs.newTask.show"
        />
        <btn
          icon="fas fa-copy"
          tooltip="Duplicate Task"
          :loading="duplicatingTask"
          variant="secondary"
          compact
          @click.native="duplicateTask"
        />
        <btn
          icon="fas fa-times"
          tooltip="Delete Task"
          :loading="deletingTask"
          variant="secondary"
          compact
          @click.native="$refs.delete.show"
        />
      </div>
    </table-cell>
    <confirm-modal
      ref="delete"
      negative
      approve-text="Delete Task"
      deny-text="Cancel"
      @approve="deleteTask"
    >
      Are you sure you want to delete task "{{ task.name }}"?
    </confirm-modal>
    <modal ref="newTask">
      <modal-header> Edit Task </modal-header>
      <modal-content>
        <g-form :id="`${task.id}-edit-task`" @submit="patchTask">
          <template #default="{ valid }">
            <form-validation
              :valid="valid"
              @update="(value) => (isTaskFormValid = value)"
            />
            <form-field>
              <form-label>Name</form-label>
              <form-input
                name="Name"
                :rules="['required', 'max:255']"
                :initial-value="task.name"
                placeholder="Name"
              />
            </form-field>
            <form-field>
              <form-label>Description</form-label>
              <text-editor
                :value="task.description"
                name="Description"
                :aspects="aspects"
              />
            </form-field>
            <form-field>
              <form-label>Owner</form-label>
              <form-dropdown
                name="Owner"
                required
                searchable
                groupable
                append-to-body
                :options="ownerOptions"
                label="name"
                :value="ownerValue"
                reduced-field="uid"
                placeholder="Select Owner"
              />
            </form-field>
            <form-field-group equal-width>
              <form-field>
                <form-label>Send Date</form-label>
                <delta-picker
                  append-to-body
                  placement="top"
                  form
                  name="Send Date"
                  :delta="task.sendDate"
                />
              </form-field>
              <form-field>
                <form-label>Send Time</form-label>
                <time-picker
                  name="Send Time"
                  :time="task.sendTime"
                  class="send-time-picker"
                />
              </form-field>
              <form-field>
                <form-label
                  >Deadline
                  <i
                    v-tippy
                    class="fas fa-info-circle"
                    content="Deadline is relative to day task is assigned"
                  />
                </form-label>
                <delta-picker
                  name="Deadline"
                  :delta="task.deadline"
                  :start="{ days: 0 }"
                  nullable
                  form
                  append-to-body
                  placement="top"
                  null-label="No deadline"
                />
              </form-field>
            </form-field-group>
          </template>
        </g-form>
      </modal-content>
      <modal-actions space-between>
        <form-btn
          :form-id="`${task.id}-edit-task`"
          variant="default"
          type="reset"
        >
          Cancel
        </form-btn>

        <form-btn
          :loading="patchingTask"
          variant="primary"
          type="submit"
          :invalid="!isTaskFormValid"
          :form-id="`${task.id}-edit-task`"
        >
          Save Task
        </form-btn>
      </modal-actions>
    </modal>
  </table-row>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import confirmModal from '@/components/v2/confirm_modal.vue'
import btn from '@/components/v2/btn.vue'
import tableRow from '@/components/v2/table/row.vue'
import tableCell from '@/components/v2/table/cell.vue'
import modal from '@/components/v2/modal/modal.vue'
import modalHeader from '@/components/v2/modal/header.vue'
import modalActions from '@/components/v2/modal/actions.vue'
import modalContent from '@/components/v2/modal/content.vue'
import gForm from '@/components/v2/form/form.vue'
import gImage from '@/components/v2/image.vue'
import formField from '@/components/v2/form/field.vue'
import formFieldGroup from '@/components/v2/form/field_group.vue'
import formBtn from '@/components/v2/form/btn.vue'
import formLabel from '@/components/v2/form/label.vue'
import formValidation from '@/components/v2/form/validation.vue'
import formInput from '@/components/v2/form/input.vue'
import formDropdown from '@/components/v3/form_dropdown.vue'
import deltaPicker from '@/components/v2/delta_picker.vue'
import timePicker from '@/components/v2/time_picker.vue'
import textEditor from '@/components/v2/text_editor.vue'

import defaultProfileImage from '@/assets/img/profile_avatar_small.png'
import defaultEnrolleeImage from '@/assets/img/default_enrollee_image_small.png'
import defaultRelationshipImage from '@/assets/img/default_relationship_image_small.png'

import dateMixin from '@/mixins/v2/dateMixin'

export default {
  components: {
    confirmModal,
    btn,
    tableRow,
    tableCell,
    modal,
    modalHeader,
    modalActions,
    modalContent,
    gForm,
    gImage,
    formFieldGroup,
    formField,
    formBtn,
    formLabel,
    formValidation,
    formInput,
    formDropdown,
    deltaPicker,
    timePicker,
    textEditor,
  },
  mixins: [dateMixin],
  props: {
    task: {
      type: Object,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
    aspects: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      default: () => [],
    },
    relationships: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      defaultProfileImage,
      defaultEnrolleeImage,
      defaultRelationshipImage,
      deletingTask: false,
      patchingTask: false,
      duplicatingTask: false,
      isTaskFormValid: false,
      token: {},
    }
  },
  computed: {
    ownerOptions() {
      let options = []

      options = options.concat([
        { group: 'Enrollee', name: null },
        {
          name: 'Enrollee',
          uid: JSON.stringify({
            ownerType: 'enrollee',
            ownerRelationshipId: null,
            ownerUserId: null,
          }),
        },
      ])

      const ownerRelationships = this.relationships.map((relationship) => ({
        ...relationship,
        ...{
          uid: JSON.stringify({
            ownerType: 'relationship',
            ownerRelationshipId: relationship.id,
            ownerUserId: null,
          }),
        },
      }))
      options = options.concat(
        [{ group: 'Relationships', name: null }].concat(ownerRelationships)
      )

      const ownerUsers = this.users.map((user) => ({
        ...user,
        ...{
          uid: JSON.stringify({
            ownerType: 'user',
            ownerRelationshipId: null,
            ownerUserId: user.id,
          }),
          name: user.fullName,
        },
      }))

      options = options.concat(
        [{ group: 'People', name: null }].concat(ownerUsers)
      )

      // we give every option an ID for vue-select to prevent duplicate keys
      options = options.map((option) => {
        option.id = JSON.stringify(option)
        return option
      })
      return options
    },
    ownerValue() {
      return JSON.stringify({
        ownerType: this.task.ownerType,
        ownerRelationshipId: this.task.ownerRelationshipId,
        ownerUserId: this.task.ownerUserId,
      })
    },
    taskOwnerImage() {
      if (this.task.ownerType === 'user') {
        const user = this.users.find(
          (user) => user.id === this.task.ownerUserId
        )
        return (user || {}).avatar || this.defaultProfileImage
      }

      if (this.task.ownerType === 'relationship') {
        return this.defaultRelationshipImage
      }

      if (this.task.ownerType === 'enrollee') {
        return this.defaultEnrolleeImage
      }

      return null
    },
    taskOwnerError() {
      return this.taskOwnerLabel.includes('Removed')
    },
    taskOwnerLabel() {
      if (this.task.ownerType === 'user') {
        const user = this.users.find(({ id }) => id === this.task.ownerUserId)
        if (!user) {
          return `Removed Person - ${this.task.ownerUserId}`
        }

        return user.fullName
      }
      if (this.task.ownerType === 'relationship') {
        const relationship = this.relationships.find(
          ({ id }) => id === this.task.ownerRelationshipId
        )

        if (!relationship) {
          return `Removed Relationship - ${this.task.ownerRelationshipId}`
        }
        return relationship.name
      }
      if (this.task.ownerType === 'enrollee') {
        return 'Enrollee'
      }
      return ''
    },
  },
  methods: {
    async deleteTask() {
      try {
        this.deletingTask = true
        await api.delete(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/tasks/${this.task.id}`
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.deletingTask = false
      }
      this.$emit('get-tasks')
      this.$emit('get-actions')
    },
    async duplicateTask() {
      try {
        this.duplicatingTask = true
        await api.post(`${process.env.VUE_APP_DB_ENDPOINT}/v2/tasks`, {
          taskId: this.task.id,
        })
      } catch (error) {
        toast.error(error)
      } finally {
        this.duplicatingTask = false
      }
      this.$emit('get-tasks')
      this.$emit('get-actions')
    },
    async patchTask(submission) {
      try {
        const owner = JSON.parse(submission.get('Owner'))
        this.patchingTask = true
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/tasks/${this.task.id}`,
          {
            name: submission.get('Name'),
            description: submission.get('Description'),
            deadline: JSON.parse(submission.get('Deadline')),
            ownerType: owner.ownerType,
            ownerUserId: owner.ownerUserId,
            ownerRelationshipId: owner.ownerRelationshipId,
            isRequired: !!JSON.parse(submission.get('Deadline')),
            sendDate: JSON.parse(submission.get('Send Date')),
            sendTime:
              submission.get('Send Time') &&
              this.serializeTimeFromString(submission.get('Send Time')),
          }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingTask = false
      }
      this.$emit('get-tasks')
      this.$emit('get-actions')
    },
    formatDeadline(deadline) {
      if (!deadline) {
        return deadline
      }
      return this.formatDelta(deadline) === 'Same Day'
        ? 'Same Day'
        : `${this.formatDelta(deadline)} assigned`
    },
    showNewTaskModal() {
      this.$refs.newTask.show()
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';

.task-controls {
  float: right;
}

.dropdown-section-header {
  padding: 0.5em;
  font-weight: bold;
  background-color: @light-grey-background;
}

.warning-bg {
  background-color: @warning-yellow-background !important;
}

.no-left-padding {
  padding-left: 0 !important;
}
</style>
