// ========================================== // Author: @bsime-gather //
Purpose: Child Checkbox (semantic) // ==========================================
<template>
  <div class="ui child checkbox">
    <input v-model="isChecked" type="checkbox" :name="name" />
    <label><slot /></label>
  </div>
</template>

<script>
export default {
  props: {
    initialValue: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'child-checkbox',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isChecked: this.initialValue,
    }
  },
  watch: {
    isChecked(newValue) {
      this.$emit('input', newValue)
    },
  },
  mounted() {
    this.setInitialValue()
  },
  methods: {
    setInitialValue() {
      if (this.initialValue) {
        global.$(this.$el).checkbox('check')
      }
      if (this.isDisabled) {
        global.$(this.$el).checkbox('disable')
      }
    },
  },
}
</script>

<style></style>
