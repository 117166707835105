var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('loading',{attrs:{"loading":_vm.loading,"inline":""}}):_c('span',{staticClass:"user-action-indicator",on:{"click":_vm.indicatorClicked}},[(_vm.hasPopUp)?_c('tippy',{attrs:{"to":_vm.uniqueIdentifier,"arrow":"true","interactive":""}},[_c('user-action-status-hover',{key:_vm.hoverKey,attrs:{"status":_vm.status,"organization":_vm.organization,"action-type":_vm.actionType,"action-time":_vm.userActionDate,"error-message":_vm.errorMessage}})],1):_vm._e(),(_vm.showReminderCheckbox)?_c('child-checkbox',{staticClass:"reminder-checkbox",on:{"input":function (input) { return _vm.$emit('checked', input); }}}):(_vm.statusText)?_c('pre',{staticClass:"grey status-text",attrs:{"name":_vm.uniqueIdentifier}},[_vm._v("      "+_vm._s(_vm.statusText)+"\n  ")]):_c('i',{class:[
      _vm.statusIcon,
      _vm.color,
      {
        'reminder-mode-fade': _vm.isReminderSetupMode,
        'clickable-indicator': _vm.isClickable,
      } ],style:([
      { backgroundImage: _vm.status === 'partialComplete' ? _vm.linearGradient : '' } ]),attrs:{"name":_vm.uniqueIdentifier}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }