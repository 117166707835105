<template>
  <card-description>
    <flex direction="column" gap="0.5rem">
      <recipient-group
        title="To"
        :recipients="recipients"
        :action="action"
        :users="users"
        :groups="groups"
        :relationships="relationships"
        :slack-conversations="[]"
        :loading="loading"
        hide-channels
        @get-recipients="() => $emit('get-recipients')"
        @patch-action="({ field, value }) => patchAction(field, value)"
      >
        <template v-if="action.sendToRecipientPersonalEmail" #label>
          <g-label horizontal> Personal Email </g-label>
        </template>
        <template #footer>
          <checkbox
            :initial-value="action.sendToRecipientPersonalEmail"
            name="Send To Personal Email"
            @update="patchSendToRecipientPersonalEmail"
          >
            Send to Personal Email
          </checkbox>
        </template>
      </recipient-group>
      <carbon-copy-group
        :action="action"
        :users="users"
        :groups="groups"
        :relationships="relationships"
        :loading="loading"
        :carbon-copies="carbonCopies"
        @get-carbon-copies="() => $emit('get-carbon-copies')"
        @get-moment-status="() => $emit('get-moment-status')"
      />
      <reply-to
        :action="action"
        :users="users"
        :groups="groups"
        :relationships="relationships"
        :loading="loading"
        @get-actions="() => $emit('get-actions')"
      />
      <flex direction="column">
        <strong>Subject</strong>
        <flex align="center" gap="1rem">
          <editable-html
            :value="action.subject"
            :loading="patchingField === 'subject'"
            placeholder="Add Subject"
            class="message-content"
            fluid
            @update="(value) => patchAction('subject', value)"
          />
          <personalization-token-dropdown
            ref="personalization"
            icon="far fa-address-card"
            inline
            :aspects="aspects"
            @update="insertEmailToken"
          />
        </flex>
      </flex>
      <text-editor
        :value="action.body"
        :aspects="aspects"
        :character-limit="10000"
        :loading="patchingField === 'body'"
        @input="(value) => patchAction('body', value)"
      />
    </flex>
  </card-description>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import cardDescription from '@/components/v2/card/description.vue'
import recipientGroup from '@/components/v2/action_card/recipient_group.vue'
import textEditor from '@/components/v2/text_editor.vue'
import editableHtml from '@/components/v2/editable_html.vue'
import personalizationTokenDropdown from '@/components/v2/personalization_token_dropdown.vue'
import flex from '@/components/v2/flex.vue'
import checkbox from '@/components/v2/checkbox.vue'
import replyTo from '@/components/v2/action_card/reply_to.vue'
import gLabel from '@/components/v2/label.vue'
import carbonCopyGroup from '@/components/v2/action_card/carbon_copy_group.vue'

export default {
  components: {
    cardDescription,
    recipientGroup,
    textEditor,
    editableHtml,
    personalizationTokenDropdown,
    flex,
    checkbox,
    replyTo,
    gLabel,
    carbonCopyGroup,
  },
  props: {
    action: {
      type: Object,
      required: true,
    },
    recipients: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
    relationships: {
      type: Array,
      required: true,
    },
    aspects: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    carbonCopies: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      patchingField: null,
    }
  },
  methods: {
    async patchAction(field, value) {
      try {
        this.patchingField = field
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${this.action.id}`,
          { [field]: value }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingField = null
      }
      this.$emit('get-actions')
    },
    async insertEmailToken(value) {
      await this.patchAction(
        'subject',
        `${this.action.subject || ''}<span></span>${value.html}`
      )
      this.$refs.personalization.hide()
    },
    async patchSendToRecipientPersonalEmail(value) {
      // Added toast to make this obnoxiously clear
      // If the UI of email.vue is refactored we can remove
      toast.info('This will apply to all recipients')
      await this.patchAction('sendToRecipientPersonalEmail', value)
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/message-content.less';
</style>
