var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid',{staticClass:"button-form"},[(_vm.action.buttonUrl)?_c('grid-column',{attrs:{"sixteen":""}},[_c('flex',{attrs:{"align":"center","gap":"1rem"}},[_c('btn',{attrs:{"basic":"","variant":"primary"},nativeOn:{"click":function($event){return _vm.editButtonUrl.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.action.buttonName)+" ")]),_c('btn',{attrs:{"dismiss":"","compact":"","tooltip":"Remove Button","loading":_vm.clearingUrl},nativeOn:{"click":function($event){return _vm.removeButtonUrl.apply(null, arguments)}}})],1)],1):_vm._e(),(!_vm.formType && !_vm.action.buttonUrl)?_c('grid-column',{attrs:{"sixteen":""}},[_c('dropdown',{attrs:{"name":"Buttons","placeholder":"Add Button","label":"name","reduced-field":"value","options":_vm.buttonOptions},on:{"update":function (value) { return (_vm.formType = value); }}})],1):_vm._e(),(_vm.formType)?_c('grid-column',{attrs:{"sixteen":""}},[(_vm.formType === 'link')?_c('g-form',{attrs:{"id":("normal-link-" + (_vm.action.id))},on:{"submit":_vm.saveButtonUrl},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('form-validation',{attrs:{"valid":valid},on:{"update":function (value) { return (_vm.isLinkFormValid = value); }}}),_c('form-field',[_c('form-label',[_vm._v(" Button Name ")]),_c('form-input',{attrs:{"name":"Button Name","rules":['required', 'max:255'],"initial-value":_vm.action.buttonName || '🔗 Check it out',"placeholder":"Call to action"}})],1),_c('form-field',[_c('form-label',[_vm._v(" Link ")]),_c('form-input',{attrs:{"name":"Link","rules":['url', 'required'],"initial-value":_vm.action.buttonUrl,"placeholder":"https://"}})],1),_c('flex',{attrs:{"justify":"space-between"}},[_c('form-btn',{attrs:{"variant":"default","type":"reset","form-id":("normal-link-" + (_vm.action.id))},nativeOn:{"click":function($event){return _vm.resetButtonForm.apply(null, arguments)}}},[_vm._v(" Cancel ")]),_c('form-btn',{attrs:{"variant":"primary","loading":_vm.savingUrl,"form-id":("normal-link-" + (_vm.action.id)),"invalid":!_vm.isLinkFormValid}},[_vm._v(" Save Button ")])],1)]}}],null,false,3365182343)}):_vm._e(),(_vm.formType === 'dm')?_c('g-form',{attrs:{"id":("dm-" + (_vm.action.id))},on:{"submit":_vm.saveButtonUrl},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('form-validation',{attrs:{"valid":valid},on:{"update":function (value) { return (_vm.isLinkDmFormValid = value); }}}),_c('form-field',[_c('form-label',[_vm._v(" Button Name ")]),_c('form-input',{attrs:{"name":"Button Name","rules":['required', 'max:255'],"initial-value":_vm.action.buttonName || '🧑 Send Message',"placeholder":"Call to action"}})],1),_c('form-field',[_c('form-label',[_vm._v(" Person ")]),_c('form-dropdown',{attrs:{"name":"People","required":"","searchable":"","options":_vm.users,"label":"fullName","reduced-field":"slackUserId","value":_vm.getSearchParam(_vm.action.buttonUrl, 'id'),"placeholder":"Select Person"}})],1),_c('flex',{attrs:{"justify":"space-between"}},[_c('form-btn',{attrs:{"variant":"default","type":"reset","form-id":("dm-" + (_vm.action.id))},nativeOn:{"click":function($event){return _vm.resetButtonForm.apply(null, arguments)}}},[_vm._v(" Cancel ")]),_c('form-btn',{attrs:{"variant":"primary","loading":_vm.savingUrl,"form-id":("dm-" + (_vm.action.id)),"invalid":!_vm.isLinkDmFormValid}},[_vm._v(" Save Button ")])],1)]}}],null,false,3189031274)}):_vm._e(),(_vm.formType === 'channel')?_c('g-form',{attrs:{"id":("channel-" + (_vm.action.id))},on:{"submit":_vm.saveButtonUrl},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('form-validation',{attrs:{"valid":valid},on:{"update":function (value) { return (_vm.isLinkChannelFormValid = value); }}}),_c('form-field',[_c('form-label',[_vm._v(" Button Name ")]),_c('form-input',{attrs:{"name":"Button Name","rules":['required', 'max:255'],"initial-value":_vm.action.buttonName || 'Post in Channel',"placeholder":"Call to action"}})],1),_c('form-field',[_c('form-label',[_vm._v(" Slack Channel ")]),_c('form-dropdown',{attrs:{"name":"Channels","required":"","searchable":"","value":_vm.getSearchParam(_vm.action.buttonUrl, 'id'),"placeholder":"Select Channel","options":_vm.slackConversations,"label":"displayName","reduced-field":"conversationId"}})],1),_c('flex',{attrs:{"justify":"space-between"}},[_c('form-btn',{attrs:{"variant":"default","type":"reset","form-id":("channel-" + (_vm.action.id))},nativeOn:{"click":function($event){return _vm.resetButtonForm.apply(null, arguments)}}},[_vm._v(" Cancel ")]),_c('form-btn',{attrs:{"variant":"primary","loading":_vm.savingUrl,"form-id":("channel-" + (_vm.action.id)),"invalid":!_vm.isLinkChannelFormValid}},[_vm._v(" Save Button ")])],1)]}}],null,false,707224290)}):_vm._e(),(_vm.formType === 'calendar')?_c('g-form',{attrs:{"id":("calendar-" + (_vm.action.id))},on:{"submit":_vm.saveButtonUrl},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('form-validation',{attrs:{"valid":valid},on:{"update":function (value) { return (_vm.isLinkCalendarFormValid = value); }}}),_c('form-field',[_c('form-label',[_vm._v(" Button Name ")]),_c('form-input',{attrs:{"name":"Button Name","rules":['required', 'max:255'],"initial-value":_vm.action.buttonName || '📅 Create Calendar Event',"placeholder":"Call to action"}})],1),_c('form-field',[_c('form-label',[_vm._v(" Event Name ")]),_c('form-input',{attrs:{"name":"Event Name","rules":['required', 'max:255'],"initial-value":_vm.getSearchParam(_vm.action.buttonUrl, 'text'),"placeholder":"30 Day Check In"}})],1),_c('form-field',[_c('form-label',[_vm._v(" Event Description ")]),_c('form-textarea',{attrs:{"name":"Event Description","rules":['max:1500'],"initial-value":_vm.getSearchParam(_vm.action.buttonUrl, 'details'),"placeholder":"Give context or other useful information"}})],1),_c('flex',{attrs:{"justify":"space-between"}},[_c('form-btn',{attrs:{"form-id":("calendar-" + (_vm.action.id)),"variant":"default","type":"reset"},nativeOn:{"click":function($event){return _vm.resetButtonForm.apply(null, arguments)}}},[_vm._v(" Cancel ")]),_c('form-btn',{attrs:{"form-id":("calendar-" + (_vm.action.id)),"variant":"primary","loading":_vm.savingUrl,"invalid":!_vm.isLinkCalendarFormValid}},[_vm._v(" Save Button ")])],1)]}}],null,false,1226543569)}):_vm._e(),(_vm.formType === 'email')?_c('g-form',{attrs:{"id":"email"},on:{"submit":_vm.saveButtonUrl},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('form-validation',{attrs:{"valid":valid},on:{"update":function (value) { return (_vm.isLinkEmailFormValid = value); }}}),_c('form-field',[_c('form-label',[_vm._v(" Button Name ")]),_c('form-input',{attrs:{"name":"Button Name","rules":['required', 'max:255'],"initial-value":_vm.action.buttonName || '✉️ Create Email From Template',"placeholder":"Call to action"}})],1),_c('form-field',[_c('form-label',[_vm._v(" To ")]),_c('form-dropdown',{attrs:{"name":"To","value":_vm.getSearchParam(_vm.action.buttonUrl, 'to'),"placeholder":"Select Person","options":_vm.users,"reduced-field":"email","searchable":"","label":"fullName"}})],1),_c('form-field',[_c('form-label',[_vm._v(" Subject ")]),_c('form-input',{attrs:{"name":"Subject","rules":['max:255'],"initial-value":_vm.getSearchParam(_vm.action.buttonUrl, 'su'),"placeholder":"Give context or other useful information"}})],1),_c('form-field',[_c('form-label',[_vm._v(" Email Body ")]),_c('form-textarea',{attrs:{"name":"Email Body","rules":['max:1500'],"initial-value":_vm.getSearchParam(_vm.action.buttonUrl, 'body'),"placeholder":"Give context or other useful information"}})],1),_c('flex',{attrs:{"justify":"space-between"}},[_c('form-btn',{attrs:{"variant":"default","type":"reset","form-id":"email"},nativeOn:{"click":function($event){return _vm.resetButtonForm.apply(null, arguments)}}},[_vm._v(" Cancel ")]),_c('form-btn',{attrs:{"variant":"primary","loading":_vm.savingUrl,"form-id":"email","invalid":!_vm.isLinkEmailFormValid}},[_vm._v(" Save Button ")])],1)]}}],null,false,2176648575)}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }