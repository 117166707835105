import { render, staticRenderFns } from "./button_form.vue?vue&type=template&id=40d372aa&scoped=true&"
import script from "./button_form.vue?vue&type=script&lang=js&"
export * from "./button_form.vue?vue&type=script&lang=js&"
import style0 from "./button_form.vue?vue&type=style&index=0&id=40d372aa&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d372aa",
  null
  
)

export default component.exports