<template>
  <card-description>
    <flex direction="column" gap="0.5rem">
      <flex direction="column" gap="0.5rem">
        <strong>Channel Name</strong>
        <flex justify="space-between" align="center">
          <flex>
            <dropdown
              name="Channel Privacy"
              :icon="action.isPrivateChannel ? 'fas fa-lock' : 'fas fa-hashtag'"
              button
              basic
              button-variant="primary"
              hide-caret
              tooltip="Set Channel Privacy"
              :initial-value="action.isPrivateChannel"
              :loading="patchingField === 'isPrivateChannel'"
              @update="(value) => patchAction('isPrivateChannel', value)"
            >
              <dropdown-item :value="false">
                <i class="fas fa-hashtag" />
                Public Channel
              </dropdown-item>
              <dropdown-item :value="true">
                <i class="fas fa-lock" />
                Private Channel
              </dropdown-item>
            </dropdown>
            <editable-html
              :value="action.channelName || DEFAULT_CHANNEL_NAME"
              :loading="patchingField === 'channelName'"
              placeholder="Add Channel Name"
              class="message-content"
              @update="(value) => patchAction('channelName', value)"
            />
          </flex>
          <personalization-token-dropdown
            ref="personalization"
            icon="far fa-address-card"
            inline
            :aspects="aspects"
            :loading="patchingField === 'channelName'"
            @update="insertChannelToken"
            @patch-action="({ field, value }) => patchAction(field, value)"
          />
        </flex>
      </flex>
      <recipient-group
        title="People to Add"
        placeholder="⚠️ Click + to add people to this channel"
        :recipients="recipients"
        :action="action"
        :users="users"
        :groups="groups"
        :relationships="relationships"
        :slack-conversations="slackConversations"
        hide-channels
        :loading="loading"
        @get-recipients="() => $emit('get-recipients')"
        @get-moment-status="() => $emit('get-moment-status')"
        @patch-action="({ field, value }) => patchAction(field, value)"
      />
      <checkbox
        class="action-checkbox"
        :initial-value="!!action.archiveDate"
        name="Automatic Archival"
        @update="(value) => setAutoArchiveForChannel(value)"
      >
        Automatically Archive Channel
      </checkbox>
      <flex v-if="!!action.archiveDate" direction="column">
        <strong
          >Archive Date & Time
          <i
            v-tippy
            class="fas fa-info-circle"
            content="This is relative to the trigger date (e.g. Start Date), not the creation of the channel"
          />
        </strong>

        <flex gap="1rem" wrap="wrap" align="center">
          <delta-picker
            :delta="action.archiveDate"
            :loading="patchingField === 'archiveDate'"
            :start="action.relativeDate"
            @update="(value) => patchAction('archiveDate', value)"
          />
          <time-picker
            :time="action.archiveTime"
            :loading="patchingField === 'archiveTime'"
            :start="
              isEqual(action.archiveDate, action.relativeDate)
                ? action.relativeTime
                : null
            "
            @update="(value) => patchAction('archiveTime', value)"
          />
        </flex>
      </flex>
      <flex direction="column">
        <btn
          size="small"
          compact
          basic
          class="preview-button"
          variant="default"
          @click.native="() => (showPreview = !showPreview)"
        >
          {{ showPreview ? 'Edit' : 'Close Text Editor' }}
        </btn>
        <slack-message-preview
          v-show="showPreview"
          class="message-preview pointer"
          :action="action"
          :organization="organization"
          @click.native="() => (showPreview = false)"
        />
        <text-editor
          v-show="!showPreview"
          :value="action.body"
          :aspects="aspects"
          :loading="patchingField === 'body'"
          @input="(value) => patchAction('body', value)"
        />
        <button-form
          v-show="!showPreview"
          :action="action"
          :users="users"
          :slack-integration="slackIntegration"
          :slack-conversations="slackConversations"
          @get-actions="() => $emit('get-actions')"
        />
      </flex>
    </flex>
  </card-description>
</template>

<script>
import { isEqual } from 'lodash'
import { api } from '@/api'
import { toast } from '@/toasts'

import cardDescription from '@/components/v2/card/description.vue'
import checkbox from '@/components/v2/checkbox.vue'
import editableHtml from '@/components/v2/editable_html.vue'
import flex from '@/components/v2/flex.vue'
import btn from '@/components/v2/btn.vue'
import deltaPicker from '@/components/v2/delta_picker.vue'
import timePicker from '@/components/v2/time_picker.vue'
import recipientGroup from '@/components/v2/action_card/recipient_group.vue'
import personalizationTokenDropdown from '@/components/v2/personalization_token_dropdown.vue'
import textEditor from '@/components/v2/text_editor.vue'
import slackMessagePreview from '@/components/v2/slack_message_preview.vue'
import dropdown from '@/components/v2/dropdown/dropdown.vue'
import dropdownItem from '@/components/v2/dropdown/item.vue'
import buttonForm from '@/components/v2/action_card/button_form.vue'

// this default channel name must be used here to support actions created in api v1
// this could be deprecated if we manually add the default to old actions in the db
const DEFAULT_CHANNEL_NAME = `
gather-<span class="message-content"><span class="mention" contenteditable="false">@Enrollee Full Name</span></span>
`

export default {
  components: {
    cardDescription,
    checkbox,
    recipientGroup,
    personalizationTokenDropdown,
    textEditor,
    editableHtml,
    flex,
    btn,
    deltaPicker,
    timePicker,
    slackMessagePreview,
    dropdown,
    dropdownItem,
    buttonForm,
  },
  props: {
    action: {
      type: Object,
      required: true,
    },
    recipients: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
    relationships: {
      type: Array,
      required: true,
    },
    slackConversations: {
      type: Array,
      required: true,
    },
    aspects: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    slackIntegration: {
      type: Object,
      default: null,
    },
    organization: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      DEFAULT_CHANNEL_NAME,
      token: {},
      creatingRecipient: false,
      patchingField: null,
      showPreview: true,
      isEqual,
    }
  },
  methods: {
    async patchAction(field, value) {
      try {
        this.patchingField = field
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${this.action.id}`,
          { [field]: value }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingField = null
      }
      this.$emit('get-actions')
    },
    async insertChannelToken(value) {
      const channelName = this.action.channelName || DEFAULT_CHANNEL_NAME
      await this.patchAction(
        'channelName',
        `${channelName}<span></span>${value.html}`
      )
    },
    async setAutoArchiveForChannel(isArchivable) {
      try {
        if (isArchivable) {
          await api.patch(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${this.action.id}`,
            {
              archiveDate: this.action.relativeDate,
              archiveTime: this.action.relativeTime,
            }
          )
        } else {
          await api.patch(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${this.action.id}`,
            {
              archiveDate: null,
              archiveTime: null,
            }
          )
        }
      } catch (error) {
        toast.error(error)
      }
      this.$emit('get-actions')
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/message-content.less';

.preview-button {
  display: block;
  margin: 0 0 0.5rem auto;
}

.message-preview {
  width: 100%;
}
</style>
