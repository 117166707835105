<template>
  <div v-click-outside="hide" :class="classes">
    <div :class="['ui input left icon', { loading: loading }]">
      <i class="time icon" />
      <input
        ref="timePicker"
        v-tippy
        type="text"
        :name="name"
        :placeholder="placeholder"
        class="time-picker"
        :value="formatTime(time)"
        :content="placeholder"
        autocomplete="off"
        @focus="() => (typing = true)"
        @blur="onBlur"
        @click="onClick"
        @input="onInput"
        @keydown="onEnter"
      />
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'

import { set } from 'date-fns'
import jquery from 'jquery'
import { toast } from '@/toasts'
import dateMixin from '@/mixins/v2/dateMixin'

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [dateMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Object,
      default: null,
    },
    start: {
      type: Object,
      default: null,
    },
    end: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'Time',
    },
    placeholder: {
      type: String,
      default: 'Select Time',
    },
    minTimeGap: {
      type: Number,
      default: 15,
    },
  },
  data() {
    return {
      typing: false,
    }
  },
  computed: {
    classes() {
      return {
        ui: true,
        calendar: true,
        didSyncJquery: false,
        disabled: this.disabled,
        loading: this.loading,
      }
    },
  },
  watch: {
    time: {
      handler() {
        this.syncJquery()
      },
    },
    start: {
      handler() {
        this.syncJquery()
      },
    },
    end: {
      handler() {
        this.syncJquery()
      },
    },
  },
  beforeDestroy() {
    jquery(this.$el).removeData()
    jquery(this.$el).remove()
  },
  mounted() {
    this.syncJquery()
  },
  methods: {
    syncJquery() {
      const calendar = jquery(this.$el)
      calendar.calendar({
        type: 'time',
        minTimeGap: this.minTimeGap,
        onChange: (time) => {
          if (
            time &&
            (this.time === null ||
              !(
                time.getHours() === this.time.hour &&
                time.getMinutes() === this.time.minute
              ))
          ) {
            if (!this.typing) {
              this.$refs.timePicker.blur()
            }
          }
        },
        onSelect: () => {
          this.typing = false
        },
      })
      calendar.calendar('set minDate', this.timeToDate(this.start))
      calendar.calendar('set maxDate', this.timeToDate(this.end))
      calendar.calendar('set date', this.timeToDate(this.time))
    },
    onClick() {
      if (!this.didSyncJquery) {
        this.syncJquery()
        jquery(this.$el).calendar('popup', 'show')
      }
      this.didSyncJquery = true
    },
    timeToDate(time) {
      if (!time) {
        return time
      }

      return set(new Date(), {
        hours: time.hour,
        minutes: time.minute,
        seconds: 0,
        milliseconds: 0,
      })
    },
    onBlur() {
      this.typing = false
      const time = jquery(this.$el).calendar('get date')
      this.$emit('update', {
        hour: time.getHours(),
        minute:
          Math.floor(time.getMinutes() / this.minTimeGap) * this.minTimeGap,
      })
      if (time.getMinutes() % this.minTimeGap !== 0) {
        toast.info(`Gather only allows ${this.minTimeGap}-minute intervals`)
      }
    },
    onInput() {
      this.typing = true
      this.hide()
    },
    onEnter({ keyCode }) {
      if (keyCode === 13) {
        this.$refs.timePicker.blur()
      }
    },
    hide() {
      jquery(this.$el).calendar('popup', 'hide')
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';
@import '~@/assets/less/borders.less';

.ui.calendar {
  .time.icon {
    left: 0;
  }

  .time-picker {
    border: none;
    max-width: 8rem;

    &::placeholder {
      color: @black;
    }

    &:hover {
      cursor: pointer;
      background-color: @light-grey-hover;
    }

    &:focus {
      cursor: text;
      background-color: @white;
      box-shadow: @focus-box-shadow-border;
    }
  }
}
</style>
