<template>
  <card-body v-if="action.type !== 'moment'">
    <card-header class="card-header">
      <flex align="start" justify="space-between">
        <flex justify="flex-start">
          <i :class="['blue action-icon', icon]" />
          <flex direction="column">
            <flex gap="1rem">
              <span class="action-type">
                {{ title }}
              </span>
              <g-label v-if="action.type === 'meeting'" horizontal light-blue>
                Beta
              </g-label>
            </flex>
            <flex align="center" wrap="wrap">
              <delta-picker
                :disabled="action.type === 'task'"
                :delta="action.relativeDate"
                :loading="patchingActionField === 'relativeDate'"
                @update="(value) => patchAction('relativeDate', value)"
              />
              <time-picker
                :disabled="action.type === 'task'"
                :time="action.relativeTime"
                :loading="patchingActionField === 'relativeTime'"
                @update="(value) => patchAction('relativeTime', value)"
              />
            </flex>
          </flex>
        </flex>
        <overflow-menu
          class="overflow-menu"
          :options="overflowOptions"
          type="horizontal"
          size="small"
          @delete="() => $emit('delete-action', action.id)"
          @send-test-message="showTestModal"
          @send-test-email="showTestModal"
          @create-test-meeting="showTestModal"
        />
      </flex>
    </card-header>
    <modal
      v-if="
        ['message', 'relationship', 'channel', 'task'].includes(action.type)
      "
      ref="testMessage"
      :key="`test-message-modal-${action.id}`"
      @deny="resetTestMessageModal"
    >
      <modal-header> Send Test Message </modal-header>
      <modal-content>
        <g-form
          :id="`send-test-message-${action.id}`"
          @submit="sendTestMessage"
        >
          <template #default="{ valid }">
            <form-validation
              :valid="valid"
              @update="(value) => (isSlackMessageFormValid = value)"
            />
            <form-field required>
              <form-label>Send Message To</form-label>
              <form-dropdown
                name="People"
                required
                searchable
                :options="users"
                label="fullName"
                placeholder="Select Person"
                :value="testSlackRecipients"
                multiple
                @update="(value) => (testSlackRecipients = value)"
              />
            </form-field>
            <form-field required>
              <form-label>
                Example Enrollee
                <i
                  v-tippy
                  class="fas fa-info-circle"
                  content="Fills in the personalization tokens with this person's information.
                  They will not be notified."
                />
              </form-label>
              <form-dropdown
                name="Example Enrollee"
                required
                append-to-body
                placement="bottom"
                :value="exampleEnrolleeId"
                placeholder="Select Person"
                :options="users"
                label="fullName"
                reduced-field="id"
                searchable
                @update="(value) => (exampleEnrolleeId = value)"
              />
            </form-field>
          </template>
        </g-form>
      </modal-content>
      <modal-actions space-between>
        <form-btn
          variant="default"
          type="reset"
          :form-id="`send-test-message-${action.id}`"
        >
          Cancel
        </form-btn>

        <form-btn
          :loading="sendingMessages"
          variant="primary"
          type="submit"
          :invalid="
            !isSlackMessageFormValid ||
            !testSlackRecipients.length ||
            !exampleEnrolleeId
          "
          :form-id="`send-test-message-${action.id}`"
        >
          Send to Slack
        </form-btn>
      </modal-actions>
    </modal>
    <modal
      v-if="action.type === 'email'"
      ref="testEmail"
      :key="`test-email-modal-${action.id}`"
      @deny="resetTestEmailModal"
    >
      <modal-header> Send Test Email </modal-header>
      <modal-content>
        <g-form :id="`send-test-email-${action.id}`" @submit="sendTestEmail">
          <template #default="{ valid }">
            <form-validation
              :valid="valid"
              @update="(value) => (isEmailFormValid = value)"
            />
            <form-field required>
              <form-label>
                Send To
                {{
                  action.sendToRecipientPersonalEmail
                    ? 'Personal Email'
                    : 'Work Email'
                }}
              </form-label>
              <form-dropdown
                name="People"
                required
                searchable
                :options="users"
                label="fullName"
                :secondary-label="
                  action.sendToRecipientPersonalEmail
                    ? 'personalEmail'
                    : 'email'
                "
                secondary-label-warning="No Email"
                placeholder="Select Person"
                :value="testEmailRecipient"
                @update="(value) => (testEmailRecipient = value)"
              />
            </form-field>
            <form-field required>
              <form-label>
                Example Enrollee
                <i
                  v-tippy
                  class="fas fa-info-circle"
                  content="Fills in the personalization tokens with this person's information.
                  They will not be notified."
                />
              </form-label>
              <form-dropdown
                name="Example Enrollee"
                required
                append-to-body
                placement="bottom"
                :value="exampleEnrolleeId"
                placeholder="Select Person"
                :options="users"
                label="fullName"
                reduced-field="id"
                searchable
                @update="(value) => (exampleEnrolleeId = value)"
              />
            </form-field>
            <checkbox
              :initial-value="includeCarbonCopies"
              name="Send to cc"
              @update="(value) => (includeCarbonCopies = value)"
            >
              Send to CC'ed people
            </checkbox>
          </template>
        </g-form>
      </modal-content>
      <modal-actions space-between>
        <form-btn
          variant="default"
          type="reset"
          :form-id="`send-test-email-${action.id}`"
        >
          Cancel
        </form-btn>

        <form-btn
          :loading="sendingEmails"
          variant="primary"
          type="submit"
          :invalid="
            !isEmailFormValid || !testEmailRecipient || !exampleEnrolleeId
          "
          :form-id="`send-test-email-${action.id}`"
        >
          Send Email
        </form-btn>
      </modal-actions>
    </modal>
    <modal
      v-if="action.type === 'meeting'"
      ref="testMeeting"
      :key="`test-meeting-modal-${action.id}`"
      @deny="resetTestMeetingModal"
    >
      <modal-header> Create Test Meeting </modal-header>
      <modal-content>
        <g-form
          :id="`create-test-meeting-${action.id}`"
          @submit="createTestMeeting"
        >
          <template #default="{ valid }">
            <form-validation
              :valid="valid"
              @update="(value) => (isMeetingFormValid = value)"
            />
            <form-field required>
              <form-label> Send meeting invite to</form-label>
              <form-dropdown
                name="Example Attendee"
                required
                append-to-body
                placement="bottom"
                :value="exampleAttendeeId"
                placeholder="Select Person"
                :options="users"
                label="fullName"
                reduced-field="id"
                searchable
                @update="(value) => (exampleAttendeeId = value)"
              />
            </form-field>
            <form-field required>
              <form-label>
                Example Enrollee
                <i
                  v-tippy
                  class="fas fa-info-circle"
                  content="Fills in the personalization tokens with this person's information.
                  They will not be notified."
                />
              </form-label>
              <form-dropdown
                name="Example Enrollee"
                required
                append-to-body
                placement="bottom"
                :value="exampleEnrolleeId"
                placeholder="Select Person"
                :options="users"
                label="fullName"
                reduced-field="id"
                searchable
                @update="(value) => (exampleEnrolleeId = value)"
              />
            </form-field>
            Meeting will be scheduled today
            {{ formatTime(action.relativeMeetingStartTime) }} -
            {{ formatTime(action.relativeMeetingEndTime) }} on
            {{ calendarOwner ? calendarOwner.preferredFirstName : 'N/A' }}'s
            <em>Gather Meetings</em> calendar
          </template>
        </g-form>
      </modal-content>
      <modal-actions space-between>
        <form-btn
          variant="default"
          type="reset"
          :form-id="`create-test-meeting-${action.id}`"
        >
          Cancel
        </form-btn>

        <form-btn
          :loading="creatingMeeting"
          variant="primary"
          type="submit"
          :invalid="
            !isMeetingFormValid ||
            !calendarOwner ||
            !exampleEnrolleeId ||
            !exampleAttendeeId
          "
          :form-id="`create-test-meeting-${action.id}`"
        >
          Create Meeting
        </form-btn>
      </modal-actions>
    </modal>
  </card-body>
  <flex v-else class="action-type-moment" align="center" gap="1rem">
    <moment-icon :hex-color="moment.color" :icon="moment.icon" size="large" />
    {{ title }}
  </flex>
</template>

<script>
import { find } from 'lodash'
import { api } from '@/api'
import { toast } from '@/toasts'

import deltaPicker from '@/components/v2/delta_picker.vue'
import timePicker from '@/components/v2/time_picker.vue'
import flex from '@/components/v2/flex.vue'
import cardBody from '@/components/v2/card/body.vue'
import cardHeader from '@/components/v2/card/header.vue'
import overflowMenu from '@/components/v2/overflow_menu.vue'
import momentIcon from '@/components/v2/moment_icon.vue'
import modal from '@/components/v2/modal/modal.vue'
import modalHeader from '@/components/v2/modal/header.vue'
import modalActions from '@/components/v2/modal/actions.vue'
import modalContent from '@/components/v2/modal/content.vue'
import gForm from '@/components/v2/form/form.vue'
import formField from '@/components/v2/form/field.vue'
import formBtn from '@/components/v2/form/btn.vue'
import formLabel from '@/components/v2/form/label.vue'
import formValidation from '@/components/v2/form/validation.vue'
import formDropdown from '@/components/v3/form_dropdown.vue'
import gLabel from '@/components/v2/label.vue'
import checkbox from '@/components/v2/checkbox.vue'

import dateMixin from '@/mixins/v2/dateMixin'

export default {
  components: {
    deltaPicker,
    timePicker,
    flex,
    cardBody,
    cardHeader,
    overflowMenu,
    momentIcon,
    modal,
    modalActions,
    modalContent,
    modalHeader,
    gForm,
    formField,
    formBtn,
    formValidation,
    formLabel,
    formDropdown,
    gLabel,
    checkbox,
  },
  mixins: [dateMixin],
  props: {
    action: {
      type: Object,
      required: true,
    },
    moment: {
      type: Object,
      required: true,
    },
    triggers: {
      type: Array,
      required: true,
    },
    relationships: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    organization: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      patchingActionField: null,
      isSlackMessageFormValid: false,
      isEmailFormValid: false,
      isMeetingFormValid: false,
      testSlackRecipients: [],
      testEmailRecipient: null,
      exampleEnrolleeId: null,
      exampleEnrolleeEmail: null,
      exampleAttendeeId: null,
      sendingMessages: false,
      sendingEmails: false,
      creatingMeeting: false,
      includeCarbonCopies: false,
    }
  },
  computed: {
    title() {
      if (this.action.type === 'message') {
        return 'Slack Message'
      }
      if (this.action.type === 'channel') {
        return 'Create Channel'
      }
      if (this.action.type === 'relationship') {
        return `Assign ${this.getRelationshipSuperiorTitle()}`
      }
      if (this.action.type === 'task') {
        return 'Send Task'
      }
      if (this.action.type === 'email') {
        return 'Send Email'
      }
      if (this.action.type === 'meeting') {
        return 'Schedule Meeting'
      }

      if (this.action.type === 'moment') {
        if (this.moment.type === 'aspect') {
          return `Enrollee's ${
            (find(this.triggers, { id: this.moment.aspectId }) || {}).name ||
            'Trigger Date'
          }`
        }
        if (this.moment.type === 'date') {
          return this.moment.triggerDate
            ? this.formatDate(this.parseDate(this.moment.triggerDate), true)
            : 'Trigger Date'
        }
        if (this.moment.type === 'work_anniversary') {
          return `Enrollee's Work Anniversary`
        }
        if (this.moment.type === 'birthday') {
          return `Enrollee's Birthday`
        }
        if (this.moment.type === 'end_date') {
          return `Enrollee's End Date`
        }
        if (this.moment.type === 'start_date') {
          return `Enrollee's Start Date`
        }

        throw new Error(`unknown moment type: ${this.moment.type}`)
      }

      throw new Error(`unknown action type: ${this.action.type}`)
    },
    icon() {
      if (this.action.type === 'message') {
        return 'far fa-comment-alt'
      }
      if (this.action.type === 'channel') {
        return 'fas fa-hashtag'
      }
      if (this.action.type === 'relationship') {
        return 'fas fa-people-arrows'
      }
      if (this.action.type === 'task') {
        return 'fas fa-tasks'
      }
      if (this.action.type === 'email') {
        return 'far fa-envelope'
      }
      if (this.action.type === 'meeting') {
        return 'far fa-calendar'
      }

      throw new Error(`unknown action type: ${this.action.type}`)
    },
    calendarOwner() {
      return (
        this.users.find(
          (user) => user.id === this.organization.calendarOwnerId
        ) || null
      )
    },
    overflowOptions() {
      const options = []

      if (this.action.type === 'meeting') {
        options.push({
          name: 'Create Test Meeting',
          icon: 'fas fa-calendar-plus',
        })
      } else {
        options.push({
          name: `Send Test ${
            this.action.type === 'email' ? 'Email' : 'Message'
          }`,
          icon: 'fas fa-paper-plane',
        })
      }

      if (this.action.type !== 'task') {
        options.push({ name: 'Delete', danger: true, icon: 'fas fa-trash' })
      }

      return options
    },
  },
  mounted() {
    this.resetTestMessageModal()
    this.resetTestEmailModal()
    this.resetTestMeetingModal()
  },
  methods: {
    async patchAction(field, value) {
      try {
        this.patchingActionField = field
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${this.action.id}`,
          { [field]: value }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingActionField = null
      }

      this.$emit(
        'get-actions',
        ['relativeDate', 'relativeTime'].includes(field)
          ? {
              goToAction: this.action.id,
            }
          : undefined
      )
    },
    getRelationshipSuperiorTitle() {
      const relationship =
        find(this.relationships, {
          relationshipId: this.action.relationshipId,
        }) || {}
      return relationship.superiorTitle || relationship.name || 'Relationship'
    },
    async sendTestMessage(submission) {
      if (this.testSlackRecipients.length === 0) {
        toast.info("You haven't picked any recipients for this test message")
        return
      }

      try {
        this.sendingMessages = true
        const result = await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/rpcs/action/send-test-action`,
          {
            actionId: this.action.id,
            enrolleeId: parseInt(submission.get('Example Enrollee'), 10),
            recipientIds: this.testSlackRecipients.map((user) => user.id),
          }
        )

        if (result.data.length) {
          toast.success('Your test message was sent!')
        }
      } catch (error) {
        toast.error(error)
      } finally {
        this.sendingMessages = false
      }
    },
    async sendTestEmail(submission) {
      if (!this.testEmailRecipient) {
        toast.info("You haven't picked a recipient for this test email")
        return
      }

      try {
        this.sendingEmails = true
        await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/rpcs/action/send-test-email`,
          {
            actionId: this.action.id,
            enrolleeId: parseInt(submission.get('Example Enrollee'), 10),
            recipientId: this.testEmailRecipient.id,
            includeCarbonCopies: this.includeCarbonCopies,
          }
        )

        toast.success(`Your test email was sent!`)
      } catch (error) {
        toast.error(error)
      } finally {
        this.sendingEmails = false
      }
    },
    async createTestMeeting() {
      if (!this.calendarOwner.gatherCalendarId) {
        toast.info(
          `We can't find ${this.calendarOwner.fullName}'s Gather calendar. Please contact support`
        )
        return
      }
      try {
        this.creatingMeeting = true
        await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/rpcs/action/create-test-meeting`,
          {
            actionId: this.action.id,
            enrolleeId: this.exampleEnrolleeId, // is there a reason why we use the submission above only sometimes?
            attendeeId: this.exampleAttendeeId,
            calendarOwnerId: this.calendarOwner.id,
          }
        )
        toast.success('Your test meeting was created!')
      } catch (error) {
        toast.error(error)
      } finally {
        this.creatingMeeting = false
      }
    },
    resetTestMessageModal() {
      this.testSlackRecipients = [this.$store.state.currentUser]
      this.exampleEnrolleeId = null
    },
    resetTestEmailModal() {
      this.testEmailRecipient = this.$store.state.currentUser
      this.exampleEnrolleeId = null
    },
    resetTestMeetingModal() {
      this.exampleAttendeeId = this.$store.state.currentUser.id
      this.exampleEnrolleeId = null
    },
    showTestModal() {
      if (this.action.type === 'email') {
        this.$refs.testEmail.show()
      } else if (this.action.type === 'meeting') {
        this.$refs.testMeeting.show()
      } else {
        this.$refs.testMessage.show()
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/text.less';

.card-header {
  margin-bottom: 0.5em;
}

.overflow-menu {
  min-height: 1.5rem;
}

.action-icon {
  font-size: 3rem;
  padding-right: 0.25rem;
}

.action-type {
  margin-left: 0.75rem;
  font-size: @title-font-size;
  font-weight: bold;
}

.action-type-moment {
  font-size: @title-font-size;
  font-weight: bold;
}
</style>
