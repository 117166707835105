<template>
  <validation-provider
    v-slot="{ errors }"
    slim
    :rules="required ? 'required' : ''"
  >
    <input
      :value="
        typeof submission === 'object' ? JSON.stringify(submission) : submission
      "
      :name="name"
      type="hidden"
    />
    <dropdown
      class="form-dropdown"
      :append-to-body="appendToBody"
      :clearable="clearable"
      :groupable="groupable"
      :label="label"
      :loading="loading"
      :multiple="multiple"
      :name="name"
      :options="options"
      :placeholder="placeholder"
      :placement="placement"
      :reduced-field="reducedField"
      :searchable="searchable"
      :secondary-label="secondaryLabel"
      :secondary-label-warning="secondaryLabelWarning"
      :value="submission"
      @update="(val) => (submission = val)"
      @search="(val) => $emit('search', val)"
    />
    <span class="error">{{ errors[0] }}</span>
  </validation-provider>
</template>

<script>
import { isEqual } from 'lodash'

import { ValidationProvider } from '@/extensions/v2/validation'
import dropdown from '@/components/v3/dropdown.vue'

export default {
  components: { ValidationProvider, dropdown },
  props: {
    appendToBody: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    groupable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    placement: {
      type: String,
      default: null,
    },
    reducedField: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    secondaryLabel: {
      type: String,
      default: null,
    },
    secondaryLabelWarning: {
      type: String,
      default: 'N/A',
    },
    value: {
      type: [String, Number, Object, Boolean, Array],
      default: null,
    },
  },
  data() {
    return {
      submission: null,
    }
  },
  watch: {
    value: {
      handler(newValue) {
        this.submission = newValue
      },
      immediate: true,
    },
    submission: {
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) {
          this.$emit('update', newValue)
        }
      },
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';

.error {
  color: @error-red;
}

.g-dropdown.form-dropdown {
  ::v-deep.vs__search {
    width: auto;
    font-size: 1em;
    line-height: 1rem;
    color: @blue;
    background: @white;
    border: none;
    border-radius: 0;
  }
}
</style>
