var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.action.type !== 'moment')?_c('card-body',[_c('card-header',{staticClass:"card-header"},[_c('flex',{attrs:{"align":"start","justify":"space-between"}},[_c('flex',{attrs:{"justify":"flex-start"}},[_c('i',{class:['blue action-icon', _vm.icon]}),_c('flex',{attrs:{"direction":"column"}},[_c('flex',{attrs:{"gap":"1rem"}},[_c('span',{staticClass:"action-type"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.action.type === 'meeting')?_c('g-label',{attrs:{"horizontal":"","light-blue":""}},[_vm._v(" Beta ")]):_vm._e()],1),_c('flex',{attrs:{"align":"center","wrap":"wrap"}},[_c('delta-picker',{attrs:{"disabled":_vm.action.type === 'task',"delta":_vm.action.relativeDate,"loading":_vm.patchingActionField === 'relativeDate'},on:{"update":function (value) { return _vm.patchAction('relativeDate', value); }}}),_c('time-picker',{attrs:{"disabled":_vm.action.type === 'task',"time":_vm.action.relativeTime,"loading":_vm.patchingActionField === 'relativeTime'},on:{"update":function (value) { return _vm.patchAction('relativeTime', value); }}})],1)],1)],1),_c('overflow-menu',{staticClass:"overflow-menu",attrs:{"options":_vm.overflowOptions,"type":"horizontal","size":"small"},on:{"delete":function () { return _vm.$emit('delete-action', _vm.action.id); },"send-test-message":_vm.showTestModal,"send-test-email":_vm.showTestModal,"create-test-meeting":_vm.showTestModal}})],1)],1),(
      ['message', 'relationship', 'channel', 'task'].includes(_vm.action.type)
    )?_c('modal',{key:("test-message-modal-" + (_vm.action.id)),ref:"testMessage",on:{"deny":_vm.resetTestMessageModal}},[_c('modal-header',[_vm._v(" Send Test Message ")]),_c('modal-content',[_c('g-form',{attrs:{"id":("send-test-message-" + (_vm.action.id))},on:{"submit":_vm.sendTestMessage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var valid = ref.valid;
return [_c('form-validation',{attrs:{"valid":valid},on:{"update":function (value) { return (_vm.isSlackMessageFormValid = value); }}}),_c('form-field',{attrs:{"required":""}},[_c('form-label',[_vm._v("Send Message To")]),_c('form-dropdown',{attrs:{"name":"People","required":"","searchable":"","options":_vm.users,"label":"fullName","placeholder":"Select Person","value":_vm.testSlackRecipients,"multiple":""},on:{"update":function (value) { return (_vm.testSlackRecipients = value); }}})],1),_c('form-field',{attrs:{"required":""}},[_c('form-label',[_vm._v(" Example Enrollee "),_c('i',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"fas fa-info-circle",attrs:{"content":"Fills in the personalization tokens with this person's information.\n                They will not be notified."}})]),_c('form-dropdown',{attrs:{"name":"Example Enrollee","required":"","append-to-body":"","placement":"bottom","value":_vm.exampleEnrolleeId,"placeholder":"Select Person","options":_vm.users,"label":"fullName","reduced-field":"id","searchable":""},on:{"update":function (value) { return (_vm.exampleEnrolleeId = value); }}})],1)]}}],null,false,106978576)})],1),_c('modal-actions',{attrs:{"space-between":""}},[_c('form-btn',{attrs:{"variant":"default","type":"reset","form-id":("send-test-message-" + (_vm.action.id))}},[_vm._v(" Cancel ")]),_c('form-btn',{attrs:{"loading":_vm.sendingMessages,"variant":"primary","type":"submit","invalid":!_vm.isSlackMessageFormValid ||
          !_vm.testSlackRecipients.length ||
          !_vm.exampleEnrolleeId,"form-id":("send-test-message-" + (_vm.action.id))}},[_vm._v(" Send to Slack ")])],1)],1):_vm._e(),(_vm.action.type === 'email')?_c('modal',{key:("test-email-modal-" + (_vm.action.id)),ref:"testEmail",on:{"deny":_vm.resetTestEmailModal}},[_c('modal-header',[_vm._v(" Send Test Email ")]),_c('modal-content',[_c('g-form',{attrs:{"id":("send-test-email-" + (_vm.action.id))},on:{"submit":_vm.sendTestEmail},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var valid = ref.valid;
return [_c('form-validation',{attrs:{"valid":valid},on:{"update":function (value) { return (_vm.isEmailFormValid = value); }}}),_c('form-field',{attrs:{"required":""}},[_c('form-label',[_vm._v(" Send To "+_vm._s(_vm.action.sendToRecipientPersonalEmail ? 'Personal Email' : 'Work Email')+" ")]),_c('form-dropdown',{attrs:{"name":"People","required":"","searchable":"","options":_vm.users,"label":"fullName","secondary-label":_vm.action.sendToRecipientPersonalEmail
                  ? 'personalEmail'
                  : 'email',"secondary-label-warning":"No Email","placeholder":"Select Person","value":_vm.testEmailRecipient},on:{"update":function (value) { return (_vm.testEmailRecipient = value); }}})],1),_c('form-field',{attrs:{"required":""}},[_c('form-label',[_vm._v(" Example Enrollee "),_c('i',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"fas fa-info-circle",attrs:{"content":"Fills in the personalization tokens with this person's information.\n                They will not be notified."}})]),_c('form-dropdown',{attrs:{"name":"Example Enrollee","required":"","append-to-body":"","placement":"bottom","value":_vm.exampleEnrolleeId,"placeholder":"Select Person","options":_vm.users,"label":"fullName","reduced-field":"id","searchable":""},on:{"update":function (value) { return (_vm.exampleEnrolleeId = value); }}})],1),_c('checkbox',{attrs:{"initial-value":_vm.includeCarbonCopies,"name":"Send to cc"},on:{"update":function (value) { return (_vm.includeCarbonCopies = value); }}},[_vm._v(" Send to CC'ed people ")])]}}],null,false,2533439662)})],1),_c('modal-actions',{attrs:{"space-between":""}},[_c('form-btn',{attrs:{"variant":"default","type":"reset","form-id":("send-test-email-" + (_vm.action.id))}},[_vm._v(" Cancel ")]),_c('form-btn',{attrs:{"loading":_vm.sendingEmails,"variant":"primary","type":"submit","invalid":!_vm.isEmailFormValid || !_vm.testEmailRecipient || !_vm.exampleEnrolleeId,"form-id":("send-test-email-" + (_vm.action.id))}},[_vm._v(" Send Email ")])],1)],1):_vm._e(),(_vm.action.type === 'meeting')?_c('modal',{key:("test-meeting-modal-" + (_vm.action.id)),ref:"testMeeting",on:{"deny":_vm.resetTestMeetingModal}},[_c('modal-header',[_vm._v(" Create Test Meeting ")]),_c('modal-content',[_c('g-form',{attrs:{"id":("create-test-meeting-" + (_vm.action.id))},on:{"submit":_vm.createTestMeeting},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var valid = ref.valid;
return [_c('form-validation',{attrs:{"valid":valid},on:{"update":function (value) { return (_vm.isMeetingFormValid = value); }}}),_c('form-field',{attrs:{"required":""}},[_c('form-label',[_vm._v(" Send meeting invite to")]),_c('form-dropdown',{attrs:{"name":"Example Attendee","required":"","append-to-body":"","placement":"bottom","value":_vm.exampleAttendeeId,"placeholder":"Select Person","options":_vm.users,"label":"fullName","reduced-field":"id","searchable":""},on:{"update":function (value) { return (_vm.exampleAttendeeId = value); }}})],1),_c('form-field',{attrs:{"required":""}},[_c('form-label',[_vm._v(" Example Enrollee "),_c('i',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"fas fa-info-circle",attrs:{"content":"Fills in the personalization tokens with this person's information.\n                They will not be notified."}})]),_c('form-dropdown',{attrs:{"name":"Example Enrollee","required":"","append-to-body":"","placement":"bottom","value":_vm.exampleEnrolleeId,"placeholder":"Select Person","options":_vm.users,"label":"fullName","reduced-field":"id","searchable":""},on:{"update":function (value) { return (_vm.exampleEnrolleeId = value); }}})],1),_vm._v(" Meeting will be scheduled today "+_vm._s(_vm.formatTime(_vm.action.relativeMeetingStartTime))+" - "+_vm._s(_vm.formatTime(_vm.action.relativeMeetingEndTime))+" on "+_vm._s(_vm.calendarOwner ? _vm.calendarOwner.preferredFirstName : 'N/A')+"'s "),_c('em',[_vm._v("Gather Meetings")]),_vm._v(" calendar ")]}}],null,false,3646859486)})],1),_c('modal-actions',{attrs:{"space-between":""}},[_c('form-btn',{attrs:{"variant":"default","type":"reset","form-id":("create-test-meeting-" + (_vm.action.id))}},[_vm._v(" Cancel ")]),_c('form-btn',{attrs:{"loading":_vm.creatingMeeting,"variant":"primary","type":"submit","invalid":!_vm.isMeetingFormValid ||
          !_vm.calendarOwner ||
          !_vm.exampleEnrolleeId ||
          !_vm.exampleAttendeeId,"form-id":("create-test-meeting-" + (_vm.action.id))}},[_vm._v(" Create Meeting ")])],1)],1):_vm._e()],1):_c('flex',{staticClass:"action-type-moment",attrs:{"align":"center","gap":"1rem"}},[_c('moment-icon',{attrs:{"hex-color":_vm.moment.color,"icon":_vm.moment.icon,"size":"large"}}),_vm._v(" "+_vm._s(_vm.title)+" ")],1)}
var staticRenderFns = []

export { render, staticRenderFns }