<template>
  <loading v-if="pageLoading" :loading="pageLoading" />
  <container v-else>
    <flex align="center" justify="space-between">
      <flex align="center">
        <g-menu class="pointer">
          <menu-item
            :active="!showTaskOnlyView"
            @click.native="() => showTaskView(false)"
          >
            Date View
          </menu-item>
          <menu-item
            :active="showTaskOnlyView"
            @click.native="() => showTaskView(true)"
          >
            Checklist View
          </menu-item>
        </g-menu>
      </flex>

      <flex
        v-show="showReminderSelection && !notice"
        align="center"
        justify="flex-end"
        gap="0.5rem"
      >
        <btn
          variant="default"
          @click.native="() => (showReminderSelection = false)"
        >
          Cancel
        </btn>
        <btn
          :disabled="!selectedUserTasks.length"
          @click.native="$refs.modal.show()"
        >
          Create Reminder Message
        </btn>
        <i
          v-tippy
          class="fas fa-info-circle"
          content="Select Tasks to Send Reminders for"
        />
      </flex>

      <flex v-show="!showReminderSelection" align="center" gap="0.5rem">
        <user-action-status-legend />
        <btn
          v-show="showTaskOnlyView"
          @click.native="() => (showReminderSelection = true)"
        >
          Send Reminders
        </btn>
      </flex>
    </flex>

    <div v-if="showTaskOnlyView" class="table-container">
      <timeline-table
        v-if="!notice"
        type="checklist"
        :organization="organization"
        :user-actions="userActions"
        :moment="moment"
        :sections="checklistSections"
        :tasks="tasks"
        :user-tasks="userTasks"
        :enrollees="enrollees"
        :users="users"
        :actions="actions"
        :aspects="aspects"
        :user-aspects="userAspects"
        :recipients="recipients"
        :relationships="relationships"
        :action-tasks="actionTasks"
        :show-reminder-selection="showReminderSelection"
        @reminders-added="(value) => (selectedUserTasks = value)"
        @get-user-actions="getUserActions"
      />
    </div>
    <div v-else class="table-container">
      <timeline-table
        v-if="!noWorkflowSteps && !notice"
        type="date"
        :organization="organization"
        :user-actions="userActions"
        :moment="moment"
        :sections="checklistSections"
        :tasks="tasks"
        :user-tasks="userTasks"
        :enrollees="enrollees"
        :users="users"
        :actions="actions"
        :aspects="aspects"
        :user-aspects="userAspects"
        :recipients="recipients"
        :relationships="relationships"
        :action-tasks="actionTasks"
        @get-user-actions="getUserActions"
      />
    </div>

    <placeholder v-if="notice" vertically-aligned centered light>
      {{ (notice || {}).content }}
    </placeholder>

    <modal ref="modal" size="tiny">
      <modal-header> Send Reminders </modal-header>
      <modal-content>
        <p>
          Send reminders for:
          <strong>
            {{ selectedUserTasks.length }}
            {{ selectedUserTasks.length > 1 ? 'Tasks' : 'Task' }}
          </strong>
        </p>
        <g-form id="send-reminders" @submit="sendReminders">
          <form-field>
            <form-label> Reminder Message </form-label>
            <text-editor
              v-model="reminderBody"
              name="Reminder Message"
              :aspects="aspects"
            />
          </form-field>
        </g-form>
      </modal-content>
      <modal-actions space-between>
        <form-btn variant="default" type="reset" form-id="send-reminders">
          Cancel
        </form-btn>

        <form-btn
          :loading="sendingReminders"
          variant="primary"
          type="submit"
          :invalid="!reminderBody"
          form-id="send-reminders"
        >
          Send
        </form-btn>
      </modal-actions>
    </modal>
  </container>
</template>

<script>
import { uniqBy } from 'lodash'

import { api } from '@/api'
import { toast } from '@/toasts'

import container from '@/components/v2/container.vue'
import placeholder from '@/components/v2/placeholder.vue'
import modal from '@/components/v2/modal/modal.vue'
import modalHeader from '@/components/v2/modal/header.vue'
import modalActions from '@/components/v2/modal/actions.vue'
import modalContent from '@/components/v2/modal/content.vue'
import gForm from '@/components/v2/form/form.vue'
import formField from '@/components/v2/form/field.vue'
import textEditor from '@/components/v2/text_editor.vue'
import formBtn from '@/components/v2/form/btn.vue'
import formLabel from '@/components/v2/form/label.vue'
import loading from '@/components/v2/loading.vue'
import btn from '@/components/v2/btn.vue'
import flex from '@/components/v2/flex.vue'
import gMenu from '@/components/v2/menu/menu.vue'
import menuItem from '@/components/v2/menu/item.vue'

import timelineTable from '@/views/v2/moment/timeline_table.vue'

import userActionStatusLegend from '@/components/UserActionStatusLegend.vue'

import {
  notificationTimelineNoUsers,
  notificationTimelineNoActions,
  notificationTimelineNoTasks,
  notificationTimelineNoTrigger,
} from '@/assets/js/notifications'

export default {
  components: {
    container,
    placeholder,
    modal,
    modalActions,
    modalContent,
    modalHeader,
    gForm,
    formField,
    textEditor,
    formBtn,
    formLabel,
    timelineTable,
    loading,
    flex,
    btn,
    userActionStatusLegend,
    gMenu,
    menuItem,
  },
  props: {
    moment: {
      type: Object,
      required: true,
    },
    actions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedUserTasks: [],
      showTaskOnlyView: false,
      reminderBody:
        '<p>👋Hey <span class="mention" data-mention="" ' +
        'data-id="Recipient First Name"' +
        'data-label="Recipient First Name" contenteditable="false">' +
        '@Recipient First Name</span> ' +
        "— Just a reminder you've been assigned the following tasks for " +
        '<span class="mention" data-mention="" data-id="Enrollee First Name"' +
        'data-label="Enrollee First Name" contenteditable="false">' +
        '@Enrollee First Name</span>: </p>',
      showReminderSelection: false,
      pageLoading: false,
      sendingReminders: false,
      slackIntegration: null,
      slackIntegrationLoading: false,
      userActions: [],
      userActionsLoading: false,
      aspects: [],
      aspectsLoading: false,
      enrollees: [],
      enrolleesLoading: false,
      actionsLoading: false,
      tasks: [],
      tasksLoading: false,
      checklistSections: [],
      checklistSectionsLoading: false,
      userAspects: [],
      userAspectsLoading: false,
      recipients: [],
      recipientsLoading: false,
      relationships: [],
      relationshipsLoading: false,
      users: [],
      usersLoading: false,
      actionTasks: [],
      actionTasksLoading: [],
      organization: null,
    }
  },
  computed: {
    userTasks() {
      return this.userActions.flatMap((userAction) => userAction.userTasks)
    },
    // Empty State Handling
    noTasks() {
      return !this.tasks.length
    },
    noEnrollees() {
      return !this.enrollees.length
    },
    noWorkflowSteps() {
      return !this.actions.length
    },
    noTrigger() {
      if (this.moment.type === 'date') {
        return !this.moment.triggerDate
      }
      if (this.moment.type === 'aspect') {
        return !this.moment.aspectId
      }
      if (this.moment.type === 'work_anniversary') {
        return false
      }
      if (this.moment.type === 'birthday') {
        return false
      }
      if (this.moment.type === 'end_date') {
        return false
      }
      if (this.moment.type === 'start_date') {
        return false
      }

      throw new Error(`Unknown moment type ${this.moment.type}`)
    },
    notice() {
      if (this.noWorkflowSteps) {
        return notificationTimelineNoActions
      }
      if (this.noTrigger) {
        return notificationTimelineNoTrigger
      }
      if (this.showTaskOnlyView && this.noTasks) {
        return notificationTimelineNoTasks
      }
      if (this.noEnrollees) {
        return notificationTimelineNoUsers
      }
      return null
    },
  },
  watch: {
    $route() {
      if (this.$route.hash === '#timeline') {
        this.getPage()
      }
    },
    moment(newValue, oldValue) {
      if (
        this.$route.hash === '#timeline' &&
        (newValue || {}).isActive !== (oldValue || {}).isActive
      ) {
        this.getPage()
      }
    },
  },
  mounted() {
    if (this.$route.hash === '#timeline') {
      this.getPage()
    }
  },
  methods: {
    async getPage() {
      this.pageLoading = true
      await this.getSlackIntegration()
      await this.getRecipients()
      await this.getUserActions()
      await this.getAspects()
      await this.getEnrollees()
      await this.getTasks()
      await this.getChecklistSections()
      await this.getUserAspects()
      await this.getRelationships()
      await this.getUsers()
      await this.getActionTasks()
      await this.getOrganization()
      this.pageLoading = false
    },
    async getChecklistSections() {
      try {
        this.checklistSectionsLoading = true
        this.checklistSections = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${this.moment.id}/checklist-sections`,
            {
              sort: 'id',
            }
          )
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.checklistSectionsLoading = false
      }
    },
    async getTasks() {
      let response
      this.tasksLoading = true
      this.tasks = []
      while (!response || (response.cursor && response.cursor.nextPage)) {
        try {
          // eslint-disable-next-line no-await-in-loop
          response = await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/tasks`,
            {
              momentId: this.moment.id,
              page: parseInt((response && response.cursor.nextPage) || 1, 10),
              sort: 'checklistSectionId,sendDate,sendTime,id',
            }
          )
          this.tasks = this.tasks.concat(response.data)
        } catch (error) {
          toast.error(error)
          break
        }
      }
      this.tasksLoading = false
    },
    async getEnrollees() {
      const sort = {
        date: 'preferredFirstName,lastName,id',
        aspect: 'preferredFirstName,lastName,id',
        work_anniversary: 'startDateMMDD,preferredFirstName,lastName,id',
        birthday: 'birthdayMMDD,preferredFirstName,lastName,id',
        end_date: 'endDate-desc,preferredFirstName,lastName,id',
        start_date: 'startDate-desc,preferredFirstName,lastName,id',
        default: 'preferredFirstName,lastName,id',
      }
      this.enrolleesLoading = true
      this.enrollees = []
      await this.getUserEnrollees(sort[this.moment.type] || sort.default)
      await this.getGroupEnrollees(sort[this.moment.type] || sort.default)
      this.enrolleesLoading = false
    },
    async getUserEnrollees(sortOrder) {
      let response
      while (!response || (response.cursor && response.cursor.nextPage)) {
        try {
          // eslint-disable-next-line no-await-in-loop
          response = await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${this.moment.id}/enrollees/individuals`,
            {
              page: (response && response.cursor.nextPage) || 1,
              hideInactiveUsers: 1,
              sort: sortOrder,
            }
          )
          this.enrollees = uniqBy(
            this.enrollees.concat(
              response.data.map((data) => ({
                ...data.user,
                ...{ enrolledAt: data.createdAt },
              }))
            ),
            'id'
          )
        } catch (error) {
          toast.error(error)
          break
        }
      }
    },
    async getGroupEnrollees(sortOrder) {
      let groups = []
      let groupEnrolleesResponse
      while (
        !groupEnrolleesResponse ||
        (groupEnrolleesResponse.cursor &&
          groupEnrolleesResponse.cursor.nextPage)
      ) {
        try {
          // eslint-disable-next-line no-await-in-loop
          groupEnrolleesResponse = await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${this.moment.id}/enrollees/groups`,
            {
              page:
                (groupEnrolleesResponse &&
                  groupEnrolleesResponse.cursor.nextPage) ||
                1,
            }
          )
          groups = groups.concat(
            groupEnrolleesResponse.data.map((data) => data.group)
          )
        } catch (error) {
          toast.error(error)
          break
        }
      }

      let groupMembersResponse
      for await (const group of groups) {
        while (
          !groupMembersResponse ||
          (groupMembersResponse.cursor && groupMembersResponse.cursor.nextPage)
        ) {
          try {
            // eslint-disable-next-line no-await-in-loop
            groupMembersResponse = await api.get(
              `${process.env.VUE_APP_DB_ENDPOINT}/v2/groups/${group.id}/members`,
              {
                page:
                  (groupMembersResponse &&
                    groupMembersResponse.cursor.nextPage) ||
                  1,
                hideInactiveUsers: 1,
                sort: sortOrder,
              }
            )
            this.enrollees = uniqBy(
              this.enrollees.concat(
                groupMembersResponse.data.map((data) => ({
                  ...data.user,
                  ...{ enrolledAt: group.createdAt },
                }))
              ),
              'id'
            )
          } catch (error) {
            toast.error(error)
            break
          }
        }
      }
    },
    async getSlackIntegration() {
      try {
        this.slackIntegrationLoading = true
        this.slackIntegration = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/organizations/${this.$store.state.organizationId}/slack-integration`
          )
        ).data
      } catch (error) {
        if (error.code !== 404) {
          toast.error(error)
        }
      } finally {
        this.slackIntegrationLoading = false
      }
    },
    async getRecipients() {
      try {
        this.recipientsLoading = true
        this.recipients = (
          await Promise.all(
            this.actions.flatMap(
              async (action) =>
                (
                  await api.get(
                    `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${action.id}/recipients`
                  )
                ).data
            )
          )
        ).flat()
      } catch (error) {
        toast.error(error)
      } finally {
        this.recipientsLoading = false
      }
    },
    async getUserActions() {
      try {
        this.userActionsLoading = true
        this.userActions = await api.get(
          `${process.env.VUE_APP_DB_ENDPOINT}/userActions`,
          {
            organizationId: this.$store.state.organizationId,
            actionIds: this.actions.map((action) => action.id),
          }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.userActionsLoading = false
      }
    },
    async getAspects() {
      try {
        this.aspectsLoading = true
        this.aspects = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/aspects`, {
            isEditable: 1,
          })
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.aspectsLoading = false
      }
    },
    async getUserAspects() {
      // moments that have no associated aspect id don't need user aspects
      if (!this.moment.aspectId) {
        return
      }
      let response
      this.userAspectsLoading = true
      this.userAspects = []
      while (!response || (response.cursor && response.cursor.nextPage)) {
        try {
          // eslint-disable-next-line no-await-in-loop
          response = await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/user-aspects`,
            {
              aspectId: this.moment.aspectId,
              page: parseInt((response && response.cursor.nextPage) || 1, 10),
              isEditable: 1,
            }
          )
          this.userAspects = this.userAspects.concat(response.data)
        } catch (error) {
          toast.error(error)
          break
        }
      }
      this.userAspectsLoading = false
    },
    async getRelationships() {
      let response
      this.relationshipsLoading = true
      this.relationships = []
      while (!response || (response.cursor && response.cursor.nextPage)) {
        try {
          // eslint-disable-next-line no-await-in-loop
          response = await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/relationships`,
            {
              page: parseInt((response && response.cursor.nextPage) || 1, 10),
            }
          )
          this.relationships = this.relationships.concat(response.data)
        } catch (error) {
          toast.error(error)
          break
        }
      }
      this.relationshipsLoading = false
    },
    async getUsers() {
      let response
      this.users = []
      this.usersLoading = true
      while (!response || response.cursor.nextPage) {
        try {
          // eslint-disable-next-line no-await-in-loop
          response = await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/users`,
            {
              page: parseInt((response && response.cursor.nextPage) || 1, 10),
              status: ['active', 'onboarding'],
            }
          )
          this.users = this.users.concat(response.data)
        } catch (error) {
          toast.error(error)
          break
        }
      }
      this.usersLoading = false
    },
    async getActionTasks() {
      try {
        this.actionTasksLoading = true
        this.actionTasks = await Promise.all(
          this.actions.flatMap(async (action) => {
            const tasks = (
              await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/tasks`, {
                actionId: action.id,
              })
            ).data
            return { action, tasks }
          })
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.actionTasksLoading = false
      }
    },
    async getOrganization() {
      try {
        this.organization = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/organizations/${this.$store.state.organizationId}`
          )
        ).data
      } catch (error) {
        toast.error(error)
      }
    },
    async sendReminders() {
      try {
        this.sendingReminders = true
        await Promise.all(
          this.selectedUserTasks.map(async (userTask) => {
            await api.post(
              `${process.env.VUE_APP_DB_ENDPOINT}/v2/rpcs/task/send-reminder`,
              { message: this.reminderBody, userTaskId: userTask.id }
            )
          })
        )
      } catch (error) {
        toast.error(error)
      } finally {
        toast.success(
          `Your ${
            this.selectedUserTasks.length > 1
              ? 'reminders were'
              : 'reminder was'
          } sent`
        )
        this.sendingReminders = false
        this.reminderBody = null
      }

      this.showReminderSelection = false
      this.selectedUserTasks = []
    },
    showTaskView(isShowing) {
      this.showTaskOnlyView = isShowing
      this.showReminderSelection = false
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/colors.less';
@import '~@/assets/less/text.less';
@import '~@/assets/less/borders.less';

.table-container {
  max-height: 70vh;
  margin-top: 1rem;
  margin-right: -1px;
  overflow: auto;
  white-space: nowrap;
}

.card-square {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7em;
  height: 7em;
  border-radius: @big-border-radius;

  .icon {
    margin: 0;
  }
}

.notice {
  margin-top: 2em;
}
</style>
