module.exports = {
  // Global Notifications (Imported into Dashboard Notification Component)
  notificationOnboardingAddPeople: {
    type: 'general',
    emoji: '👫',
    header: 'Add Your Team',
    content:
      'Import from Slack, a spreadsheet, or an HR Platform. You can also always add manually.',
    isCta: true,
    ctaText: 'See Import Options',
    ctaEmit: 'push-to-people-info-import',
  },

  // Onboarding Nudge Notifications
  notificationOnboardingCustomizeMessage: {
    type: 'general',
    emoji: '✍',
    header: 'Customize a Message',
    content:
      'Make it feel right for your team. Try adding personalization tokens or a button.',
    isCta: true,
    ctaText: 'Edit Message',
    ctaEmit: 'open-first-action',
  },
  notificationOnboardingAddToSlack: {
    type: 'general',
    emoji: '📲',
    header: 'Add Gather to Slack',
    content: 'Connect to Slack before you start sending messages.',
    // TODO: Change back when testing step is added
    // content: 'Connect to Slack to test your workflow.',
    isCta: true,
    ctaText: 'Go to Integrations',
    ctaEmit: 'push-to-slack-integration',
  },
  notificationOnboardingTestWorkflow: {
    type: 'general',
    emoji: '👆',
    header: 'Test Your Workflow',
    content: 'Send test messages to yourself or a teammate.',
  },
  notificationOnboardingPreview: {
    type: 'general',
    emoji: '🔍',
    header: 'Preview Your Workflow',
    content: 'See what messages will be sent before activating your workflow.',
  },
  // TEMP: Remove when onboarding preview is live
  notificationOnboardingActivateWorkflowNoCTA: {
    type: 'general',
    emoji: '👆',
    header: 'Activate Your Workflow',
    content:
      "Whenever you've crafted your perfect messages and updated your people's info, turn on " +
      'your workflow ',
  },
  notificationOnboardingCreateGroup: {
    type: 'general',
    emoji: '🏘',
    header: 'Create Groups with your people',
    content:
      "We've started you off with a smart group with everyone in it. You can use groups to" +
      ' enroll sets of people in workflows. Examples: NYC Office, Remote Workers, Women’s Group, 2020 New' +
      ' Hires, or Leadership Team.',
    isDismissable: true,
  },
  notificationOnboardingActivateWorkflow: {
    type: 'general',
    emoji: '📣',
    header: 'Activate Your First Workflow',
    content:
      "Whenever you've crafted your perfect messages and updated your people's info, turn on " +
      'your workflow to send messages ',
    isCta: true,
    ctaText: 'Preview Workflow',
    ctaEmit: 'push-to-first-moment',
  },
  notificationOnboardingCreateRelationship: {
    type: 'general',
    emoji: '🤝',
    header: 'Create Relationships with your people',
    content:
      "We've started you off with a relationship from your example workflow. You can use relationships to" +
      ' connect two people. Examples: Manager, Mentor, Coffee Buddy, or Department Head.',
    isDismissable: true,
  },

  // Topic Specific Notifications

  notificationActivatingWithoutTrigger: {
    type: 'error',
    emoji: '📅',
    header: 'Add trigger before activating workflow',
    isDismissible: true,
  },
  notificationActivatingWithoutEnrollees: {
    type: 'error',
    emoji: '👫',
    header: 'Add enrollees before activating workflow',
    isCta: true,
    ctaText: 'Add Enrollee',
    ctaEmit: 'open-edit-modal',
    isDismissible: true,
  },
  notificationActivatingWithoutActions: {
    type: 'error',
    emoji: '📭',
    header: 'Add messages before activating workflow',
    content: 'Click the plus icon in the timeline',
    isDismissible: true,
  },
  notificationActivatingWithoutMessageBody: {
    type: 'error',
    emoji: '📭',
    header: 'Add content to all messages before activating',
    content: "There is a message in the workflow that doesn't have any content",
    isDismissible: true,
  },
  notificationActivatingWithoutMessageRecipient: {
    type: 'error',
    emoji: '📭',
    header: 'Add a message recipient to all messages before activating',
    content: "There is a message in the workflow that doesn't have a recipient",
    isDismissible: true,
  },

  // Moment Testing Error
  notificationTestMessageError: {
    type: 'error',
    header: 'Test Message Failed in Slack',
    content:
      'There was an issue connecting to Slack. Check that Slack is connected then try again.',
    isDismissible: true,
  },
  // Slack Integration Removed Error
  notificationSlackMissing: {
    type: 'error',
    header: 'Slack Has Been Disconnected',
    content:
      'There was an issue connecting to Slack. Check that Slack is connected then try again.',
    isDismissible: true,
  },

  // Groups
  notificationNoPeopleInGroup: {
    type: 'general',
    emoji: '👨‍👩‍👧‍👦',
    header: 'Add some people to finish creating your group',
    isCta: true,
    ctaText: '',
    ctaEmit: 'open-edit-group-modal',
    isDismissible: false,
  },

  // date is being set in the past
  notificationMomentTriggerIsPast: {
    type: 'error',
    header: 'Change your trigger date',
    content:
      'Your trigger date has already passed, try changing it to a future date',
    isDismissible: true,
  },

  // Empty States
  notificationTimelineNoUsers: {
    type: 'general',
    emoji: '🧍‍♂️',
    header: 'Add Enrollees',
    content:
      'Enroll someone in this workflow then use this view to monitor their progress',
    isCta: true,
    ctaText: 'Go to Workflow',
    ctaEmit: 'redirect-to-workflow-page',
  },

  notificationTimelineNoActions: {
    type: 'general',
    emoji: '🪜',
    header: 'Add Workflow Steps',
    content:
      'Add a step to your workflow by clicking the (+) before you can monitor the workflow progress',
    isCta: true,
    ctaText: 'Go to Workflow',
    ctaEmit: 'redirect-to-workflow-page',
  },

  notificationTimelineNoTasks: {
    type: 'general',
    emoji: '☑️',
    header: 'Add a Checklist',
    content:
      'Add tasks to the workflow then use this view to monitor what has been completed',
    isCta: true,
    ctaText: 'Go to Checklist',
    ctaEmit: 'redirect-to-checklist-page',
  },

  notificationTimelineNoTrigger: {
    type: 'general',
    emoji: '⚡️',
    header: 'Add a Trigger',
    content: 'Add a trigger before you can monitor workflow progress',
    isCta: true,
    ctaText: 'Go to Workflow',
    ctaEmit: 'redirect-to-workflow-page',
  },
}
