var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-description',[_c('flex',{attrs:{"direction":"column","gap":"0.5rem"}},[_c('flex',{attrs:{"direction":"column","gap":"0.5rem"}},[_c('strong',[_vm._v("Channel Name")]),_c('flex',{attrs:{"justify":"space-between","align":"center"}},[_c('flex',[_c('dropdown',{attrs:{"name":"Channel Privacy","icon":_vm.action.isPrivateChannel ? 'fas fa-lock' : 'fas fa-hashtag',"button":"","basic":"","button-variant":"primary","hide-caret":"","tooltip":"Set Channel Privacy","initial-value":_vm.action.isPrivateChannel,"loading":_vm.patchingField === 'isPrivateChannel'},on:{"update":function (value) { return _vm.patchAction('isPrivateChannel', value); }}},[_c('dropdown-item',{attrs:{"value":false}},[_c('i',{staticClass:"fas fa-hashtag"}),_vm._v(" Public Channel ")]),_c('dropdown-item',{attrs:{"value":true}},[_c('i',{staticClass:"fas fa-lock"}),_vm._v(" Private Channel ")])],1),_c('editable-html',{staticClass:"message-content",attrs:{"value":_vm.action.channelName || _vm.DEFAULT_CHANNEL_NAME,"loading":_vm.patchingField === 'channelName',"placeholder":"Add Channel Name"},on:{"update":function (value) { return _vm.patchAction('channelName', value); }}})],1),_c('personalization-token-dropdown',{ref:"personalization",attrs:{"icon":"far fa-address-card","inline":"","aspects":_vm.aspects,"loading":_vm.patchingField === 'channelName'},on:{"update":_vm.insertChannelToken,"patch-action":function (ref) {
              var field = ref.field;
              var value = ref.value;

              return _vm.patchAction(field, value);
}}})],1)],1),_c('recipient-group',{attrs:{"title":"People to Add","placeholder":"⚠️ Click + to add people to this channel","recipients":_vm.recipients,"action":_vm.action,"users":_vm.users,"groups":_vm.groups,"relationships":_vm.relationships,"slack-conversations":_vm.slackConversations,"hide-channels":"","loading":_vm.loading},on:{"get-recipients":function () { return _vm.$emit('get-recipients'); },"get-moment-status":function () { return _vm.$emit('get-moment-status'); },"patch-action":function (ref) {
              var field = ref.field;
              var value = ref.value;

              return _vm.patchAction(field, value);
}}}),_c('checkbox',{staticClass:"action-checkbox",attrs:{"initial-value":!!_vm.action.archiveDate,"name":"Automatic Archival"},on:{"update":function (value) { return _vm.setAutoArchiveForChannel(value); }}},[_vm._v(" Automatically Archive Channel ")]),(!!_vm.action.archiveDate)?_c('flex',{attrs:{"direction":"column"}},[_c('strong',[_vm._v("Archive Date & Time "),_c('i',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"fas fa-info-circle",attrs:{"content":"This is relative to the trigger date (e.g. Start Date), not the creation of the channel"}})]),_c('flex',{attrs:{"gap":"1rem","wrap":"wrap","align":"center"}},[_c('delta-picker',{attrs:{"delta":_vm.action.archiveDate,"loading":_vm.patchingField === 'archiveDate',"start":_vm.action.relativeDate},on:{"update":function (value) { return _vm.patchAction('archiveDate', value); }}}),_c('time-picker',{attrs:{"time":_vm.action.archiveTime,"loading":_vm.patchingField === 'archiveTime',"start":_vm.isEqual(_vm.action.archiveDate, _vm.action.relativeDate)
              ? _vm.action.relativeTime
              : null},on:{"update":function (value) { return _vm.patchAction('archiveTime', value); }}})],1)],1):_vm._e(),_c('flex',{attrs:{"direction":"column"}},[_c('btn',{staticClass:"preview-button",attrs:{"size":"small","compact":"","basic":"","variant":"default"},nativeOn:{"click":function($event){return (function () { return (_vm.showPreview = !_vm.showPreview); }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.showPreview ? 'Edit' : 'Close Text Editor')+" ")]),_c('slack-message-preview',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPreview),expression:"showPreview"}],staticClass:"message-preview pointer",attrs:{"action":_vm.action,"organization":_vm.organization},nativeOn:{"click":function($event){return (function () { return (_vm.showPreview = false); }).apply(null, arguments)}}}),_c('text-editor',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showPreview),expression:"!showPreview"}],attrs:{"value":_vm.action.body,"aspects":_vm.aspects,"loading":_vm.patchingField === 'body'},on:{"input":function (value) { return _vm.patchAction('body', value); }}}),_c('button-form',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showPreview),expression:"!showPreview"}],attrs:{"action":_vm.action,"users":_vm.users,"slack-integration":_vm.slackIntegration,"slack-conversations":_vm.slackConversations},on:{"get-actions":function () { return _vm.$emit('get-actions'); }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }