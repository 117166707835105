<template>
  <span>
    <btn
      v-if="moment.isActive"
      inverted
      variant="secondary"
      :loading="loading"
      @click.native="$refs.deactivationModal.show"
    >
      Stop Workflow
    </btn>
    <div v-else-if="moment.isActive === undefined" />
    <btn v-else :loading="loading" @click.native="$refs.activationModal.show">
      Launch Workflow
    </btn>
    <modal ref="activationModal">
      <modal-header>Launch Workflow</modal-header>
      <modal-content>
        <div>
          <flex
            class="modal-big-text"
            direction="column"
            align="center"
            gap="1rem"
          >
            <div v-if="loading">Launching Workflow...</div>
            <div v-else-if="moment.isActive">
              <i class="fas fa-check-circle success" /> Workflow Launched
            </div>

            <div v-if="scheduling">Scheduling Messages...</div>
            <div v-else-if="scheduled">
              <i class="fas fa-check-circle success" /> Messages Scheduled
            </div>

            <div>
              <div v-if="scheduled && moment.isActive">
                <i class="fas fa-paper-plane fa-5x" />
              </div>
              <loading v-else :loading="loading || scheduling" size="massive" />
            </div>
          </flex>

          <p v-if="moment.isActive" class="workflow-congratulations">
            Great job building and launching your workflow! We'll take it from
            here.
          </p>

          <div v-if="!moment.isActive && !loading && !scheduling && !scheduled">
            <div
              v-if="(momentStatus || {}).error && momentStatus.error.message"
              class="warning-alert"
            >
              <div class="modal-big-text">
                <b>
                  <i class="fas fa-exclamation-triangle warning" /> Moment
                  Configuration Issue
                </b>
              </div>
              <p>
                {{ momentStatus.error.message }}
              </p>
            </div>
            <div class="modal-big-text">
              <b>What to expect</b>
            </div>
            <ul>
              <li>
                If the trigger date is in the past, no messages will be
                scheduled.
              </li>
              <li>
                If the trigger date is today or in the future, all messages will
                be scheduled.
              </li>
              <li>
                If a message was scheduled for the past, it will be
                fast-forwarded and sent today.
              </li>
              <li>
                <a
                  href="https://www.notion.so/How-Gather-Schedules-Sends-Messages-4df88657e69c41bb9bc14c8080c61105"
                >
                  Take a look at some examples to understand further.
                </a>
              </li>
              <li>Allow up to 15 minutes for messages to start sending.</li>
            </ul>
            <div>
              <b>Still have questions? Get a workflow review</b>
            </div>
            <btn
              basic
              size="small"
              variant="default"
              @click.native="openZendeskWidget"
            >
              Contact Gather Team
            </btn>
          </div>
        </div>
        <div v-if="!moment.isActive" class="modal-buttons">
          <flex justify="space-between">
            <btn variant="secondary" @click.native="$refs.activationModal.hide">
              Cancel
            </btn>
            <btn
              variant="positive"
              :disabled="!momentStatus.ok"
              @click.native="activateMoment"
            >
              Activate
            </btn>
          </flex>
        </div>
        <div v-else class="modal-buttons">
          <flex gap="1em" direction="column">
            <btn variant="primary" @click.native="seeScheduledMessages">
              See Scheduled Messages
            </btn>
            <btn
              variant="secondary"
              outline
              @click.native="$refs.activationModal.hide"
            >
              Back to Moment
            </btn>
          </flex>
        </div>
      </modal-content>
    </modal>
    <modal ref="deactivationModal">
      <modal-header>Stop Workflow</modal-header>
      <modal-content>
        <div class="modal-big-text">
          <b>What to expect</b>
        </div>
        <ul>
          <li>
            All messages scheduled for today or in the future will be deleted.
          </li>
          <li>
            The history of previously sent messages and completed tasks will be
            preserved.
          </li>
          <li>Incomplete tasks can still be marked complete</li>
        </ul>
        <b>Still have questions? Contact us before stopping</b>
        <p>
          <btn
            basic
            size="small"
            variant="default"
            @click.native="openZendeskWidget"
          >
            Contact Gather Team
          </btn>
        </p>
        <loading :loading="loading" size="massive" />
        <div class="modal-buttons">
          <flex gap="1em" justify="space-between">
            <btn
              variant="secondary"
              @click.native="$refs.deactivationModal.hide"
            >
              Cancel
            </btn>
            <btn variant="negative" @click.native="deactivateMoment">
              Stop Workflow
            </btn>
          </flex>
        </div>
      </modal-content>
    </modal>
  </span>
</template>

<script>
import btn from '@/components/v2/btn.vue'

import loading from '@/components/v2/loading.vue'
import flex from '@/components/v2/flex.vue'
import modal from '@/components/v2/modal/modal.vue'
import modalHeader from '@/components/v2/modal/header.vue'
import modalContent from '@/components/v2/modal/content.vue'

import customerCommunicationsMixin from '@/mixins/customerCommunicationsMixin'

export default {
  components: {
    btn,
    flex,
    modal,
    modalHeader,
    modalContent,
    loading,
  },
  mixins: [customerCommunicationsMixin],
  props: {
    moment: {
      type: Object,
      default: () => {},
    },
    momentStatus: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    scheduling: {
      type: Boolean,
      default: false,
    },
    scheduled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    activateMoment() {
      this.$emit('activate')
    },
    deactivateMoment() {
      this.$emit('deactivate')
      this.$refs.deactivationModal.hide()
    },
    seeScheduledMessages() {
      this.$refs.activationModal.hide()
      this.$router.push({ name: 'activity' })
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';
@import '~@/assets/less/borders.less';
@import '~@/assets/less/text.less';

.success {
  color: @green;
}

.warning {
  color: @warning-yellow;
}

.warning-alert {
  border: @light-border;
  background: @warning-yellow-background;
  padding: 1em;
  margin-bottom: 1em;
}

.modal-big-text {
  font-size: @title-font-size;
}

.modal-buttons {
  margin-top: 1em !important;
}

.workflow-congratulations {
  margin-top: 0.5em;
  text-align: center;
}
</style>
