// ========================================== // Author: @johnwetzel // Purpose:
A legend to communicate the statuses to the user. //
==========================================

<template>
  <div>
    <div class="ui circular label legend-tag" name="legend" arrow>
      <i class="info circle icon legend-icon" />
      <span class="legend-text">Legend</span>
    </div>
    <!-- append-to is linking to the component, this allows css styling on the tippy component -->
    <tippy
      to="legend"
      theme="light no-transparency"
      interactive
      :append-to="() => $el"
    >
      <div class="legend-hover">
        <div class="ui header legend-header">Statuses</div>
        <div class="ui relaxed list">
          <div v-for="key in legend" :key="key.name" class="item">
            <user-action-status-indicator
              :status="key.status"
              :action-type="key.actionType"
            />
            <p class="status-name">
              {{ key.name }}
            </p>
          </div>
        </div>
      </div>
    </tippy>
  </div>
</template>

<script>
import userActionStatusIndicator from '@/components/UserActionStatusIndicator.vue'

export default {
  components: {
    userActionStatusIndicator,
  },
  data() {
    return {
      legend: [
        {
          name: 'Scheduled',
          status: 'scheduled',
          actionType: 'message',
        },
        {
          name: 'Sent',
          status: 'sent',
          actionType: 'message',
        },
        {
          name: 'Task Incomplete',
          status: 'sent',
          actionType: 'task',
        },
        {
          name: 'Task Complete',
          status: 'complete',
          actionType: 'task',
        },
        {
          name: 'Skipped',
          status: 'skipped',
          actionType: 'task',
        },
        {
          name: 'Overdue',
          status: 'overdue',
          actionType: 'task',
        },
        // {
        //   name: 'Blocked',
        //   status: 'blocked',
        //   actionType: 'task'
        // },
        {
          name: 'Issue',
          status: 'error',
          actionType: 'task',
        },
      ],
    }
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/colors.less';
@import '~@/assets/less/text.less';

.ui.label {
  &.legend-tag {
    padding: 0.8em 0.5em !important; // override fomantic style
    font-size: @body-font-size;
    cursor: pointer;
    outline: none;
  }

  .legend-text {
    margin-right: 0.4em;
    font-weight: bold;
  }

  .legend-icon {
    padding-right: 0.5em;
    margin: 0;
  }
}

.tippy-content {
  .legend-hover {
    padding: 0.5em;
    text-align: left;

    .legend-header {
      margin-bottom: 0.5em;
    }

    .ui.list {
      margin-top: 0;

      .item {
        display: flex;
        align-items: center;
        font-size: @body-font-size;

        .status-name {
          margin-left: 0.5em;
        }
      }
    }
  }
}

* ::v-deep.tippy-tooltip.no-transparency-theme[data-animatefill] {
  background-color: #fff; // override tippy light theme slightly to remove transparency
}
</style>
