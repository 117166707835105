<template>
  <card class="message-content preview">
    <card-body class="preview-body">
      <grid class="preview-body-grid">
        <grid-column one class="gather-logo">
          <g-image
            :src="gatherAppImage"
            size="mini"
            rounded
            alt="Gather logo"
          />
        </grid-column>
        <grid-column fourteen>
          <flex align="center">
            <div class="message-sender">Gather</div>
            <div class="app-badge">APP</div>
            <div class="timestamp">
              {{
                formatTime(action.relativeTime, {
                  timeZone: organization ? organization.timezone : '',
                })
              }}
            </div>
          </flex>
          <span class="section-block" v-html="action.body" />
          <a
            v-if="
              action.buttonUrl && action.buttonName && action.type !== 'task'
            "
            :href="action.buttonUrl"
            target="_blank"
            class="slack-button-container"
            @click.stop
          >
            <btn basic variant="default" class="slack-button">
              {{ action.buttonName }}
            </btn>
          </a>
          <div
            v-else-if="
              !action.buttonUrl && action.buttonName && action.type !== 'task'
            "
            class="slack-button-container"
            @click="showNoUrlAlert"
          >
            <btn basic variant="default" class="slack-button">
              {{ action.buttonName }}
            </btn>
            <br />
            <small class="button-error">{{ noUrlError }}</small>
          </div>
          <flex
            v-if="action.type === 'task'"
            direction="column"
            class="section-block"
          >
            <div v-for="task in tasks" :key="task.id" class="task-section">
              <div class="divider" />
              <p class="task-name message-copy">
                {{ task.name }}
              </p>
              <p class="message-copy scrollable" v-html="task.description" />
              <btn basic variant="default" class="slack-button complete-button">
                ✅ Mark Complete
              </btn>
              <btn
                v-if="!task.isRequired"
                basic
                variant="default"
                class="slack-button"
              >
                Skip
              </btn>
              <p class="details message-copy task-requirement">
                {{ formatTaskDeadline(task) }}
              </p>
            </div>
          </flex>
        </grid-column>
      </grid>
    </card-body>
  </card>
</template>

<script>
import gatherAppImage from '@/assets/img/gather_g_small.png'
import defaultProfileImage from '@/assets/img/profile_avatar_small.png'
import dateMixin from '@/mixins/v2/dateMixin'

import card from '@/components/v2/card/card.vue'
import cardBody from '@/components/v2/card/body.vue'
import btn from '@/components/v2/btn.vue'
import flex from '@/components/v2/flex.vue'
import gImage from '@/components/v2/image.vue'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'

export default {
  components: {
    card,
    cardBody,
    btn,
    flex,
    gImage,
    grid,
    gridColumn,
  },
  mixins: [dateMixin],
  props: {
    action: {
      type: Object,
      required: true,
    },
    tasks: {
      type: Array,
      default: () => [],
    },
    organization: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      gatherAppImage,
      defaultProfileImage,
      noUrlError: '',
    }
  },
  methods: {
    formatTaskDeadline(task) {
      if (!task.isRequired) {
        return 'Optional'
      }

      return `Required | Deadline: ${this.formatDynamicDate(
        task.deadline,
        null,
        null,
        new Date()
      )}`
    },
    showNoUrlAlert() {
      this.noUrlError =
        "Sorry, that button doesn't work here! Try using Test Workflow to see it in action."
      setTimeout(() => {
        this.noUrlError = ''
      }, 3000)
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/colors.less';
@import '~@/assets/less/text.less';
@import '~@/assets/less/message-content.less';
@import '~@/assets/less/borders.less';

.preview {
  margin: 0;
}

.gather-logo {
  padding-right: 2em !important;
}

.preview-body {
  width: 100%;
  font-family: @slack-font;
  border-radius: @standard-border-radius;

  .preview-body-grid {
    flex-wrap: nowrap;
  }

  ::v-deep.content {
    ::v-deep p {
      margin: @message-p-margin;
    }

    ::v-deep p:empty {
      height: 8px;
    }

    ::v-deep.message-copy {
      margin: 0;
    }

    .scrollable {
      max-height: 15em;
      overflow-y: auto;
    }
  }

  a {
    color: @slack-link;
  }

  .preview-img {
    width: 36px;
    height: 36px;
    border-radius: @slack-in-message-border-radius;
  }

  .message-sender {
    font-size: @slack-font-size;
    font-weight: 700;
    color: @slack-black;
  }

  .app-badge {
    display: inline-block;
    height: 14px;
    padding: 1px 3px;
    margin: 0 4px 0 4px;
    font-size: @slack-app-badge-font-size;
    font-weight: 700;
    line-height: 1.25;
    color: @slack-text-grey;
    vertical-align: 0.125rem;
    background-color: @slack-light-grey;
    border-radius: @slack-app-badge-border-radius;
  }

  .timestamp {
    padding-left: 4px;
    font-size: @description-font-size;
    color: @slack-grey;
  }

  .section-block {
    overflow: hidden;
    font-size: @slack-font-size;
    font-weight: 400;
    line-height: 1.46668;
    color: @slack-black;
  }

  .slack-button-container {
    display: block;
  }

  .slack-button {
    min-width: 56px;
    height: 28px;
    padding: 0 12px 1px;
    margin: 4px 10px 0 0;
    font-family: @slack-font;
    font-size: @slack-button-font-size;
    font-weight: 700;
    color: @slack-black;
    cursor: pointer;
    user-select: none;
    background-color: @white;
    border: @slack-button-border;
    border-radius: @slack-button-border-radius;
    outline: none;
    transition: all 80ms linear;
  }

  .slack-button:hover {
    background: @slack-hover-white;
  }

  .complete-button {
    color: @green;
    border: @slack-green-button-border;
  }

  .task-section {
    display: inline-block;
    width: 100%;

    .divider {
      width: 100%;
      margin: 4px auto 8px;
      border-bottom: @slack-button-border;
    }

    .task-name {
      font-weight: bold;
    }

    .task-requirement {
      font-weight: bold;
    }
  }

  .details {
    padding: 0.5em 0 0 0;
    font-size: @description-font-size;
    color: @slack-grey;
  }
}

.button-error {
  color: @slack-red;
  transition: 0.5s;
}
</style>
