<template>
  <grid container class="section">
    <loading :loading="tasksLoading" />
    <flex justify="space-between" fluid>
      <flex align="center" gap="0.5rem">
        <i
          :class="[
            {
              'fas fa-caret-down': showSection,
              'fas fa-caret-right': !showSection,
            },
            'pointer',
            'caret',
          ]"
          @click="() => (showSection = !showSection)"
        />
        <!-- HACK: icon is blank, we just want the color -->
        <moment-icon
          :hex-color="checklistSection.color"
          :icon="''"
          size="medium"
          class="pointer"
          @click.native="$refs.color.show"
        />
        <editable-text
          :value="checklistSection.name"
          :loading="patchingField === 'name'"
          class="section-header"
          placeholder="Add Section Name"
          @update="(value) => patchChecklistSection('name', value)"
        />
      </flex>
      <flex gap="0.5rem">
        <btn
          icon="fas fa-trash"
          tooltip="Delete Section"
          variant="secondary"
          :loading="deletingChecklistSection"
          compact
          @click.native="deleteChecklistSection"
        />
      </flex>
    </flex>
    <g-table v-show="showSection" basic fixed>
      <table-head v-if="tasks.length">
        <table-row>
          <table-header four> Task </table-header>
          <table-header three> Owner </table-header>
          <table-header four> Send Date and Time </table-header>
          <table-header three> Deadline </table-header>
          <table-header two />
        </table-row>
      </table-head>
      <table-body>
        <task
          v-for="task in tasks"
          :ref="`task-${task.id}`"
          :key="task.id"
          :task="task"
          :organization="organization"
          :users="users"
          :relationships="relationships"
          :aspects="aspects"
          @get-tasks="getTasks"
          @get-actions="() => $emit('get-actions')"
        />
        <table-row>
          <table-cell />
          <table-cell />
          <table-cell />
          <table-cell />
          <table-cell>
            <btn
              class="add-task"
              variant="default"
              :loading="creatingTask"
              basic
              @click.native="createTask"
            >
              + Add Task
            </btn>
          </table-cell>
        </table-row>
      </table-body>
    </g-table>
    <modal ref="color" size="tiny">
      <modal-header> Edit Section Color </modal-header>
      <modal-content>
        <g-form
          :id="`${checklistSection.id}-edit-checklist-section-color`"
          @submit="
            (submission) =>
              patchChecklistSection('color', submission.get('Color'))
          "
        >
          <template #default="{ valid }">
            <form-validation
              :valid="valid"
              @update="(value) => (isColorFormValid = value)"
            />
            <form-field>
              <form-label>Color</form-label>
              <form-dropdown
                name="Color"
                required
                :initial-value="checklistSection.color"
                default-text="Select Color"
              >
                <dropdown-item
                  v-for="color in momentColors"
                  :key="color"
                  :value="color"
                >
                  <i
                    class="fas fa-square form-dropdown-icon"
                    :style="{ color: color }"
                  />
                </dropdown-item>
              </form-dropdown>
            </form-field>
          </template>
        </g-form>
      </modal-content>
      <modal-actions space-between>
        <form-btn
          :form-id="`${checklistSection.id}-edit-checklist-section-color`"
          variant="default"
          type="reset"
        >
          Cancel
        </form-btn>

        <form-btn
          :form-id="`${checklistSection.id}-edit-checklist-section-color`"
          :loading="patchingField === 'color'"
          variant="primary"
          type="submit"
          :invalid="!isColorFormValid"
        >
          Save Color
        </form-btn>
      </modal-actions>
    </modal>
  </grid>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import editableText from '@/components/v2/editable_text.vue'
import loading from '@/components/v2/loading.vue'
import flex from '@/components/v2/flex.vue'
import btn from '@/components/v2/btn.vue'
import grid from '@/components/v2/grid/grid.vue'
import modal from '@/components/v2/modal/modal.vue'
import modalHeader from '@/components/v2/modal/header.vue'
import modalActions from '@/components/v2/modal/actions.vue'
import modalContent from '@/components/v2/modal/content.vue'
import gForm from '@/components/v2/form/form.vue'
import formField from '@/components/v2/form/field.vue'
import formBtn from '@/components/v2/form/btn.vue'
import formLabel from '@/components/v2/form/label.vue'
import formValidation from '@/components/v2/form/validation.vue'
import formDropdown from '@/components/v2/form/dropdown.vue'
import dropdownItem from '@/components/v2/dropdown/item.vue'
import gTable from '@/components/v2/table/table.vue'
import tableHead from '@/components/v2/table/head.vue'
import tableHeader from '@/components/v2/table/header.vue'
import tableRow from '@/components/v2/table/row.vue'
import tableBody from '@/components/v2/table/body.vue'
import momentIcon from '@/components/v2/moment_icon.vue'
import tableCell from '@/components/v2/table/cell.vue'

import task from '@/views/v2/moment/task.vue'

import { momentColors } from '@/assets/js/colors'

export default {
  components: {
    editableText,
    loading,
    flex,
    btn,
    grid,
    task,
    modal,
    modalHeader,
    modalActions,
    modalContent,
    gForm,
    formField,
    formBtn,
    formLabel,
    formValidation,
    formDropdown,
    dropdownItem,
    gTable,
    tableHead,
    tableHeader,
    tableRow,
    tableBody,
    momentIcon,
    tableCell,
  },
  props: {
    moment: {
      type: Object,
      required: true,
    },
    checklistSection: {
      type: Object,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
    aspects: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      default: () => [],
    },
    relationships: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      momentColors,
      patchingField: null,
      creatingTask: false,
      deletingChecklistSection: false,
      isColorFormValid: false,
      isTaskFormValid: false,
      tasksLoading: false,
      showSection: true,
      tasks: [],
      newTask: {
        name: '',
        description: '',
      },
    }
  },
  mounted() {
    this.getTasks()
  },
  methods: {
    async getTasks(taskId) {
      try {
        this.tasksLoading = true
        this.tasks = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/tasks`, {
            checklistSectionId: this.checklistSection.id,
            sort: 'checklistSectionId,sendDate,sendTime,id',
          })
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.tasksLoading = false
      }
      if (taskId) {
        this.$nextTick(() => {
          this.$refs[`task-${taskId}`][0].showNewTaskModal()
        })
      }
    },
    async patchChecklistSection(field, value) {
      try {
        this.patchingField = field
        await api.patch(
          `
        ${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${this.moment.id}/checklist-sections/${this.checklistSection.id}
        `,
          { [field]: value }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingField = null
      }
      this.$emit('get-checklist-sections')
    },
    async deleteChecklistSection() {
      try {
        this.deletingChecklistSection = true
        await api.delete(`
        ${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${this.moment.id}/checklist-sections/${this.checklistSection.id}
        `)
      } catch (error) {
        toast.error(error)
      } finally {
        this.deletingChecklistSection = false
      }
      this.$emit('get-checklist-sections')
    },
    async createTask() {
      let task
      try {
        this.creatingTask = true
        task = (
          await api.post(`${process.env.VUE_APP_DB_ENDPOINT}/v2/tasks`, {
            checklistSectionId: this.checklistSection.id,
            name: 'New Task',
            ownerType: 'enrollee',
            ownerUserId: null,
            ownerRelationshipId: null,
            isRequired: false,
            sendDate: { days: 0 },
            sendTime: {
              hour: 12,
              minute: 0,
            },
          })
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.creatingTask = false
      }
      await this.getTasks(task?.id)
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/text.less';

.section-header {
  font-size: @title-font-size;
  font-weight: bold;
}

.form-dropdown-icon {
  font-size: @large-icon-font-size;
  line-height: 1;
}

.caret {
  min-width: 1rem;
  font-size: @large-icon-font-size;
}

.section {
  margin-bottom: 2rem;
}

.add-task {
  float: right;
}
</style>
