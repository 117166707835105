<template>
  <dropdown
    :icon="icon"
    button
    button-variant="primary"
    hide-caret
    :size="size"
    name="overflow-menu"
    :default-text="null"
    @update="(value) => $emit(kebabCase(value))"
  >
    <dropdown-item
      v-for="(item, index) in items"
      :key="index"
      :value="item.name"
      :class="{ danger: item.danger }"
    >
      <i v-if="item.icon" :class="['icon', { [item.icon]: true }]" />
      {{ item.name }}
    </dropdown-item>
  </dropdown>
</template>

<script>
import { kebabCase } from 'lodash'
import dropdown from '@/components/v2/dropdown/dropdown.vue'
import dropdownItem from '@/components/v2/dropdown/item.vue'

export default {
  components: { dropdown, dropdownItem },
  props: {
    options: {
      type: Array,
      required: true,
      validator: (options) =>
        !options.some(
          (option) =>
            typeof option === 'object' &&
            !Object.prototype.hasOwnProperty.call(option, 'name')
        ),
    },
    type: {
      type: String,
      required: true,
      validator: (value) => ['vertical', 'horizontal'].includes(value),
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) =>
        [
          'mini',
          'tiny',
          'small',
          'medium',
          'large',
          'big',
          'huge',
          'massive',
        ].includes(value),
    },
  },
  data() {
    return {
      kebabCase,
    }
  },
  computed: {
    icon() {
      return this.type === 'vertical'
        ? 'fas fa-ellipsis-v'
        : 'fas fa-ellipsis-h'
    },
    items() {
      return this.options.map((option) =>
        typeof option === 'object' ? option : { name: option }
      )
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';

.ui.dropdown {
  .menu {
    .item.danger {
      color: @error-red;
    }
  }
}
</style>
