<template>
  <flex v-click-outside="() => (showOptions = false)" direction="column">
    <flex align="center" justify="space-between">
      <strong>
        {{ title }}
        <slot name="label" />
        <i
          v-if="disabled"
          v-tippy
          class="fas fa-info-circle"
          :content="disabledTooltip"
        />
      </strong>
      <btn
        v-if="!disabled"
        tooltip="Add Recipient"
        circular
        compact
        icon="fas fa-plus"
        :disabled="isCreationBlocked"
        @click.native="() => (showOptions = !showOptions)"
      />
    </flex>
    <flex justify="space-between" align="center">
      <loading v-if="loading" loading inline size="tiny" />
      <template v-else>
        <placeholder v-if="!recipients.length" warning>
          {{ placeholder }}
        </placeholder>
        <list class="recipient-list" horizontal selection>
          <list-item
            v-for="recipient in recipients"
            :key="`recipient-${recipient.id}`"
            :disabled="disabled"
            :class="['recipient-list-item', { 'disabled-recipient': disabled }]"
          >
            <recipient
              :action="action"
              :recipient="recipient"
              :disabled="disabled"
              @get-recipients="() => $emit('get-recipients')"
              @get-moment-status="() => $emit('get-moment-status')"
            />
          </list-item>
        </list>
      </template>
    </flex>
    <flex v-if="showOptions" wrap="wrap" gap="0.25rem 0">
      <btn
        basic
        class="recipient-btn"
        variant="default"
        :loading="creatingEnrolleeRecipient"
        :disabled="
          recipients.some((recipient) => recipient.model === 'enrollee')
        "
        @click.native="createEnrolleeRecipient"
      >
        + Enrollee
      </btn>

      <dropdown
        class="recipient-dropdown"
        name="People"
        placeholder="+ Person"
        hide-caret
        :loading="creatingUserRecipient"
        :options="users"
        label="fullName"
        searchable
        reduced-field="id"
        @update="createUserRecipient"
      />

      <dropdown
        v-if="!hideGroups"
        name="Groups"
        class="recipient-dropdown"
        placeholder="+ Group"
        hide-caret
        searchable
        :disabled="!groups.length"
        reduced-field="id"
        label="name"
        :options="groups"
        :loading="creatingGroupRecipient"
        @update="createGroupRecipient"
      />
      <dropdown
        name="Relationships"
        class="recipient-dropdown"
        placeholder="+ Relationship"
        hide-caret
        :options="relationships"
        :disabled="!relationships.length"
        :loading="creatingRelationshipRecipient"
        reduced-field="id"
        label="name"
        searchable
        @update="createRelationshipRecipient"
      />
      <dropdown
        v-if="!hideChannels"
        name="Slack Channels"
        class="recipient-dropdown"
        placeholder="+ Channel"
        :options="slackConversations"
        hide-caret
        searchable
        reduced-field="id"
        label="displayName"
        :disabled="!slackConversations.length"
        :loading="creatingSlackConversationRecipient"
        @update="createSlackConversationRecipient"
      />
    </flex>
    <template v-if="showOptions">
      <flex direction="column" gap="0.5rem" class="footer">
        <checkbox
          class=""
          :initial-value="action.isExcludeEnrollee"
          name="Exclude Enrollee"
          @update="
            (value) =>
              $emit('patch-action', { field: 'isExcludeEnrollee', value })
          "
        >
          Exclude Enrollee
        </checkbox>
        <slot name="footer" />
      </flex>
    </template>
  </flex>
</template>

<script>
import vClickOutside from 'v-click-outside'

import { api } from '@/api'
import { toast } from '@/toasts'

import dropdown from '@/components/v3/dropdown.vue'
import list from '@/components/v2/list/list.vue'
import listItem from '@/components/v2/list/item.vue'
import recipient from '@/components/v2/action_card/recipient.vue'
import flex from '@/components/v2/flex.vue'
import btn from '@/components/v2/btn.vue'
import placeholder from '@/components/v2/placeholder.vue'
import loading from '@/components/v2/loading.vue'
import checkbox from '@/components/v2/checkbox.vue'

import defaultUserAvatar from '@/assets/img/profile_avatar_small.png'
import defaultGroupAvatar from '@/assets/img/group_avatar_small.png'
import smartGroupAvatar from '@/assets/img/smart_group_avatar_small.png'

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    dropdown,
    list,
    listItem,
    recipient,
    flex,
    btn,
    placeholder,
    loading,
    checkbox,
  },
  props: {
    title: {
      type: String,
      default: 'Recipients',
    },
    placeholder: {
      type: String,
      default: '⚠️ Click + to add a recipient to this step',
    },
    disabledTooltip: {
      type: String,
      default: 'Adding recipients has been disabled',
    },
    recipients: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
    relationships: {
      type: Array,
      required: true,
    },
    slackConversations: {
      type: Array,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
    hideChannels: {
      type: Boolean,
      default: false,
    },
    hideGroups: {
      type: Boolean,
      default: false,
    },
    allowOne: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultUserAvatar,
      defaultGroupAvatar,
      smartGroupAvatar,
      showOptions: false,
      creatingEnrolleeRecipient: false,
      creatingUserRecipient: false,
      creatingGroupRecipient: false,
      creatingRelationshipRecipient: false,
      creatingSlackConversationRecipient: false,
    }
  },
  computed: {
    isCreationBlocked() {
      return this.allowOne && this.recipients.length > 0
    },
  },
  watch: {
    isCreationBlocked: {
      handler(newValue) {
        if (newValue) {
          this.showOptions = false
        }
      },
    },
  },
  methods: {
    async createRecipient(payload) {
      try {
        await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/actions/${this.action.id}/recipients`,
          payload
        )
      } catch (error) {
        toast.error(error)
      }

      this.$emit('get-recipients')
      this.$emit('get-moment-status')
    },
    async createEnrolleeRecipient() {
      this.creatingEnrolleeRecipient = true
      await this.createRecipient({ model: 'enrollee' })
      this.creatingEnrolleeRecipient = false
    },
    async createUserRecipient(id) {
      this.creatingUserRecipient = true
      await this.createRecipient({ model: 'user', userId: id })
      this.creatingUserRecipient = false
    },
    async createGroupRecipient(id) {
      this.creatingGroupRecipient = true
      await this.createRecipient({ model: 'group', groupId: id })
      this.creatingGroupRecipient = false
    },
    async createRelationshipRecipient(id) {
      this.creatingRelationshipRecipient = true
      await this.createRecipient({ model: 'relationship', relationshipId: id })
      this.creatingRelationshipRecipient = false
    },
    async createSlackConversationRecipient(id) {
      this.creatingSlackConversationRecipient = true
      await this.createRecipient({
        model: 'slackConversation',
        slackConversationId: id,
      })
      this.creatingSlackConversationRecipient = false
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';

.recipient-list {
  margin: 0;
}

.ui.selection.list {
  .recipient-list-item {
    cursor: default;
    padding: 0.25em;
    margin-right: 0.5em;
  }
}

.footer {
  margin: 0.5rem 0;
}

.disabled-recipient {
  margin-top: 0.5rem !important;
}

.recipient-dropdown {
  margin: 0 0.25em 0 0;

  ::v-deep.vs__selected-options .vs__search:only-child {
    max-width: 8rem;
  }
}

.recipient-btn {
  padding: 0 1rem 0 0.5rem;
  color: @blue !important;
  border: 1px solid rgba(60, 60, 60, 0.26);
  box-shadow: none !important;
}
</style>
